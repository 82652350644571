import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import * as adminActions from '../../actions/adminActions';
import MetaTags from '../../components/MetaTags';
import RecentlyAdded from '../../containers/RecentlyAdded';
import AddIntegrationForm from '../../containers/forms/admin/AddIntegrationForm';

class AddIntegration extends Component {
  static title = 'Dodawanie integracji';

  constructor(props) {
    super(props);
    this.handleForm = this.handleForm.bind(this);
  }

  componentDidMount() {
    this.props.getRecentlyAdded();

    this.props.breadcrumbs([{ title: AddIntegration.title }]);
  }

  handleForm(values) {
    const data = { ...values, type: values.type.value };
    this.props.adminAddIntegration(data);
  }

  render() {
    return (
      <div className="admin-add-integration-page">
        <MetaTags title={AddIntegration.title} />

        <div className="row">
          <div className="well-container">
            <div
              className="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12
                col-sm-offset-0 col-xs-12 col-xs-offset-0 well-form"
            >
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h1>{AddIntegration.title}</h1>
                <AddIntegrationForm onSubmit={this.handleForm} />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h2>Chcesz dodać integrację?</h2>
                <div className="row col-lg-12 desc-right">
                  Wystarczy, że:
                  <ul>
                    <li>
                      Zalogujesz się do
                      {' '}
                      <a href="https://s20.cyber-folks.pl:2223/" target="_blank" rel="noreferrer">
                        panelu administratora FTP
                      </a>
                    </li>
                    <li>Wybierzesz domenę tanieruchomosci.pl i przejdziesz do Zarządzania FTP</li>
                    <li>Utworzysz nowe konto FTP na bazie istniejących</li>
                    <li>Skorzystasz z formularza obok w celu zapisania danych w bazie danych</li>
                    <li>Wyślesz informację do użytkownika z prośbą o ponowne zalogowanie się</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RecentlyAdded />
      </div>
    );
  }
}

AddIntegration.propTypes = {
  getRecentlyAdded: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.func.isRequired,
  adminAddIntegration: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  (dispatch) => bindActionCreators(
    { ...breadcrumbsActions, ...estatesActions, ...adminActions }, dispatch,
  ),
)(AddIntegration);
