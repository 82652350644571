import { ESTATES_ACTION_TYPES } from '../actions/estatesActions';

export default function estate(state = {}, action = {}) {
  switch (action.type) {
    case ESTATES_ACTION_TYPES.ESTATES_SET_ESTATE_AD:
      const { step } = action.data;
      const estateAd = (step && step === 1) ? action.data : { ...state.estateAd, ...action.data };

      return { ...state, estateAd };
    case ESTATES_ACTION_TYPES.ESTATES_SET_ESTATE_AD_PHOTOS:
      const oldPhotos = state.estateAd.photos || [];
      const photos = !action.data.append ? action.data.photos
        : oldPhotos.map(photo => Object.assign(photo, { main: 0 })).concat(action.data.photos);

      return { ...state, estateAd: { ...state.estateAd, photos } };
    case ESTATES_ACTION_TYPES.ESTATES_SET_ACCOUNT_LIST:
      return { ...state, accountEstatesList: action.data };
    case ESTATES_ACTION_TYPES.ESTATES_SET_BY_ID:
      return { ...state, estate: action.data };
    case ESTATES_ACTION_TYPES.ESTATES_SET_BY_USER_ID:
      return { ...state, userEstatesList: action.data };
    case ESTATES_ACTION_TYPES.ESTATES_SET_CITY_LIST:
      return { ...state, cityEstatesList: action.data };
    case ESTATES_ACTION_TYPES.ESTATES_SET_LIST:
      return { ...state, estatesList: action.data };
    case ESTATES_ACTION_TYPES.ESTATES_SET_PROMOTED:
      return { ...state, promoted: action.data };
    case ESTATES_ACTION_TYPES.ESTATES_SET_RECENTLY_ADDED:
      return { ...state, recentlyAdded: action.data };
    case ESTATES_ACTION_TYPES.ESTATES_SET_SAFE_LIST:
      return { ...state, safeList: action.data };
    case ESTATES_ACTION_TYPES.ESTATES_SET_SEARCH:
      const search = state.search || {};
      search[action.estateType] = action.data;

      return { ...state, search };
    case ESTATES_ACTION_TYPES.ESTATES_SET_SEARCH_RESULT:
      return { ...state, searchResult: action.data };
    default:
      return state;
  }
}
