import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import EstateCard from '../../components/EstateCard';
import MetaTags from '../../components/MetaTags';
import PromotionList from '../../components/PromotionList';
import { pageUrl } from '../../utils/url';
import Paginator from '../../components/Paginator';
import countPages from '../../utils/estate/countPages';

class ManageAds extends PureComponent {
  static title = 'Zarządzanie ogłoszeniami';

  constructor(props) {
    super(props);
    this.state = { closeRodo: false };
    this.closeRodo = this.closeRodo.bind(this);
    this.deleteAd = this.deleteAd.bind(this);
    this.refreshAd = this.refreshAd.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();

    this.props.breadcrumbs([{ title: ManageAds.title }]);

    this.props.estateAdReset();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match !== this.props.match) {
      this.fetchData();
    }
  }

  fetchData() {
    const { page } = this.props.match.params;
    const query = `page=${page || 1}`;

    this.props.getAccountEstates(query);
  }

  deleteAd(event, estateId) {
    event.preventDefault();
    event.currentTarget.remove();

    this.props.deleteEstateAd(estateId, this.fetchData);
  }

  refreshAd(event, estateId, active) {
    event.preventDefault();

    this.props.refreshEstateAd(estateId);
    if (!active) {
      // Refresh the page to force fresh data
      window.location.reload(true);
    }
  }

  closeRodo() {
    this.setState({ closeRodo: true });
  }

  renderList() {
    const { estatesList } = this.props;

    const estates = (estatesList ? estatesList.rows : Array(8).fill({}))
      .map((estate) => (
        <li key={estate.estateId ? estate.estateId : Math.random()} className="col-xs-12 col-sm-6 col-md-3">
          <EstateCard {...estate} safeBar={false} adActions adStats
            onDelete={this.deleteAd} onRefresh={this.refreshAd}
          />
        </li>
      ));

    return (
      <ul>{estates}</ul>
    );
  }

  render() {
    const page = parseInt(this.props.match.params.page, 10) || 1;
    const { estatesList } = this.props;

    return (
      <div className="estates-page">
        <MetaTags title={ManageAds.title} />

        <Alert bsStyle="warning" className="text-center">
          Od 1 stycznia 2024 zmienia się czas aktywności ogłoszeń.
          Ogłoszenie jest aktywne przez
          {' '}
          <strong>6 miesięcy</strong>
          {' '}
          od daty ostatniej aktualizacji, a usuwane po
          {' '}
          <strong>12 miesiącach</strong>
          .
        </Alert>

        {!this.state.closeRodo && (
          <Alert bsStyle="info" className="text-center" onDismiss={this.closeRodo}>
            Wprowadziliśmy pewne zmiany w&nbsp;naszej Polityce Prywatności, które będą obowiązywać
            od 25 maja 2018. Zmiany te odzwierciedlają rosnące wymogi dotyczące przejrzystości
            związane z&nbsp;ogólnym rozporządzeniem UE o&nbsp;ochronie danych
            (znanym jako GDPR lub RODO). Kliknij
            {' '}
            <Link to="/polityka-prywatnosci">tutaj</Link>
            , aby zapoznać się z&nbsp;
            <strong>nową Polityką Prywatności</strong>
            , która będzie obowiązywała od 25 maja 2018 roku.
          </Alert>
        )}

        <div id="ads" className="row estates-short-list">
          <h1>{ManageAds.title}</h1>

          {(!_.isEmpty(estatesList) && estatesList.rows.length === 0) && (
            <div className="col-lg-12">
              <Alert bsStyle="info" className="text-center">
                W chwili obecnej użytkownik nie posiada dodanych ofert!
              </Alert>
            </div>
          )}

          {this.renderList()}
        </div>

        {(!_.isEmpty(estatesList) && estatesList.rows.length > 0) && (
          <Paginator page={page} pages={countPages(estatesList)}
            url={pageUrl(this.props.match.url, page)}
          />
        )}

        <PromotionList />
      </div>
    );
  }
}

ManageAds.propTypes = {
  breadcrumbs: PropTypes.func.isRequired,
  match: PropTypes.object,
  profile: PropTypes.object,
  estatesList: PropTypes.shape({
    count: PropTypes.number,
    rows: PropTypes.array,
  }),
  getAccountEstates: PropTypes.func.isRequired,
  deleteEstateAd: PropTypes.func.isRequired,
  estateAdReset: PropTypes.func.isRequired,
  refreshEstateAd: PropTypes.func.isRequired,
};

export default withRouter(connect(
  (state) => ({ estatesList: state.estates.accountEstatesList, profile: state.user.profile }),
  (dispatch) => bindActionCreators({ ...breadcrumbsActions, ...estatesActions }, dispatch),
)(ManageAds));
