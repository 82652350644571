import { getJson, getJsonAsync } from '../utils/fetch';
import log from '../utils/log';

export const CITIES_ACTION_TYPES = {
  CITIES_GET_BY_ID: 'CITIES_GET_BY_ID',
  CITIES_GET_BY_PROVINCE: 'CITIES_GET_BY_PROVINCE',
  CITIES_GET_BY_QUERY: 'CITIES_GET_BY_QUERY',
};

export const setCityById = (cityId, data) => ({
  type: CITIES_ACTION_TYPES.CITIES_GET_BY_ID,
  cityId,
  data,
});

export function getCityById(cityId) {
  return (dispatch) => {
    dispatch(setCityById(cityId, null));

    getJson(`/api/cities/${cityId}`)
      .then(json => dispatch(setCityById(cityId, json)))
      .catch((err) => {
        if (err.status === 404) {
          log.warn(`Estate with ID: ${cityId} was not found`);
        } else {
          log.error(`An error occured in getCityById(): ${err.message}`, err);
        }
        return dispatch(setCityById(cityId, {}));
      });
  };
}

export async function fetchCityById(cityId) {
  return getJsonAsync(`/api/cities/${cityId}`);
}

export const setByProvince = (data, province) => ({
  type: CITIES_ACTION_TYPES.CITIES_GET_BY_PROVINCE,
  data,
  province,
});

export function getCitiesByProvince(provinceId) {
  return (dispatch) => {
    dispatch(setByProvince([], provinceId));

    getJson(`/api/cities/province/${provinceId}`)
      .then(json => dispatch(setByProvince(json, provinceId)))
      .catch((err) => {
        log.error(`An error occured in getCitiesByProvince(): ${err.message}`, err);
        return dispatch(setByProvince(null));
      });
  };
}

export const setByQuery = data => ({
  type: CITIES_ACTION_TYPES.CITIES_GET_BY_QUERY,
  data,
});

export function getCitiesByQuery(provinceId, text) {
  return (dispatch) => {
    dispatch(setByQuery([]));

    getJson(`/api/cities/province/${provinceId}?query=${encodeURIComponent(text)}`)
      .then(json => dispatch(setByQuery(json)))
      .catch((err) => {
        log.error(`An error occured in getCitiesByQuery(): ${err.message}`, err);
        return dispatch(setByQuery(null));
      });
  };
}
