export const MESSAGES_ACTION_TYPES = {
  FLASH_MESSENGER_ADD: 'FLASH_MESSENGER_ADD',
  FLASH_MESSENGER_CLEAR: 'FLASH_MESSENGER_CLEAR',
};

export const flashMessengerAdd = data => ({
  type: MESSAGES_ACTION_TYPES.FLASH_MESSENGER_ADD,
  data,
});

export function addFlashMessage(mmessage) {
  return (dispatch) => {
    dispatch(flashMessengerAdd(mmessage));
  };
}

export const flashMessengerClear = () => ({
  type: MESSAGES_ACTION_TYPES.FLASH_MESSENGER_CLEAR,
});

export const sendSuccessMessage = message => addFlashMessage({ type: 'success', message });
export const sendErrorMessage = message => addFlashMessage({ type: 'danger', message });

export function clearFlashMessenger() {
  return (dispatch) => {
    dispatch(flashMessengerClear());
  };
}
