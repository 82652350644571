import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import MetaTags from '../../components/MetaTags';
import EditAdSteps from '../../components/estate/EditAdSteps';
import PhotosForm from '../../containers/forms/PhotosForm';
import { getType } from '../../utils/estate/label';

const maxStepsCondition = (maxSteps) => (!maxSteps || maxSteps < 2);

class EditAdStep2 extends PureComponent {
  static title = 'Edycja ogłoszenia';

  constructor(props) {
    super(props);
    const { history, maxSteps } = props;
    const { estateId } = props.match.params;

    this.handleForm = this.handleForm.bind(this, history, estateId);
    this.handleUpload = this.handleUpload.bind(this);

    if (maxStepsCondition(maxSteps)) props.history.push('/zarzadzaj-ogloszeniami');
  }

  componentDidMount() {
    this.props.breadcrumbs([{ title: EditAdStep2.title }]);

    const { estateId } = this.props.match.params;
    if (estateId) {
      this.fetchData(estateId);
    }
  }

  componentDidUpdate(prevProps) {
    const { estateId } = this.props.match.params;

    if (prevProps.match !== this.props.match) {
      this.fetchData(estateId);
    }
  }

  handleForm(history, estateId) {
    this.props.estateAdStep2Submit(null, estateId, history);
  }

  handleUpload(acceptedFiles) {
    const { estateId } = this.props.match.params;
    const type = getType(this.props.dataStep1.type, true);

    this.props.estateAdStep2Upload(type, estateId, acceptedFiles);
  }

  fetchData(estateId) {
    this.props.estateAdStep2Load(estateId);
  }

  render() {
    const { photos, maxSteps } = this.props;
    const { estateId } = this.props.match.params;

    if (maxStepsCondition(maxSteps)) return null;

    const type = getType(this.props.dataStep1.type, true);

    return (
      <div className="add-add-page-2">
        <MetaTags title={EditAdStep2.title} />

        <div className="row">
          <h1>{EditAdStep2.title}</h1>
        </div>

        <div className="row">
          <div className="well-container">
            <EditAdSteps estateId={estateId} step={2} steps={maxSteps} />

            <div className="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12
              col-sm-offset-0 col-xs-12 col-xs-offset-0 well-form"
            >
              <PhotosForm
                onSubmit={this.handleForm} onUpload={this.handleUpload}
                type={type} estateId={estateId} photos={photos}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditAdStep2.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  breadcrumbs: PropTypes.func.isRequired,
  estateAdStep2Load: PropTypes.func.isRequired,
  estateAdStep2Upload: PropTypes.func.isRequired,
  estateAdStep2Submit: PropTypes.func.isRequired,
  estateId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  dataStep1: PropTypes.object,
  maxSteps: PropTypes.number,
  photos: PropTypes.array,
};

export default withRouter(connect(
  (state) => ({
    estateId: state.estates.estateAd.estateId,
    maxSteps: state.estates.estateAd.step,
    dataStep1: state.estates.estateAd.dataStep1,
    photos: state.estates.estateAd.photos,
  }),
  (dispatch) => bindActionCreators({ ...breadcrumbsActions, ...estatesActions }, dispatch),
)(EditAdStep2));
