import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import * as messagesActions from '../actions/messagesActions';
import scrollToTop from '../utils/scrollToTop';

class FlashMessenger extends PureComponent {
  constructor(props) {
    super(props);

    this.handleDismiss = this.handleDismiss.bind(this);
  }

  handleDismiss() {
    this.props.clearFlashMessenger();
  }

  renderMessages() {
    /* eslint react/no-array-index-key: 0 */
    scrollToTop();

    return this.props.fleshMessenger.map((item, index) => {
      const paragraphs = !_.isArray(item.message) ? item.message
        : item.message.map((message, key) => (<p key={key}>{message}</p>));

      return (
        <Alert key={`alert-${index}`} bsStyle={item.type} onDismiss={this.handleDismiss}>
          {paragraphs}
        </Alert>
      );
    });
  }

  render() {
    const { fleshMessenger } = this.props;
    if (!fleshMessenger || fleshMessenger.length === 0) return null;

    return (
      <>
        {this.renderMessages()}
      </>
    );
  }
}
FlashMessenger.propTypes = {
  fleshMessenger: PropTypes.array,
  clearFlashMessenger: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({ fleshMessenger: state.messages.fleshMessenger }),
  (dispatch) => bindActionCreators(messagesActions, dispatch),
)(FlashMessenger);
