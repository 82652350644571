const offer = [
  {
    title: 'Darmowe ogłoszenia',
    icon: 'usd',
    desc: 'Zarówno zakładanie konta jak i\u00a0dodawanie nielimitowanej liczby ogłoszeń jest bezpłatne.',
  },
  {
    title: 'Dostęp z urządzeń mobilnych',
    icon: 'phone',
    desc: 'Serwis jest odpowiednio przystosowany pod każdy rodzaj urządzenia, w\u00a0tym urządzenia mobilne.',
  },
  {
    title: 'Wyszukiwarka ogłoszeń',
    icon: 'search',
    desc: 'Pozwala na wyszukiwanie interesującego Cię ogłoszenia w\u00a0aktualnej bazie ogłoszeń.',
  },
  {
    title: 'Lokalizacja na mapie',
    icon: 'map-marker',
    desc: 'Każde ogłoszenie zawiera dokładną lub przybliżoną lokalizację na mapie, w\u00a0celu łatwiejszego zidentyfikowania.',
  },
];

export default offer;
