import _ from 'lodash';
import accesses from '../../data/estate/accesses';
import market from '../../data/estate/market';
import materials from '../../data/estate/materials';
import rent from '../../data/estate/rent';
import types from '../../data/estate/types';

function getAccess(accessId) {
  return _.find(accesses, { value: accessId }).label;
}

function getMarket(marketId) {
  return _.find(market, { value: marketId }).label;
}

function getMaterial(materialId) {
  return _.find(materials, { value: materialId }).label;
}

function getRent(rentId) {
  return _.find(rent, { value: rentId }).label;
}

function getType(type, plural = false) {
  return types[type][plural ? 'singular' : 'plural'];
}

export { getAccess };
export { getMarket };
export { getMaterial };
export { getRent };
export { getType };
