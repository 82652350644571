import React from 'react';
import { Switch } from 'react-router-dom';
import LayoutRoute from './LayoutRoute';
import App from '../pages/App';
import About from '../pages/About';
import Regulations from '../pages/Regulations';
import PrivacyPolicys from '../pages/PrivacyPolicy';
import FAQ from '../pages/FAQ';
import ImportAds from '../pages/ImportAds';
import Contact from '../pages/Contact';
import Login from '../pages/user/Login';
import Register from '../pages/user/Register';
import Activation from '../pages/user/Activation';
import ForgotPassword from '../pages/user/ForgotPassword';
import NewPassword from '../pages/user/NewPassword';
import ChangePassword from '../pages/user/ChangePassword';
import Integration from '../pages/user/Integration';
import EditProfile from '../pages/user/EditProfile';
import RemoveAccount from '../pages/user/RemoveAccount';
import Stats from '../pages/admin/Stats';
import AddIntegration from '../pages/admin/AddIntegration';
import RemoveAd from '../pages/admin/RemoveAd';
import NotFound from '../pages/NotFound';
import EstatesRoutes from './EstatesRoutes';

function Router() {
  return (
    <Switch>
      <LayoutRoute exact path="/" component={App} />
      <LayoutRoute exact path="/o-nas" component={About} />
      <LayoutRoute exact path="/regulamin" component={Regulations} />
      <LayoutRoute exact path="/polityka-prywatnosci" component={PrivacyPolicys} />
      <LayoutRoute exact path="/faq" component={FAQ} />
      <LayoutRoute exact path="/import-ofert" component={ImportAds} />
      <LayoutRoute exact path="/kontakt" component={Contact} />

      <LayoutRoute exact path="/logowanie" component={Login} />
      <LayoutRoute exact path="/rejestracja" component={Register} />
      <LayoutRoute exact path="/aktywuj/:userId([0-9]+)/:hash([A-Z0-9]+)" component={Activation} />
      <LayoutRoute exact path="/nowe-haslo/:userId([0-9]+)/:hash([A-Z0-9]+)" component={NewPassword} />
      <LayoutRoute exact path="/zapomnialem-hasla" component={ForgotPassword} />
      <LayoutRoute exact path="/zmien-haslo" component={ChangePassword} />
      <LayoutRoute exact path="/integracja" ensureLoggedIn component={Integration} />
      <LayoutRoute exact path="/edytuj-profil" ensureLoggedIn component={EditProfile} />
      <LayoutRoute exact path="/usun-konto" ensureLoggedIn component={RemoveAccount} />

      <LayoutRoute
        exact path="/admin/statystyki" ensureLoggedIn ensureAdmin
        component={Stats}
      />
      <LayoutRoute
        exact path="/admin/dodaj-integracje" ensureLoggedIn ensureAdmin
        component={AddIntegration}
      />
      <LayoutRoute
        exact path="/admin/usun-ogloszenie" ensureLoggedIn ensureAdmin
        component={RemoveAd}
      />

      <EstatesRoutes />

      <LayoutRoute component={NotFound} />
    </Switch>
  );
}

export default Router;
