import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, reset } from 'redux-form';
import { FormGroup, Button } from 'react-bootstrap';
import { validation, required, minLength, maxLength, email } from '../../utils/forms/validation';
import ReduxFormControl from '../../components/forms/ReduxFormControl';

class CompanyProfileForm extends PureComponent {
  render() {
    const { handleSubmit } = this.props;

    return (
      <form className="row register-company" onSubmit={handleSubmit}>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Field component={ReduxFormControl} name="email" type="email" placeholder="E-mail" disabled />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Field component={ReduxFormControl} name="companyName" type="text" placeholder="Nazwa firmy" />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Field component={ReduxFormControl} name="nip" type="text" placeholder="NIP" />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Field component={ReduxFormControl} name="address" type="text" placeholder="Adres" />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Field component={ReduxFormControl} name="cityCode" type="text" placeholder="Kod pocztowy" />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Field component={ReduxFormControl} name="phone" type="text" placeholder="Telefon" />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <FormGroup controlId="company-submit">
            <Button bsStyle="success" className="submit" type="submit">
              Zapisz
            </Button>
          </FormGroup>
        </div>
      </form>
    );
  }
}

CompanyProfileForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const validate = (values) => {
  const errors = {};

  errors.email = validation(values.email, [required(), email()]);
  errors.companyName = validation(values.companyName, [required(), minLength(3), maxLength(60)]);
  errors.nip = validation(values.nip, [required(), minLength(9), maxLength(13)]);
  errors.address = validation(values.address, [required(), minLength(5), maxLength(50)]);
  errors.cityCode = validation(values.cityCode, [required(), minLength(5), maxLength(6)]);
  errors.phone = validation(values.phone, [required(), minLength(3), maxLength(20)]);

  return errors;
};

const onSubmitSuccess = (result, dispatch) => dispatch(reset('companyProfile'));

export default reduxForm({ form: 'companyProfile', validate, onSubmitSuccess })(CompanyProfileForm);
