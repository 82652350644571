import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, FormSection, reduxForm } from 'redux-form';
import ReduxFormControl from '../../../components/forms/ReduxFormControl';
import { digits, validation } from '../../../utils/forms/validation';

const validate = (values, errors) => {
  /* eslint no-param-reassign: 0 */
  const details = values.details || {};

  errors.details.areaFrom = validation(details.areaFrom, [digits()]);
  errors.details.areaTo = validation(details.areaTo, [digits()]);

  return errors;
};

class LandForm extends PureComponent {
  render() {
    const advancedSearchClass = (this.props.advSearch) ? ' visible' : '';

    return (
      <div className={`advanced-form${advancedSearchClass}`}>
        <FormSection name="details">
          <div className="adv-field-cont col-md-2 col-sm-6 col-xs-6">
            <div className="row">
              <Field component={ReduxFormControl} name="areaFrom" type="text"
                placeholder="Powierzchnia od"
              />
            </div>
          </div>
          <div className="adv-field-cont col-md-2 col-sm-6 col-xs-6">
            <div className="row">
              <Field component={ReduxFormControl} name="areaTo" type="text"
                placeholder="Powierzchnia do"
              />
            </div>
          </div>
        </FormSection>
      </div>
    );
  }
}

LandForm.propTypes = {
  advSearch: PropTypes.bool,
};

export default reduxForm({ form: 'search' })(LandForm);

export { validate };
