import React, { PureComponent } from 'react';
import { Field, FormSection, reduxForm } from 'redux-form';
import ReduxFormControl from '../../../components/forms/ReduxFormControl';
import accesses from '../../../data/estate/accesses';
import categories from '../../../data/estate/categories';

const checkboxes = [
  { name: 'power', label: 'Prąd' },
  { name: 'gas', label: 'Gaz' },
  { name: 'water', label: 'Woda' },
  { name: 'canalization', label: 'Kanalizacja' },
  { name: 'phone', label: 'Telefon' },
  { name: 'internet', label: 'Internet' },
  { name: 'fence', label: 'Ogrodzenie' },
];

class LandForm extends PureComponent {
  static validate = (values, errors) => errors;

  static renderCheckboxes() {
    return checkboxes.map(({ name, label }) => (
      <div key={`checkbox-${name}`} className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
        <Field component={ReduxFormControl} name={name} type="checkbox"
          placeholder={label} useLabel
        />
      </div>
    ));
  }

  render() {
    return (
      <div>
        <FormSection name="estateType">
          <div>
            <Field component={ReduxFormControl} name="category" type="select"
              placeholder="Kategoria" useLabel options={categories.land}
            />
          </div>
          <div>
            <Field component={ReduxFormControl} name="access" type="select"
              placeholder="Dojazd" useLabel options={accesses}
            />
          </div>

          {LandForm.renderCheckboxes()}
        </FormSection>
      </div>
    );
  }
}

export default reduxForm({ form: 'estate' })(LandForm);
