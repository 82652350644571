import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const disableLink = event => event.preventDefault();
/* eslint arrow-body-style: 0 */
const handleClick = (event, step, steps, gotoStep) => ((steps < gotoStep || step === gotoStep)
  ? disableLink(event) : null);
const activeClass = (currentStep, step) => ((currentStep === step) ? 'active' : '');

function EditAdSteps({ step, steps, estateId }) {
  return (
    <div className="ad-steps">
      <ul className="nav nav-pills nav-wizard">
        <li className={activeClass(step, 1)}>
          <Link to={`/edytuj-ogloszenie/${estateId}/krok/1`}
            onClick={event => handleClick(event, step, steps, 1)}
            disabled={(steps < 1 || step === 1)}
          >
            Opis
          </Link>
          <div className="nav-arrow" />
        </li>

        <li className={activeClass(step, 2)}>
          <div className="nav-wedge" />
          <Link to={`/edytuj-ogloszenie/${estateId}/krok/2`}
            onClick={event => handleClick(event, step, steps, 2)}
            disabled={(steps < 2 || step === 2)}
          >
            Zdjęcia
          </Link>
          <div className="nav-arrow" />
        </li>

        <li className={activeClass(step, 3)}>
          <div className="nav-wedge" />
          <Link to={`/edytuj-ogloszenie/${estateId}/krok/3`}
            onClick={event => handleClick(event, step, steps, 3)}
            disabled={(steps < 3 || step === 3)}
          >
            Podgląd
          </Link>
        </li>
      </ul>
    </div>
  );
}

EditAdSteps.propTypes = {
  step: PropTypes.number,
  steps: PropTypes.number,
  estateId: PropTypes.string,
};

export default EditAdSteps;
