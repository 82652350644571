import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as userActions from '../../actions/userActions';
import MetaTags from '../../components/MetaTags';
import Loader from '../../components/Loader';
import integrationTypeDetails from '../../components/integration';

class Integration extends PureComponent {
  static title = 'Integracja z portalem';

  componentDidMount() {
    this.fetchData();

    this.props.breadcrumbs([{ title: Integration.title }]);
  }

  fetchData() {
    this.props.getUserIntegration();
  }

  renderTypeDetails() {
    const { integration } = this.props;
    const IntegrationTypeDetails = integrationTypeDetails[integration.type];

    return <IntegrationTypeDetails />;
  }

  render() {
    const { integration } = this.props;

    return (
      <div className="integration-page">
        <MetaTags title={Integration.title} />

        <div className="row">
          <h1>{Integration.title}</h1>
        </div>

        {(_.isEmpty(integration)) && (<Loader />)}

        {(!_.isEmpty(integration)) && (
          <div className="ad-details clearfix">
            <ul className="details-list col-lg-6 col-md-6 col-sm-6">
              <li className="li-grey">
                Dane potrzebne do integracji
                {' '}
                <strong className="text-uppercase">{integration.type}</strong>
                :
              </li>
              <li>
                <i className="fa fa-file-text-o" />
                {' '}
                Nazwa:
                {' '}
                <strong>tanieruchomosci.pl</strong>
              </li>
              <li>
                <i className="fa fa-file-text-o" />
                {' '}
                Adres serwera:
                {' '}
                <strong>ftp.tanieruchomosci.pl</strong>
              </li>
              <li>
                <i className="fa fa-user" />
                {' '}
                Użytkownik:
                {' '}
                <strong>{integration.ftpLogin}</strong>
              </li>
              <li>
                <i className="fa fa-key" />
                {' '}
                Hasło:
                {' '}
                <strong>{integration.ftpPassword}</strong>
              </li>
              <li>
                <i className="fa fa-key" />
                {' '}
                Port:
                {' '}
                <strong>21</strong>
              </li>
            </ul>

            <ul className="details-list col-lg-6 col-md-6 col-sm-6">
              <li className="li-grey">&nbsp;</li>
              {integration.type === 'asari' && (
                <li>
                  Strona kodowa:
                  {' '}
                  <strong>UTF-8</strong>
                </li>
              )}
              {integration.type === 'asari' && (
                <li>
                  Podkatalog FOTO:
                  {' '}
                  <strong>photos</strong>
                </li>
              )}

              {integration.type !== 'asari' && (
                <li>
                  Format pliku XML:
                  {' '}
                  <strong>oferty.net</strong>
                </li>
              )}

              <li>
                Maksymalna liczba zdjęć:
                {' '}
                <strong>6</strong>
              </li>
              <li>
                Tryb Pasywny:
                {' '}
                <strong>włączony</strong>
              </li>
            </ul>
          </div>
        )}

        {(!_.isEmpty(integration)) && this.renderTypeDetails()}
      </div>
    );
  }
}

Integration.propTypes = {
  integration: PropTypes.object,
  getUserIntegration: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({ integration: state.user.integration }),
  (dispatch) => bindActionCreators({ ...breadcrumbsActions, ...userActions }, dispatch),
)(Integration);
