import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import * as userActions from '../../actions/userActions';
import MetaTags from '../../components/MetaTags';
import RecentlyAdded from '../../containers/RecentlyAdded';
import NewPassForm from '../../containers/forms/NewPassForm';

class NewPassword extends Component {
  static title = 'Zmiana hasła';

  constructor(props) {
    super(props);
    this.handleForm = this.handleForm.bind(this);
  }

  componentDidMount() {
    const { userId, hash } = this.props.match.params;

    this.props.userGetByHash(userId, hash);
    this.props.getRecentlyAdded();

    this.props.breadcrumbs([{ title: NewPassword.title }]);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.newPassUser === undefined) {
      this.props.history.push('/strona-nie-istnieje');
      return false;
    }

    return true;
  }

  handleForm(values) {
    const { userId, hash } = this.props.match.params;

    this.props.userSetNewPass(userId, hash, values.password);
  }

  render() {
    const { newPassUser } = this.props;
    if (_.isEmpty(newPassUser)) return null;

    return (
      <div className="new-password-page">
        <MetaTags title={NewPassword.title} />

        <div className="row">
          <div className="well-container">
            <div
              className="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12
                col-sm-offset-0 col-xs-12 col-xs-offset-0 well-form"
            >
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h1>
                  {NewPassword.title}
                  {' '}
                  <small>
                    do konta:
                    {' '}
                    {newPassUser.email}
                  </small>
                </h1>

                <NewPassForm onSubmit={this.handleForm} />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h2>Chcesz zmienić hasło?</h2>
                <div className="row col-lg-12 desc-right">
                  Wystarczy, że:
                  <ul>
                    <li>Podasz i poprawnie powtórzysz nowe hasło</li>
                    <li>Hasło musi spełniać określone kryteria</li>
                    <li>Potwierdzenie pocztą elektroniczną nie jest wymagane</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RecentlyAdded />
      </div>
    );
  }
}

NewPassword.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  getRecentlyAdded: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.func.isRequired,
  newPassUser: PropTypes.object,
  userGetByHash: PropTypes.func.isRequired,
  userSetNewPass: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({ newPassUser: state.user.newPassUser }),
  (dispatch) => bindActionCreators(
    { ...breadcrumbsActions, ...estatesActions, ...userActions }, dispatch,
  ),
)(NewPassword);
