import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, reset } from 'redux-form';
import { FormGroup, Button } from 'react-bootstrap';
import { validation, required, minLength, maxLength, email, identical } from '../../utils/forms/validation';
import ReduxFormControl from '../../components/forms/ReduxFormControl';
import Captcha from '../../components/forms/Captcha';
import AcceptRegulations from '../../components/forms/AcceptRegulations';

class UserForm extends PureComponent {
  render() {
    const { handleSubmit } = this.props;

    return (
      <form className="row register-company" onSubmit={handleSubmit}>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Field component={ReduxFormControl} name="email" type="email" placeholder="E-mail" />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Field component={ReduxFormControl} name="password" type="password" placeholder="Hasło" />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Field component={ReduxFormControl} name="rePassword" type="password" placeholder="Powtórz hasło" />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Field component={ReduxFormControl} name="name" type="text" placeholder="Imię" />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Field component={ReduxFormControl} name="surname" type="text" placeholder="Nazwisko" />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Field component={ReduxFormControl} name="phone" type="text" placeholder="Telefon" />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Field component={Captcha} name="captcharesponse" />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="col-xs-12">
            <Field component={ReduxFormControl} name="regulations" type="checkbox"
              checked placeholder={<AcceptRegulations />} useLabel
            />
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <FormGroup className="col-xs-12" controlId="user-submit">
            <Button bsStyle="success" className="submit" type="submit">
              Zarejestruj się
            </Button>
          </FormGroup>
        </div>
      </form>
    );
  }
}

UserForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const validate = (values) => {
  const errors = {};

  errors.email = validation(values.email, [required(), email()]);
  errors.password = validation(values.password, [required(), minLength(3)]);
  errors.rePassword = validation(
    values.rePassword, [required(), minLength(3), identical(values.password)],
  );
  errors.name = validation(values.name, [required(), minLength(3), maxLength(20)]);
  errors.surname = validation(values.surname, [required(), minLength(3), maxLength(50)]);
  errors.phone = validation(values.phone, [required(), minLength(3), maxLength(20)]);

  if (!values.captcharesponse) {
    errors.captcharesponse = 'Potwierdź, że nie jesteś robotem';
  }

  return errors;
};

const onSubmitSuccess = (result, dispatch) => dispatch(reset('user'));

export default reduxForm({ form: 'user', validate, onSubmitSuccess })(UserForm);
