import React from 'react';
import PropTypes from 'prop-types';

function TabHeader(props) {
  return (
    <div>
      <span className={`glyphicon glyphicon-${props.icon}`} />
      {` ${props.label}`}
    </div>
  );
}

TabHeader.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default TabHeader;
