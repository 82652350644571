import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, reset } from 'redux-form';
import { FormGroup, Button } from 'react-bootstrap';
import { validation, required, minLength, maxLength, email } from '../../utils/forms/validation';
import ReduxFormControl from '../../components/forms/ReduxFormControl';
import Captcha from '../../components/forms/Captcha';

const DEFAULT_COUNTER = 500;

class ContactForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { countRemains: DEFAULT_COUNTER };
    this.textHandler = this.textHandler.bind(this);
  }

  textHandler(event) {
    const text = event.target.value;
    this.setState({ countRemains: DEFAULT_COUNTER - text.length });
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <form className="row register-company" onSubmit={handleSubmit}>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Field component={ReduxFormControl} name="name" type="text" placeholder="Imię i nazwisko" />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Field component={ReduxFormControl} name="email" type="email" placeholder="E-mail" />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Field component={ReduxFormControl} onChange={this.textHandler} name="text"
            componentClass="textarea" type="textarea" placeholder="Tekst"
          />
          <div className="col-xs-12 pull-right margin-bottom20">
            Pozostało:
            {' '}
            <span className="count-remains">{this.state.countRemains}</span>
            {' '}
            znaków
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Field component={Captcha} name="captcharesponse" />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <FormGroup className="col-xs-12" controlId="contact-submit">
            <Button bsStyle="success" className="submit" type="submit">
              Wyślij
            </Button>
          </FormGroup>
        </div>
      </form>
    );
  }
}

ContactForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const validate = (values) => {
  const errors = {};

  errors.name = validation(values.name, [required(), minLength(3), maxLength(20)]);
  errors.email = validation(values.email, [required(), email()]);

  if (!values.captcharesponse) {
    errors.captcharesponse = 'Potwierdź, że nie jesteś robotem';
  }

  return errors;
};

const onSubmitSuccess = (result, dispatch) => dispatch(reset('contact'));

export default reduxForm({ form: 'contact', validate, onSubmitSuccess })(ContactForm);
