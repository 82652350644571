import React from 'react';
import OfferListItem from './offer-list/OfferListItem';
import promotionData from '../data/promotion';

const getItems = () => promotionData.map(item => <OfferListItem key={item.icon} {...item} />);

function PromotionList() {
  return (
    <div className="row offer-list how-to-promote">
      <h2>Jak wypromować ogłoszenie?</h2>
      <ul>
        {getItems()}
      </ul>
    </div>
  );
}

export default PromotionList;
