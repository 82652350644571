import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as breadcrumbsActions from '../actions/breadcrumbsActions';
import * as estatesActions from '../actions/estatesActions';
import OfferList from '../components/OfferList';
import RecentlyAdded from '../containers/RecentlyAdded';
import Promoted from '../containers/Promoted';
import MetaTags from '../components/MetaTags';

class App extends PureComponent {
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match !== this.props.match) {
      this.fetchData();
    }
  }

  fetchData() {
    this.props.breadcrumbs([]);

    this.props.getPromoted();
    this.props.getRecentlyAdded();
  }

  render() {
    return (
      <div className="app-page">
        <MetaTags />

        <Promoted />

        <OfferList />

        <RecentlyAdded />
      </div>
    );
  }
}

App.propTypes = {
  match: PropTypes.object.isRequired,
  getPromoted: PropTypes.func.isRequired,
  getRecentlyAdded: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  (dispatch) => bindActionCreators({ ...breadcrumbsActions, ...estatesActions }, dispatch),
)(App);
