import React from 'react';
import PropTypes from 'prop-types';
import SafeButton from '../../containers/estate/SafeButton';

const serverAdUrl = encodeURIComponent(window.location.href);
const facebookUrl = ({ photos, title }) => (
  `u=${serverAdUrl}&p[images][0]=${photos[0].filename}`
  + `&p[title]=${encodeURIComponent(title)}`
);

function ShareEstate({ estate }) {
  return (
    <div className="share-wraper">
      <ul className="social-networks">
        <li>
          <a
            href={`http://www.facebook.com/sharer.php?${facebookUrl(estate).replace('[', '%5B').replace(']', '%5D')}`}
            className="social-popup" target="_blank" rel="noopener noreferrer"
            title="Udostępnij na Facebook.com"
          >
            <i className="fa fa-facebook" />
          </a>
        </li>
        <li>
          <a
            href={`https://twitter.com/intent/tweet?url=${serverAdUrl}&text=${encodeURIComponent(estate.title)}`}
            className="social-popup" target="_blank" rel="noopener noreferrer"
            title="Udostępnij na Twitter.com"
          >
            <i className="fa fa-twitter" />
          </a>
        </li>
        <li className="pull-right">
          <a className="print-button" title="Wydrukuj" role="button" tabIndex={0}
            onClick={() => (window.print())} onKeyDown={() => (window.print())}
          >
            <i className="fa fa-print" />
          </a>
        </li>
        <li className="pull-right">
          <a
            href={`mailto:?subject=${encodeURIComponent(estate.title)}&body=${serverAdUrl}`}
            title="Wyślij na e-mail"
          >
            <i className="fa fa-envelope" />
          </a>
        </li>
        <li className="pull-right">
          <SafeButton estateId={estate.estateId} />
        </li>
      </ul>
    </div>
  );
}

ShareEstate.propTypes = {
  estate: PropTypes.object.isRequired,
};

export default ShareEstate;
