import { CITIES_ACTION_TYPES } from '../actions/citiesActions';

export default function cities(state = {}, action = {}) {
  switch (action.type) {
    case CITIES_ACTION_TYPES.CITIES_GET_BY_ID:
      const citiesList = {};
      citiesList[action.cityId] = action.data;

      return { ...state, cities: citiesList };
    case CITIES_ACTION_TYPES.CITIES_GET_BY_PROVINCE:
      const provinces = {};
      provinces[action.province] = !action.data ? []
        : action.data.map(({ cityId, city }) => ({ value: cityId, label: city }));

      return { ...state, provinces };
    case CITIES_ACTION_TYPES.CITIES_GET_BY_QUERY:
      const citiesOptions = !action.data ? []
        : action.data.map(({ cityId, city }) => ({ value: cityId, label: city }));

      return { ...state, queryResult: citiesOptions };
    default:
      return state;
  }
}
