import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as breadcrumbsActions from '../actions/breadcrumbsActions';
import MetaTags from '../components/MetaTags';

function Regulations(props) {
  const title = 'Regulamin';
  props.breadcrumbs([{ title }]);

  return (
    <div className="regulations-page">
      <MetaTags title={title} />

      <div className="row regulations margin-bottom20">
        <h1>{title}</h1>
        <div className="col-lg-10 col-lg-offset-1">
          <h2>&sect; 1. Przepisy ogólne</h2>
          <ol>
            <li>
              Użytkownik wpisując swoje dane do zasobów serwisu, oświadcza, że zapoznał się
              z&nbsp;poniższym regulaminem i&nbsp;zobowiązuje się do jego przestrzegania.
            </li>
            <li>
              Wpisując dane osobowe do serwisu użytkownik wyraża zgodę na ich publikowanie
              w&nbsp;Internecie. Wpisywanie danych jest dobrowolne.
            </li>
            <li>
              Użytkownik, który zamieszcza swoje dane zgadza się na zbieranie, utrwalanie,
              przechowywanie, opracowywanie, zmienianie i&nbsp;udostępnianie swoich udostępnionych
              danych osobowych przez serwis.
            </li>
            <li>
              Osobami uprawnionymi do korzystania z&nbsp;serwisu są osoby pełnoletnie.
            </li>
            <li>
              Zabrania się rozsyłania reklam za pośrednictwem serwisu.
            </li>
            <li>
              Jako użytkownik serwisu możesz wyrazić zgodę na otrzymywanie drogą elektroniczną
              aktualnych informacji dotyczących serwisu.
            </li>
          </ol>

          <h2>&sect; 2. Własność intelektualna</h2>
          <ol>
            <li>
              Treść stron internetowych stanowi przedmiot prawa autorskiego w&nbsp;rozumieniu
              odpowiednich przepisów ustawy z&nbsp;dnia 4 lutego 1994 roku o&nbsp;prawie autorskim
              i&nbsp;prawach pokrewnych
              {' '}
              <i>(Dz. U. Nr 80, z 2000 r., poz. 904 z&nbsp;późniejszymi zmianami)</i>
              .
              Prawa autorskie do treści materiałów zawartych
              w&nbsp;serwisie przysługują wyłącznie właścicielowi serwisu.
            </li>
            <li>
              Jakiekolwiek zwielokrotnianie, w&nbsp;tym kopiowanie, korzystanie lub inne publiczne
              rozpowszechnianie wskazanych powyżej materiałów wymaga zgody właściciela serwisu
              w&nbsp;formie pisemnej.
            </li>
          </ol>

          <h2>&sect; 3. Obostrzenia formalno-prawne</h2>
          <ol>
            <li>
              W przypadku zamieszczania ogłoszeń w&nbsp;serwisie internetowym, serwis nabywa
              nieodpłatne prawo wykorzystania i&nbsp;dystrybuowania zamieszczonych ogłoszeń.
            </li>
            <li>
              Serwis nie bierze udziału w&nbsp;transakcjach pomiędzy uczestnikami rynku, którzy
              korzystają z&nbsp;naszych zasobów. Cała odpowiedzialność wynikająca
              z&nbsp;wykorzystania informacji zawartych na naszych stronach spoczywa na użytkowniku
              serwisu. Serwis nie jest oferentem, a&nbsp;jedynie przedstawia informacje
              o&nbsp;ofertach i&nbsp;oferentach. Treści ofert zawartych w&nbsp;serwisie mają
              wyłącznie charakter informacyjny, nie mogą być traktowane jako oferta handlowa.
            </li>
            <li>
              Informacje zawarte na stronach serwisu mogą być wykorzystane tylko i&nbsp;wyłącznie
              do celów zgodnych z&nbsp;prawem. Treść ogłoszeń niezgodnych z&nbsp;prawem oraz
              wszystkie informacje potrzebne do zlokalizowania ogłoszeniodawcy będą przekazywane
              odpowiednim organom ścigania.
            </li>
            <li>
              Prezentowanie, transmisja, ujawnianie i&nbsp;przechowywanie w&nbsp;serwisie treści
              bądź materiałów niezgodnych z&nbsp;prawem polskim lub międzynarodowym jest zabronione.
              Rozumiemy przez to między innymi materiały patentowe, chronione prawem autorskim,
              materiały obsceniczne, obraźliwe, łamiące prawo do prywatności, nawołujące do
              nienawiści rasowej, etnicznej wyznaniowej, itp., propagujących przemoc, bądź
              niezgodne z&nbsp;ogólnie przyjętą netykietą. Niedozwolone jest również reklamowanie
              lub oferowanie następujących towarów:
              <ol className="list-alpha">
                <li>
                  organów ludzkich,
                </li>
                <li>
                  broni, narkotyków i&nbsp;materiałów pornograficznych,
                </li>
                <li>
                  informacji na temat zarabiania w&nbsp;Internecie w&nbsp;oparciu o&nbsp;systemy
                  piramidowe, itp.
                </li>
                <li>
                  towarów, które nie istnieją,
                </li>
                <li>
                  towarów i&nbsp;informacji, które w&nbsp;jakikolwiek sposób łamią lub ograniczają
                  prawa osób trzecich.
                </li>
              </ol>
            </li>
          </ol>

          <h2>&sect; 4. Przeniesienie odpowiedzialności</h2>
          <ol>
            <li>
              Serwis nie ponosi żadnej odpowiedzialności za:
              <ol className="list-alpha">
                <li>
                  treść ogłoszeń oraz linki do zasobów ujętych w&nbsp;treści ogłoszenia,
                </li>
                <li>
                  zamieszczenie przez użytkowników danych niezgodnych z&nbsp;prawdą lub podanie
                  danych fałszywych,
                </li>
                <li>
                  to w&nbsp;jaki sposób, ani do jakich celów dane zamieszczone w&nbsp;serwisie
                  zostaną wykorzystane,
                </li>
                <li>
                  funkcjonowanie serwisu i&nbsp;wynikłe z&nbsp;tego faktu komplikacje.
                </li>
              </ol>
            </li>
            <li>
              Zastrzegamy sobie prawo do:
              <ol className="list-alpha">
                <li>
                  usuwania ogłoszeń niezgodnych z&nbsp;prawem lub niniejszym regulaminem,
                </li>
                <li>
                  usuwania ogłoszeń po ich wygaśnięciu w&nbsp;przypadku braku ponownej aktywacji,
                </li>
                <li>
                  ograniczania dostępu, usuwania czasowego lub stałego użytkowników
                  nieprzestrzegających postanowień powyższego regulaminu oraz ofert przez nich
                  składanych, również za pośrednictwem innych użytkowników,
                </li>
                <li>
                  dowolnego modyfikowania niniejszego regulaminu bez powiadamiania w&nbsp;innych
                  miejscach, lub zmianę adresu internetowego pod którym jest on opublikowany,
                </li>
                <li>
                  zaprzestania świadczenia usług ogłoszeniowych bez podania przyczyny.
                </li>
              </ol>
            </li>
          </ol>

          <h2>&sect; 5. Polityka prywatności</h2>
          <ol>
            <li>
              Serwis nie wykorzystuje w&nbsp;żaden sposób zbieranych informacji. Jedynie prezentuje
              zamieszczone ogłoszenia oraz dane kontaktowe: adres e-mail oraz numer telefonu.
            </li>
            <li>
              Szczegółowy opis znajduje się w&nbsp;
              <Link to="/polityka-prywatnosci">Polityce prywatności</Link>
              {' '}
              i&nbsp;jest częścią integralną regulaminu.
            </li>
          </ol>

          <p>
            <br />
            Korzystanie z&nbsp;serwisu internetowego jest równoznaczne z&nbsp;
            <strong>akceptacją tego regulaminu</strong>
            .
            <br />
            Regulamin obowiązuje od momentu jego opublikowania.
          </p>
        </div>
      </div>
    </div>
  );
}

Regulations.propTypes = {
  breadcrumbs: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  dispatch => bindActionCreators(breadcrumbsActions, dispatch),
)(Regulations);
