/* eslint react/jsx-filename-extension: 0 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';
import _ from 'lodash';
import configureStore from './store/configureStore';
import initialState from './data/initialState';
import preloadedState from './data/preloadedState';
import Footer from './layout/Footer';
import Router from './routes/Router';
import GA from './routes/GoogleAnalytics';
import { version } from '../package.json';

import 'react-image-lightbox/style.css';
import 'antd/lib/statistic/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/layout/style/css';
import 'antd/lib/card/style/css';
import './styles/index.scss';

// eslint-disable-next-line
__webpack_nonce__ = 'dGFuaWVydWNob21vc2NpLnBsL2NsaWVudA==';

const SENTRY_ENABLED = process.env.REACT_SENTRY_ENABLED || window.REACT_SENTRY_ENABLED;
const SENTRY_DSN = process.env.REACT_SENTRY_SENTRY_DSN || window.REACT_SENTRY_SENTRY_DSN;

if (SENTRY_ENABLED !== 'false') {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new CaptureConsole({ levels: ['error'] })],
    release: version,
    ignoreErrors: ['Failed to fetch', 'NetworkError', 'TypeError: anulowane', 'Application Error'],
  });

  if (!_.isEmpty(initialState.user.profile)) {
    Sentry.configureScope((scope) => scope.setUser({ id: initialState.user.profile.userId }));
  }
}

const init = configureStore({ ...initialState, ...preloadedState() });

ReactDOM.render(
  <Provider store={init.store}>
    <PersistGate loading={null} persistor={init.persistor}>
      <BrowserRouter>
        { GA.init() && <GA.RouteTracker /> }
        <Router />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('stickyLayout'),
);

ReactDOM.render(<Footer />, document.getElementsByTagName('footer')[0]);
