import React from 'react';
import PropTypes from 'prop-types';
import { Glyphicon } from 'react-bootstrap';

function YesNoBadge({ value }) {
  return <Glyphicon glyph={value ? 'ok' : 'remove'} />;
}

YesNoBadge.propTypes = {
  value: PropTypes.number,
};

export default YesNoBadge;
