import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Statistic, Row, Col, Layout, Card } from 'antd';
import { Area } from '@ant-design/plots';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import * as adminActions from '../../actions/adminActions';
import MetaTags from '../../components/MetaTags';
import RecentlyAdded from '../../containers/RecentlyAdded';

const { Content } = Layout;

class Stats extends PureComponent {
  static title = 'Statystyki';

  componentDidMount() {
    this.props.getRecentlyAdded();
    this.props.getStatsAdsChart();
    this.props.getStatsAdsTotal();
    this.props.getStatsUsersTotal();

    this.props.breadcrumbs([{ title: Stats.title }]);
  }

  render() {
    const { stats } = this.props;

    return (
      <div className="admin-stats-page">
        <MetaTags title={Stats.title} />

        <Layout className="layout" style={{ marginTop: '20px' }}>
          <Content style={{ padding: '50px' }}>
            <Row gutter={16}>
              <Col span={12}>
                <Card>
                  <Statistic title="Liczba aktywnych ogłoszeń" value={stats.ads.activeAdsCount} />
                </Card>
              </Col>

              <Col span={12}>
                <Card>
                  <Statistic title="Liczba aktywnych użytkowników" value={stats.users.activeUsersCount} />
                </Card>
              </Col>
            </Row>

            <Row gutter={16} style={{ marginTop: '20px' }}>
              <Col span={24}>
                <Card>
                  <Statistic title="Wykres dodanych ogłoszeń" value={' '} />
                  {!_.isEmpty(stats.adsChart) && (
                    <Area
                      data={stats.adsChart} xField="date" yField="value" seriesField="category"
                      isStack={false}
                    />
                  )}
                </Card>
              </Col>
            </Row>
          </Content>
        </Layout>

        <RecentlyAdded />
      </div>
    );
  }
}

Stats.propTypes = {
  getRecentlyAdded: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.func.isRequired,
  getStatsAdsChart: PropTypes.func.isRequired,
  getStatsAdsTotal: PropTypes.func.isRequired,
  getStatsUsersTotal: PropTypes.func.isRequired,
  stats: PropTypes.object,
};

export default connect(
  (state) => ({ stats: state.admin.stats }),
  (dispatch) => bindActionCreators(
    { ...breadcrumbsActions, ...estatesActions, ...adminActions }, dispatch,
  ),
)(Stats);
