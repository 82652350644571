import _ from 'lodash';
import types from '../../data/estate/types';

const rentOptions = 'sprzedaz|wynajem';

const typesPlural = _.values(_.mapValues(types, 'plural')).join('|');
const typesSingular = _.values(_.mapValues(types, 'singular')).join('|');

export { rentOptions };
export { typesPlural };
export { typesSingular };
