import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavItem, NavDropdown, MenuItem, Glyphicon } from 'react-bootstrap';

const AdminIcon = (
  <span>
    <Glyphicon glyph="tower" className="color-green" />
    {' '}
    Admin
  </span>
);

const MyAccountIcon = (
  <span>
    <Glyphicon glyph="user" className="color-green" />
    {' '}
    Moje konto
  </span>
);

function NavBar({ profile }) {
  return (
    <Navbar>
      <Navbar.Header>
        <Navbar.Toggle />
        <h1 className="no-padding">
          <Link to="/" id="logo" title="Darmowe ogłoszenia nieruchomości">
            <span>Darmowe ogłoszenia nieruchomości</span>
          </Link>
        </h1>
      </Navbar.Header>

      <div className="top-add-button hidden-xs pull-right">
        <Link to="/dodaj-ogloszenie" className="btn btn-large">
          <i className="fa fa-plus-circle" />
          {' '}
          Dodaj ogłoszenie
        </Link>
      </div>

      <Navbar.Collapse>
        <Nav>
          <NavDropdown eventKey={1} title="O serwisie" className="visible-xs-block" id="o-serwisie">
            <MenuItem componentClass={Link} to="/o-nas" eventKey={1.1} href="/o-nas">
              O nas
            </MenuItem>
            <MenuItem componentClass={Link} to="/regulamin" eventKey={1.2} href="/regulamin">
              Regulamin
            </MenuItem>
            <MenuItem componentClass={Link} to="/polityka-prywatnosci" eventKey={1.3} href="/polityka-prywatnosci">
              Polityka&nbsp;prywatności
            </MenuItem>
            <MenuItem componentClass={Link} to="/faq" eventKey={1.4} href="/faq">
              Najczęstsze&nbsp;pytania
            </MenuItem>
            <MenuItem componentClass={Link} to="/import-ofert" eventKey={1.5} href="/import-ofert">
              Import&nbsp;ofert
            </MenuItem>
          </NavDropdown>

          {!_.isEmpty(profile) && profile.admin && (
          <NavDropdown eventKey={1} title={AdminIcon} id="admin">
            <MenuItem componentClass={Link} to="/admin/statystyki" eventKey={1.1} href="/admin/statystyki">
              Statystyki
            </MenuItem>
            <MenuItem componentClass={Link} to="/admin/dodaj-integracje" eventKey={1.2} href="/admin/dodaj-integracje">
              Dodaj integrację
            </MenuItem>
            <MenuItem componentClass={Link} to="/admin/usun-ogloszenie" eventKey={1.3} href="/admin/usun-ogloszenie">
              Usuń ogłoszenie
            </MenuItem>
          </NavDropdown>
          )}

          {_.isEmpty(profile) && (
          <NavItem componentClass={Link} to="/rejestracja" eventKey={2} href="/rejestracja">
            Rejestracja
          </NavItem>
          )}

          {!_.isEmpty(profile) && (
          <NavDropdown eventKey={2} title={MyAccountIcon} id="moje-konto">
            <MenuItem componentClass={Link} to="/dodaj-ogloszenie" eventKey={2.1} href="/dodaj-ogloszenie">
              Dodaj ogłoszenie
            </MenuItem>
            <MenuItem componentClass={Link} to="/zarzadzaj-ogloszeniami" eventKey={2.2} href="/zarzadzaj-ogloszeniami">
              Zarządzaj ogłoszeniami
            </MenuItem>

            {profile.integrationId > 0 && (
            <MenuItem componentClass={Link} to="/integracja" eventKey={2.3} href="/integracja">
              Integracja z portalem
            </MenuItem>
            )}

            <MenuItem componentClass={Link} to="/edytuj-profil" eventKey={2.4} href="/edytuj-profil">
              Edytuj swój profil
            </MenuItem>
            <MenuItem componentClass={Link} to="/zmien-haslo" eventKey={2.5} href="/zmien-haslo">
              Zmień hasło
            </MenuItem>
            <MenuItem componentClass={Link} to="/usun-konto" eventKey={2.6} href="/usun-konto">
              Usuń konto
            </MenuItem>
          </NavDropdown>
          )}

          <NavDropdown eventKey={3} title="Na sprzedaż" id="na-sprzedaz">
            <MenuItem componentClass={Link} to="/domy/sprzedaz/" eventKey={3.1} href="/domy/sprzedaz/">
              Domy
            </MenuItem>
            <MenuItem componentClass={Link} to="/mieszkania/sprzedaz/" eventKey={3.2} href="/mieszkania/sprzedaz/">
              Mieszkania
            </MenuItem>
            <MenuItem componentClass={Link} to="/dzialki/sprzedaz/" eventKey={3.3} href="/dzialki/sprzedaz/">
              Działki
            </MenuItem>
            <MenuItem componentClass={Link} to="/lokale/sprzedaz/" eventKey={3.4} href="/lokale/sprzedaz">
              Lokale użytkowe
            </MenuItem>
            <MenuItem componentClass={Link} to="/obiekty/sprzedaz/" eventKey={3.5} href="/obiekty/sprzedaz/">
              Obiekty użytkowe
            </MenuItem>
          </NavDropdown>

          <NavDropdown eventKey={4} title="Na wynajem" id="na-wynajem">
            <MenuItem componentClass={Link} to="/domy/wynajem/" eventKey={4.1} href="/domy/wynajem/">
              Domy
            </MenuItem>
            <MenuItem componentClass={Link} to="/mieszkania/wynajem/" eventKey={4.2} href="/mieszkania/wynajem/">
              Mieszkania
            </MenuItem>
            <MenuItem componentClass={Link} to="/dzialki/wynajem/" eventKey={4.3} href="/dzialki/wynajem/">
              Działki
            </MenuItem>
            <MenuItem componentClass={Link} to="/lokale/wynajem/" eventKey={4.4} href="/lokale/wynajem">
              Lokale użytkowe
            </MenuItem>
            <MenuItem componentClass={Link} to="/obiekty/wynajem/" eventKey={4.5} href="/obiekty/wynajem/">
              Obiekty użytkowe
            </MenuItem>
          </NavDropdown>

          <NavItem componentClass={Link} to="/kontakt" eventKey={5} href="/kontakt" className="visible-xs-block">
            Kontakt
          </NavItem>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

NavBar.propTypes = {
  profile: PropTypes.object,
};

export default connect(state => ({ profile: state.user.profile }))(NavBar);
