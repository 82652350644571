import _ from 'lodash';
import rent from '../../data/estate/rent';

function getRentBySlug(slug) {
  return _.find(rent, { slug });
}

function getRentByValue(rentId) {
  return _.find(rent, { value: parseInt(rentId, 10) });
}

export { getRentBySlug };
export { getRentByValue };
