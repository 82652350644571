import React from 'react';

function Galactica() {
  return (
    <div>
      <h3>Jak skonfigurować eksport w Galactica Virgo?</h3>
      <p>
        W pierwszej kolejności należy skontaktować się z serwisem firmy
        {' '}
        <a href="http://www.galactica.pl/" target="_blank" rel="noopener noreferrer">Galactica</a>
        ,
        w&nbsp;celu dodania portalu &quot;tanieruchomosci.pl&quot; do listy dostępnych do eksportu
        portali.
        <br />
        Na tym etapie należy również podać pełne dane dostępowe znajdujące się powyżej
        (adres FTP, login, hasło), w&nbsp;celu włączenia eksportu na serwery portalu.
        <br />
        <strong>Ważne jest</strong>
        , aby podać &quot;Format pliku XML&quot;, jako iż definiuje on
        poprawne parsowanie otrzymanych przez nas danych. Bez tej informacji eksport będzie działał
        niepoprawnie!
        <br />
        <br />
        Ostatni krok to ustawienie eksportu w systemie Galactica Virgo (szczegóły w instrukcji lub
        serwisie). Zgodnie z ideą oprogramowania dalej wszystko dzieje się automatycznie.
        <br />
        <br />
        Więcej informacji na temat samego eksportu w systemie Galactica Virgo znajduje się
        {' '}
        <a href="http://virgo.galactica.pl/eksport-ofert-na-portale-ogloszeniowe,230"
          title="Eksport ofert na portale ogłoszeniowe"
        >
          tutaj
        </a>
        .
        <br />
        <br />
      </p>
    </div>
  );
}

export default Galactica;
