import React from 'react';
import PropTypes from 'prop-types';

function OfferListItem(props) {
  return (
    <li className="col-xs-12 col-sm-6 col-md-3">
      <i className={`glyphicon glyphicon-${props.icon}`} />
      <h4>{props.title}</h4>
      {props.desc}
    </li>
  );
}

OfferListItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};

export default OfferListItem;
