import cookie from 'js-cookie';

const safeKey = 'safe';

export const SAFE_ACTION_TYPES = {
  SAFE_UPDATE: 'SAFE_UPDATE',
};

const updateSafe = data => ({
  type: SAFE_ACTION_TYPES.SAFE_UPDATE,
  data,
});

export function fetchSafe() {
  return (dispatch) => {
    const safe = (cookie.get(safeKey)) ? JSON.parse(cookie.get(safeKey)) : [];

    dispatch(updateSafe(safe));
  };
}

export function updateSafeItem(estateId) {
  return (dispatch) => {
    const safe = (cookie.get(safeKey)) ? JSON.parse(cookie.get(safeKey)) : [];
    const index = safe.indexOf(estateId);

    if (index === -1) {
      safe.push(estateId);
    } else {
      safe.splice(index, 1);
    }

    cookie.set(safeKey, JSON.stringify(safe));

    dispatch(updateSafe(safe));
  };
}
