/* eslint import/prefer-default-export: 0 */
import { postJson } from '../utils/fetch';
import { sendErrorMessage, sendSuccessMessage } from './messagesActions';
import log from '../utils/log';

export function sendContactForm(values) {
  const message = {
    error: 'Wystąpił błąd podczas wysyłania wiadomości!',
    success: 'Wiadomość została wysłana.',
  };

  return (dispatch) => {
    postJson('/api/site/contact', values)
      .then(() => dispatch(sendSuccessMessage(message.success)))
      .catch((err) => {
        log.error(`An error occured in sendContactMail(): ${err.message}`, err);
        return dispatch(sendErrorMessage(message.error));
      });
  };
}
