import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as breadcrumbsActions from '../actions/breadcrumbsActions';
import MetaTags from '../components/MetaTags';

function FAQ(props) {
  const title = 'Najczęściej zadawane pytania';
  props.breadcrumbs([{ title }]);

  return (
    <div className="faq-page">
      <MetaTags title={title} />

      <div className="row regulations margin-bottom20">
        <h1>{title}</h1>
        <div className="col-lg-10 col-lg-offset-1">
          <div>
            <h2 id="funkcjonowanie-portalu">Funkcjonowanie portalu</h2>
            <ol>
              <li>
                <div>
                  <strong>Czy serwis jest w&nbsp;pełni darmowy?</strong>
                </div>
                <p>
                  Tak, serwis jest całkowicie darmowy. Zarówno dodawanie ogłoszeń, zakładanie kont
                  (osobistych i&nbsp;firmowych) jest bezpłatne.
                  <br />
                  Ponadto nie rozsyłamy
                  wiadomości SPAM o tematyce reklamowej niezwiązanej z&nbsp;serwisem, a&nbsp;także
                  nie zamieszczamy reklam firm, które nie są Partnerami serwisu.
                </p>
              </li>
              <li>
                <div>
                  <strong>Co wpływa na kolejność ogłoszeń na liście?</strong>
                </div>
                <p>
                  Kolejność ogłoszeń zależy przede wszystkim od daty dodania ogłoszenia - najnowsze
                  ogłoszenia umieszczone są najwyżej na liście.
                  <br />
                  Drugim czynnikiem warunkującym
                  pozycję ogłoszenia jest opcja promowania. Ogłoszenia promowane są umieszczane
                  w&nbsp;pierwszej kolejności na liście i&nbsp;posortowane podobnie jak pozostałe
                  - malejąco według daty dodania.
                  <br />
                  Ogłoszenia promowane na stronie głównej
                  pojawiają się w&nbsp;kolejności losowej. Ponadto jeśli liczba ogłoszeń promowanych
                  jest mniejsza od dostępnej liczby na stronie głównej, to na liście pojawiają się
                  również losowo ogłoszenia nieoznaczone jako promowane.
                </p>
              </li>
              <li>
                <div>
                  <strong>Jak zadać pytanie dotyczące konkretnego ogłoszenia?</strong>
                </div>
                <p>
                  Aby zadać pytanie dotyczące konkretnego ogłoszenia należy skorzystać z&nbsp;danych
                  kontaktowych umieszczonych na dole strony z&nbsp;tym ogłoszeniem.
                </p>
              </li>
              <li>
                <div>
                  <strong>Jak działa schowek?</strong>
                </div>
                <p>
                  Schowek to specjalne miejsce, które pozwala na dodanie maksymalnie 12 ogłoszeń
                  w&nbsp;celu późniejszego przejrzenia lub porównania kilku ofert. W&nbsp;przypadku
                  przekroczenia limitu - nowe ogłoszenia usuwają starsze, zaczynają od najstarszych.
                  <br />
                  Ogłoszenia dodane do schowka przechowywane są do końca trwania sesji, czyli
                  do momentu wyłączenia przeglądarki internetowej. Oczywiście w&nbsp;każdym momencie
                  istnieje możliwość usunięcia ogłoszenia ze schowka.
                </p>
              </li>
              <li>
                <div>
                  <strong>Dlaczego lista miejscowości jest pusta?</strong>
                </div>
                <p>
                  W celu wybrania miejscowości należy najpierw wybrać województwo, aby pobrać listę
                  miejscowości dla tego województwa.
                  <br />
                  Następnie należy wpisać minimum
                  trzy znaki, aby pojawiła się lista wszystkich odpowiadających miejscowości
                </p>
              </li>
            </ol>
          </div>
          <div>
            <h2 id="konta-uzytkownikow">Konta użytkowników</h2>
            <ol>
              <li>
                <div>
                  <strong>Co zrobić, gdy wiadomość e&#8209;mail nie dotarła?</strong>
                </div>
                <p>
                  W pierwszym kroku należy sprawdzić folder SPAM w&nbsp;skrzynce odbiorczej. Dla
                  kont pocztowych na WP lub O2 należy również sprawdzić folder o&nbsp;nazwie
                  &quot;Inne&quot;.
                  <br />
                  W większości przypadków powyższe działanie rozwiązuje
                  problem. W przeciwnym wypadku należy skontaktować się z&nbsp;serwisem korzystając
                  z&nbsp;adresu e&#8209;mail lub formularza kontaktowego i&nbsp;przedstawić
                  zaistniałą sytuację.
                </p>
              </li>
              <li>
                <div>
                  <strong>Co zrobić, gdy nie mogę się zalogować?</strong>
                </div>
                <p>
                  Należy zacząć od sprawdzenia poprawności wpisywanych danych, zwłaszcza pod kątem
                  użycia spacji lub wciśniętego klawisza caps lock. W&nbsp;przypadku stosowania
                  automatycznych podpowiedzi warto dane uzupełnić ręcznie.
                  <br />
                  Jeśli nadal
                  występuje problem z&nbsp;zalogowaniem należy zmienić hasło klikając
                  w&nbsp;&quot;Zapomniałeś hasła?&quot; lub w&nbsp;
                  <Link to="/zapomnialem-hasla">link</Link>
                  .
                </p>
              </li>
              <li>
                <div>
                  <strong>Jak mogę usunąć konto?</strong>
                </div>
                <p>
                  Aby usunąć konto należy się zalogować, wybrać z&nbsp;menu
                  &quot;Moje konto&quot; / &quot;Usuń konto&quot;. Następnie należy kilknać
                  w&nbsp;przycisk umożliwiający jego usunięcie. Przeglądarka dla pewności wyświetli
                  komunikat, aby potwierdzić usunięcie. Po jego zatwierdzeniu konto oraz wszystkie
                  przypisane do niego ogłoszenia zostaną usunięte.
                  <br />
                  Przeglądarka Google nadal
                  może wyświetlać ogłoszenie w&nbsp;wynikach wyszukiwania, jako, że odświeżenie
                  zajmuje minimum 24 godziny, ale po kliknięciu w&nbsp;link zamiast ogłoszenia
                  pojawia się stosowny komunikat.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <h2 id="ogloszenia">Ogłoszenia</h2>
            <ol>
              <li>
                <div>
                  <strong>Jak dodać ogłoszenie?</strong>
                </div>
                <p>
                  W celu dodania nowego ogłoszenia należy w&nbsp;pierwszej kolejności się zalogować
                  (a&nbsp;wcześniej zarejestrować). Następnie należy wybrać z&nbsp;menu
                  &quot;Moje konto&quot; / &quot;Dodaj ogłoszenie&quot; i&nbsp;wybrać jeden rodzaj
                  nieruchomości: dom, mieszkanie, działka, lokal, obiekt.
                  <br />
                  Proces dodania
                  ogłoszenia zawiera się w trzech krokach: dodanie informacji, dodanie zdjęć,
                  zatwierdzenie podglądu.
                  <br />
                  Na początku pojawi się formularz ogłoszenia
                  z&nbsp;polami zarówno wymaganymi (oznaczonymi gwiazdką) oraz opcjonalnymi.
                  Wypełniony formularz jest następnie walidowany w&nbsp;celu określenia poprawności
                  i&nbsp;odpowiednio zwraca informację o&nbsp;błędach lub wyświetla krok drugi.
                  <br />
                  Dodanie zdjęć to proces dodania zdjęć poprzez kliknięcie w przycisk lub
                  metodą Drag&Drop (przeciągając plik ze zdjęciem bezpośrednio na odpowiedni
                  fragment strony).
                  <br />
                  Ostatni etap to wyświetlenie podglądu ogłoszenia, który
                  umożliwia cofnięcie się i&nbsp;poprawienie zmian lub akceptację i&nbsp;dodanie
                  ogłoszenia.
                  <br />
                  Ogłoszenie może się pojawić od razu w systemi lub maksymalnie
                  do 5 minut.
                </p>
              </li>
              <li>
                <div>
                  <strong>Jakie i&nbsp;jak dużo zdjęć mogę dodać?</strong>
                </div>
                <p>
                  Do jednego ogłoszenia można dodać maksymalnie 6 zdjęć, z&nbsp;czego wymagane jest
                  dodanie minimum jednego.
                  <br />
                  Zaleca się zamieszczanie zdjęć w&nbsp;orientacji
                  poziomej, ze wzglęgu na sposób generowania miniaturek na listach i&nbsp;na
                  stronie głównej.
                </p>
              </li>
              <li>
                <div>
                  <strong>Dlaczego powinienem podać dokładny adres?</strong>
                </div>
                <p>
                  Adres służy nam do precyzyjnego określenia lokalizacji, a&nbsp;co za tym idzie
                  również poprawnego podglądu okolicy nieruchomości.
                  <br />
                  W ten sposób Użytkownicy mają przybliżony podgląd okolicy i&nbsp;lokalizacji.
                </p>
              </li>
              <li>
                <div>
                  <strong>Jak długo ogłoszenie jest aktywne?</strong>
                </div>
                <p>
                  Ogłoszenie jest aktywne przez
                  {' '}
                  <strong>6 miesiący</strong>
                  {' '}
                  od daty ostatniej aktualizacji.
                  Każdorazowa pełna edycja ogłoszenia przedłuża ten okres.
                  <br />
                  Następnie ogłoszenie może zostać usunięte
                  {' '}
                  <strong>6 miesiący</strong>
                  {' '}
                  po jego wygaśnięciu.
                  Aby tego uninkąć należy ponownie aktywować ogłoszenie w tym czasie.
                </p>
              </li>
              <li>
                <div>
                  <strong>Jak usunąć ogłoszenie?</strong>
                </div>
                <p>
                  Aby usunąć ogłoszenie należy się zalogować, wybrać z&nbsp;menu
                  &quot;Moje konto&quot; / &quot;Zarządzaj ogłoszeniami&quot;. W&nbsp;prawym górnym
                  rogu każdego dodanego ogłoszenia znajduje się przycisk umożliwiający jego
                  usunięcie. Przeglądarka dla pewności wyświetli komunikat, aby potwierdzić
                  usunięcie, po jego zatwierdzeniu ogłoszenie oraz wszystkie powiązane z&nbsp;nim
                  dane zostaną usunięte.
                  <br />
                  Przeglądarka Google nadal może wyświetlać ogłoszenie
                  w&nbsp;wynikach wyszukiwania, jako, że odświeżenie zajmuje minimum 24 godziny, ale
                  po kliknięciu w&nbsp;link zamiast ogłoszenia pojawia się stosowny komunikat.
                </p>
              </li>
              <li>
                <div>
                  <strong>Czy istnieje możliwość automatycznego importu ogłoszeń?</strong>
                </div>
                <p>
                  Zarejestrowanym Użytkownikom naszego serwisu udostępniamy możliwość uruchomienia
                  usługi automatycznego eksportu ogłoszeń z&nbsp;oprogramowania, którego używają
                  w&nbsp;swoim biurze.
                  <br />
                  Usługa polega na automatycznej wysyłce ofert
                  nieruchomości do naszego serwisu z&nbsp;programu do obsługi
                  biur nieruchomości.
                  <br />
                </p>
                <p>
                  Więcej na ten temat, a także lista dostępnych programów znajduje się na
                  stronie
                  {' '}
                  <Link to="/import-ofert">eksport ofert na portal</Link>
                  . Autorskie rozwiązania
                  {' '}
                  <strong>nie są wspierane</strong>
                  .
                </p>
              </li>
              <li>
                <div>
                  <strong>
                    Dlaczego data aktualizacji ogłoszenia jest starsza od daty dodania ogłoszenia?
                  </strong>
                </div>
                <p>
                  Część z ogłoszeń jest automatycznie importowana z&nbsp;oprogramowania biur
                  nieruchomości.
                  <br />
                  Data dodania jest w&nbsp;tym przypadku datą importu do
                  serwisu, natomiast data aktualizacji - datą aktualizacji oferty w&nbsp;programie
                  przez biuro nieruchomości.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <h2 id="wyszykiwarka">Wyszukiwarka</h2>
            <ol>
              <li>
                <div>
                  <strong>Dlaczego lista miejscowości jest pusta?</strong>
                </div>
                <p>
                  W celu wybrania miejscowości należy najpierw wybrać województwo, aby pobrać listę
                  dostępnych miejscowości dla tego województwa.
                </p>
              </li>
              <li>
                <div>
                  <strong>
                    Dlaczego interesująca mnie miejscowość nie znajduje się na liście do wyboru?
                  </strong>
                </div>
                <p>
                  Po wybraniu województwa, pobierana jest lista miejscowości z&nbsp;danego
                  województwa, które posiadają minimum jedno ogłoszenie.
                  <br />
                  Brak interesującej
                  miejscowości jest jednoznaczny z&nbsp;brakiem ogłoszeń dla tej miejscowości.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}

FAQ.propTypes = {
  breadcrumbs: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  dispatch => bindActionCreators(breadcrumbsActions, dispatch),
)(FAQ);
