import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from '../layout/Layout';
import scrollToTop from '../utils/scrollToTop';
import NotFound from '../pages/NotFound';

class LayoutRoute extends PureComponent {
  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      scrollToTop();
    }
  }

  render() {
    const { component, ...rest } = this.props;
    const redirectToLogin = this.props.ensureLoggedIn && _.isEmpty(this.props.profile);
    const redirectToNotFound = this.props.ensureAdmin && !redirectToLogin
      && !this.props.profile.admin;
    const Component = redirectToNotFound ? NotFound : component;
    const render = (matchProps) => ((redirectToLogin) ? (<Redirect to="/logowanie" />)
      : (<Layout><Component {...matchProps} /></Layout>)
    );

    return (
      <Route {...rest} render={render} />
    );
  }
}

LayoutRoute.propTypes = {
  profile: PropTypes.object,
  ensureLoggedIn: PropTypes.bool,
  ensureAdmin: PropTypes.bool,
  location: PropTypes.object,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default connect(
  (state) => ({ profile: state.user.profile }),
)(LayoutRoute);
