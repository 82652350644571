import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tabs, Tab } from 'react-bootstrap';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as userActions from '../../actions/userActions';
import OfferList from '../../components/OfferList';
import MetaTags from '../../components/MetaTags';
import UserForm from '../../containers/forms/UserForm';
import CompanyForm from '../../containers/forms/CompanyForm';

class Register extends PureComponent {
  static title = 'Rejestracja';

  constructor(props) {
    super(props);
    this.handleForm = this.handleForm.bind(this);
    this.handleCompanyForm = this.handleForm.bind(this, 'company');
    this.handleUserForm = this.handleForm.bind(this, 'user');
  }

  componentDidMount() {
    this.props.breadcrumbs([{ title: Register.title }]);
  }

  handleForm(type, values) {
    this.props.userRegister(type, values);
  }

  render() {
    return (
      <div className="register">
        <MetaTags title={Register.title} />

        <div className="row">
          <div className="well-container">
            <div
              className="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12
                col-sm-offset-0 col-xs-12 col-xs-offset-0 well-form"
            >
              <Tabs defaultActiveKey={1} id="register-tabs" className="row nav nav-tabs">

                <Tab eventKey={1} title={<h1>Osoba prywatna</h1>}>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <UserForm onSubmit={this.handleUserForm} />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="row col-lg-12 desc-right register-company-desc">
                      Oferujemy:
                      <ul>
                        <li>
                          <strong>Bezpłatne</strong>
                          {' '}
                          konto osobiste
                        </li>
                        <li>Nielimitowane dodawanie ogłoszeń</li>
                        <li>Możliwość promowania ogłoszeń</li>
                        <li>Aktualną wyszukiwarkę ogłoszeń</li>
                        <li>Dostęp do serwisu z urządzeń mobilnych</li>
                        <li>Lokalizację obiektów na mapie</li>
                      </ul>
                    </div>
                    <div className="row col-lg-12 desc-right register-company-desc">
                      Przetwarzanie danych:
                      <ul>
                        <li>
                          <strong>Nie udostępniamy</strong>
                          {' '}
                          danych osobowych innym podmiotom
                        </li>
                        <li>
                          Jedynie dane kontaktowe wyświetlane są na stronie ogłoszenia: adres
                          e-mail oraz numer telefonu
                        </li>
                      </ul>
                    </div>
                    <div className="register-contact">
                      W&nbsp;przypadku problemów z&nbsp;rejestracją
                      {' '}
                      <Link to="/kontakt">skontaktuj się z&nbsp;nami</Link>
                      .
                    </div>
                  </div>
                </Tab>

                <Tab eventKey={2} title={<h1>Firma</h1>}>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pull-right">
                    <CompanyForm onSubmit={this.handleCompanyForm} />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="row col-lg-12 desc-right register-person-desc">
                      Oferujemy:
                      <ul>
                        <li>
                          <strong>Bezpłatne</strong>
                          {' '}
                          konto firmowe
                        </li>
                        <li>Nielimitowane dodawanie ogłoszeń</li>
                        <li>Możliwość promowania ogłoszeń</li>
                        <li>Dostęp do serwisu z urządzeń mobilnych</li>
                        <li>Lokalizację obiektów na mapie</li>
                        <li>
                          Możliwość zintegrowania z systemamami do obsługi biur nieruchomości
                          w&nbsp;celu automatycznego eksportu ogłoszeń na nasz portal
                        </li>
                      </ul>
                    </div>
                    <div className="row col-lg-12 desc-right register-company-desc">
                      Przetwarzanie danych:
                      <ul>
                        <li>
                          <strong>Nie udostępniamy</strong>
                          {' '}
                          danych osobowych innym podmiotom
                        </li>
                        <li>
                          Jedynie dane kontaktowe wyświetlane są na stronie ogłoszenia:
                          adres e-mail oraz numer telefonu
                        </li>
                      </ul>
                    </div>
                    <div className="register-contact">
                      W&nbsp;przypadku problemów z&nbsp;rejestracją
                      {' '}
                      <Link to="/kontakt">skontaktuj się z&nbsp;nami</Link>
                      .
                    </div>
                  </div>
                </Tab>

              </Tabs>
            </div>
          </div>
        </div>
        <div className="clearfix" />

        <OfferList />
      </div>
    );
  }
}

Register.propTypes = {
  breadcrumbs: PropTypes.func.isRequired,
  userRegister: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  (dispatch) => bindActionCreators({ ...breadcrumbsActions, ...userActions }, dispatch),
)(Register);
