import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormGroup, Button } from 'react-bootstrap';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import * as userActions from '../../actions/userActions';
import MetaTags from '../../components/MetaTags';
import RecentlyAdded from '../../containers/RecentlyAdded';

class RemoveAccount extends PureComponent {
  static title = 'Usuwanie konta';

  componentDidMount() {
    this.props.getRecentlyAdded();

    this.props.breadcrumbs([{ title: RemoveAccount.title }]);
  }

  handleClick() {
    this.props.userRemoveAccount();
  }

  render() {
    /* eslint no-alert: 0 */
    return (
      <div className="remove-account-page">
        <MetaTags title={RemoveAccount.title} />

        <div className="row">
          <div className="well-container">
            <div
              className="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12
                col-sm-offset-0 col-xs-12 col-xs-offset-0 well-form"
            >
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h1>{RemoveAccount.title}</h1>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                  <p>&nbsp;</p>
                  <FormGroup controlId="toplogin-email">
                    <Button bsStyle="danger" bsSize="large" className="submit" type="submit"
                      onClick={() => (
                        window.confirm('Czy na pewno chcesz usunąć konto?') ? this.handleClick() : ''
                      )}
                    >
                      Usuń konto
                    </Button>
                  </FormGroup>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h2>Chcesz usunąć konto?</h2>
                <div className="row col-lg-12 desc-right">
                  Ważne informacje związane z usunięciem konta:
                  <ul>
                    <li>Usunięcie konta spowoduje nieodwracalne usunięcie konta</li>
                    <li>
                      Usunięcie konta spowoduje usunięcie wszystkich ogłoszeń przypisanych do konta
                    </li>
                    <li>
                      Usunięte ogłoszenia mogą nadal być widoczne przez krótki czas propagacji
                      danych
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RecentlyAdded />
      </div>
    );
  }
}

RemoveAccount.propTypes = {
  getRecentlyAdded: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.func.isRequired,
  userRemoveAccount: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  (dispatch) => bindActionCreators(
    { ...breadcrumbsActions, ...estatesActions, ...userActions }, dispatch,
  ),
)(RemoveAccount);
