import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EstateCard from '../components/EstateCard';

class RecentlyAdded extends PureComponent {
  static renderItems(estates) {
    if (!estates || estates.length === 0) return null;

    return estates.map((estate) => (
      <li key={estate.estateId ? estate.estateId : Math.random()} className="col-xs-12 col-sm-6 col-md-3">
        <EstateCard {...estate} />
      </li>
    ));
  }

  render() {
    return (
      <div className="row estates-short-list">
        <h2>Ostatnio dodane:</h2>

        <ul>
          {RecentlyAdded.renderItems(this.props.recentlyAdded || Array(4).fill({}))}
        </ul>
      </div>
    );
  }
}

RecentlyAdded.propTypes = {
  recentlyAdded: PropTypes.array,
};

export default connect(
  (state) => ({ recentlyAdded: state.estates.recentlyAdded }),
)(RecentlyAdded);
