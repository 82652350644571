import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import {
  calculateStart, calculateEnd, showLeftSide, showRightSide, showLeftEllipsis,
  showRightEllipsis,
} from '../utils/paginator';
import { pageUrl } from '../utils/url';

class Paginator extends PureComponent {
  renderItems() {
    const { page, pages, url } = this.props;

    const start = calculateStart(page, pages);
    const end = calculateEnd(page, pages);

    const items = [];
    for (let number = start; number <= end; number += 1) {
      items.push(
        <LinkContainer key={number} to={pageUrl(url, number)}>
          <Pagination.Item active={number === parseInt(page, 10)}>{number}</Pagination.Item>
        </LinkContainer>,
      );
    }

    return items;
  }

  render() {
    if (this.props.pages <= 1) return null;

    const { page, pages, url } = this.props;

    const renderLeftSide = showLeftSide(page, pages);
    const renderRightSide = showRightSide(page, pages);
    const renderLeftEllipsis = showLeftEllipsis(page, pages);
    const renderRightEllipsis = showRightEllipsis(page, pages);

    return (
      <div className="text-center">
        <Pagination>
          {showLeftSide(page, pages) && (
            <LinkContainer to={pageUrl(url, 1)}>
              <Pagination.Item aria-label="First">{'\xAB'}</Pagination.Item>
            </LinkContainer>
          )}
          {renderLeftSide && (
            <LinkContainer to={pageUrl(url, page - 1)}>
              <Pagination.Item aria-label="Prev">{'\u2039'}</Pagination.Item>
            </LinkContainer>
          )}
          {renderLeftEllipsis && <Pagination.Ellipsis disabled />}

          {this.renderItems()}

          {renderRightEllipsis && <Pagination.Ellipsis disabled />}
          {renderRightSide && (
            <LinkContainer to={pageUrl(url, page + 1)}>
              <Pagination.Item aria-label="Next">{'\u203A'}</Pagination.Item>
            </LinkContainer>
          )}
          {renderRightSide && (
            <LinkContainer to={pageUrl(url, pages)}>
              <Pagination.Item aria-label="Last">{'\xBB'}</Pagination.Item>
            </LinkContainer>
          )}
        </Pagination>
      </div>
    );
  }
}

Paginator.propTypes = {
  page: PropTypes.number,
  pages: PropTypes.number,
  url: PropTypes.string,
};

export default Paginator;
