import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, reset } from 'redux-form';
import { FormGroup, Button } from 'react-bootstrap';
import { validation, required, minLength, email, digits } from '../../../utils/forms/validation';
import ReduxFormControl from '../../../components/forms/ReduxFormControl';
import integrationTypes from '../../../data/integrationTypes';

class AddIntegrationForm extends PureComponent {
  render() {
    const { handleSubmit } = this.props;

    return (
      <form className="row admin-add-integration" onSubmit={handleSubmit}>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <Field component={ReduxFormControl} name="userId" type="text" placeholder="ID użytkownika" />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <Field component={ReduxFormControl} name="ftpLogin" type="text" placeholder="FTP login" />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <Field component={ReduxFormControl} name="ftpPassword" type="password" placeholder="FTP hasło" />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <Field component={ReduxFormControl} name="type" type="select"
              placeholder="Typ" required options={integrationTypes}
            />
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <FormGroup controlId="addIntegration-submit">
            <Button bsStyle="success" className="submit" type="submit">
              Dodaj integrację
            </Button>
          </FormGroup>
        </div>
      </form>
    );
  }
}

AddIntegrationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const validate = (values) => {
  const errors = {};

  errors.userId = validation(values.userId, [required(), digits()]);
  errors.ftpLogin = validation(values.ftpLogin, [required(), email()]);
  errors.ftpPassword = validation(values.ftpPassword, [required(), minLength(5)]);
  errors.type = validation(values.type, [required()]);

  return errors;
};

const onSubmitSuccess = (result, dispatch) => dispatch(reset('addIntegration'));

export default reduxForm({ form: 'addIntegration', validate, onSubmitSuccess })(AddIntegrationForm);
