import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, reset } from 'redux-form';
import { FormGroup, Button } from 'react-bootstrap';
import { validation, required, minLength, maxLength, email } from '../../utils/forms/validation';
import ReduxFormControl from '../../components/forms/ReduxFormControl';

class UserProfileForm extends PureComponent {
  render() {
    const { handleSubmit } = this.props;

    return (
      <form className="row register-company" onSubmit={handleSubmit}>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Field component={ReduxFormControl} name="email" type="email" placeholder="E-mail" disabled />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Field component={ReduxFormControl} name="name" type="text" placeholder="Imię" />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Field component={ReduxFormControl} name="surname" type="text" placeholder="Nazwisko" />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <Field component={ReduxFormControl} name="phone" type="text" placeholder="Telefon" />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <FormGroup controlId="user-submit">
            <Button bsStyle="success" className="submit" type="submit">
              Zapisz
            </Button>
          </FormGroup>
        </div>
      </form>
    );
  }
}

UserProfileForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const validate = (values) => {
  const errors = {};

  errors.email = validation(values.email, [required(), email()]);
  errors.name = validation(values.name, [required(), minLength(3), maxLength(20)]);
  errors.surname = validation(values.surname, [required(), minLength(3), maxLength(50)]);
  errors.phone = validation(values.phone, [required(), minLength(3), maxLength(20)]);

  return errors;
};

const onSubmitSuccess = (result, dispatch) => dispatch(reset('userProfile'));

export default reduxForm({ form: 'userProfile', validate, onSubmitSuccess })(UserProfileForm);
