import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tabs, Tab } from 'react-bootstrap';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import * as userActions from '../../actions/userActions';
import RecentlyAdded from '../../containers/RecentlyAdded';
import MetaTags from '../../components/MetaTags';
import UserProfileForm from '../../containers/forms/UserProfileForm';
import CompanyProfileForm from '../../containers/forms/CompanyProfileForm';
import Loader from '../../components/Loader';

class EditProfile extends PureComponent {
  static title = 'Edycja profilu';

  constructor(props) {
    super(props);
    this.handleForm = this.handleForm.bind(this);
  }

  componentDidMount() {
    this.props.userGetProfile();
    this.props.getRecentlyAdded();

    this.props.breadcrumbs([{ title: EditProfile.title }]);
  }

  handleForm(values) {
    this.props.userUpdateProfile(values);
  }

  render() {
    const { profile } = this.props;

    if (!profile) return (<div className="edit-profile"><Loader /></div>);

    return (
      <div className="edit-profile">
        <MetaTags title={EditProfile.title} />

        <div className="row">
          <div className="well-container">
            <div
              className="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12
                col-sm-offset-0 col-xs-12 col-xs-offset-0 well-form"
            >
              <Tabs defaultActiveKey={(profile && profile.company) ? 2 : 1} id="edit-profile-tabs"
                className="row nav nav-tabs"
              >
                <Tab eventKey={1} title={<h1>Osoba prywatna</h1>}
                  disabled={!!(profile && profile.company)}
                >
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <UserProfileForm initialValues={profile} onSubmit={this.handleForm} />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="row col-lg-12 desc-right register-company-desc">
                      Przetwarzanie danych:
                      <ul>
                        <li>
                          <strong>Nie udostępniamy</strong>
                          {' '}
                          danych osobowych innym podmiotom
                        </li>
                        <li>
                          Jedynie dane kontaktowe wyświetlane są na stronie ogłoszenia: adres e-mail
                          oraz numer telefonu
                        </li>
                      </ul>
                    </div>
                  </div>
                </Tab>

                <Tab eventKey={2} title={<h1>Firma</h1>} disabled={!!(profile && !profile.company)}>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pull-right">
                    <CompanyProfileForm initialValues={this.props.profile}
                      onSubmit={this.handleForm}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="row col-lg-12 desc-right register-company-desc">
                      Przetwarzanie danych:
                      <ul>
                        <li>
                          <strong>Nie udostępniamy</strong>
                          {' '}
                          danych osobowych innym podmiotom
                        </li>
                        <li>
                          Jedynie dane kontaktowe wyświetlane są na stronie ogłoszenia: adres e-mail
                          oraz numer telefonu
                        </li>
                      </ul>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="clearfix" />

        <RecentlyAdded />
      </div>
    );
  }
}

EditProfile.propTypes = {
  getRecentlyAdded: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.func.isRequired,
  userGetProfile: PropTypes.func.isRequired,
  userUpdateProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

export default connect(
  (state) => ({ profile: state.user.userProfile }),
  (dispatch) => bindActionCreators(
    { ...breadcrumbsActions, ...estatesActions, ...userActions }, dispatch,
  ),
)(EditProfile);
