import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import { thumbnailUrl, photoUrl } from '../../utils/url';

const renderSlides = (estate, preview) => (estate.photos.map((photo) => (
  <Carousel.Item key={`photo-${photo.filename}`}>
    <img alt={estate.title} src={preview ? photoUrl(photo.container, estate.type, photo.filename) : thumbnailUrl(photo.container, estate.type, photo.filename, '750x500')} />
  </Carousel.Item>
)));

class GallerySlider extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { index: 0, isOpen: false };

    this.openLightbox = this.openLightbox.bind(this);
  }

  openLightbox(event) {
    if (event.target.tagName === 'IMG') {
      this.setState({ isOpen: true });
    }
  }

  render() {
    const { index, isOpen } = this.state;
    const { estate, preview } = this.props;
    const { photos, type } = estate;
    const count = photos.length;

    return (
      <div>
        <div id="ad-gallery">
          {count > 0 && (
            <Carousel onClick={this.openLightbox}>
              {renderSlides(estate, preview)}
            </Carousel>
          )}
        </div>

        {isOpen && (
          <Lightbox
            enableZoom={false}
            reactModalStyle={{ overlay: { zIndex: 10000000000 } }}
            reactModalProps={{ shouldFocusAfterRender: false }}
            mainSrc={photoUrl(photos[index].container, type, photos[index].filename)}
            nextSrc={photoUrl(
              photos[(index + 1) % count].container,
              type,
              photos[(index + 1) % count].filename,
            )}
            prevSrc={photoUrl(
              photos[(index + count - 1) % count].container,
              type,
              photos[(index + count - 1) % count].filename,
            )}
            onMovePrevRequest={() => this.setState({ index: (index + count - 1) % count })}
            onMoveNextRequest={() => this.setState({ index: (index + 1) % count })}
            onAfterOpen={() => { document.body.style.overflow = 'hidden'; }}
            onCloseRequest={() => {
              document.body.style.overflow = 'auto';
              this.setState({ isOpen: false });
            }}
          />
        )}
      </div>
    );
  }
}

GallerySlider.propTypes = {
  estate: PropTypes.shape({
    type: PropTypes.string.isRequired,
    photos: PropTypes.array.isRequired,
  }).isRequired,
  preview: PropTypes.bool,
};

export default GallerySlider;
