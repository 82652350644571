import { BREADCRUMBS_ACTION_TYPES } from '../actions/breadcrumbsActions';

export default function breadcrumbs(state = {}, action = {}) {
  switch (action.type) {
    case BREADCRUMBS_ACTION_TYPES.BREADCRUMBS_SET:
      return action.data || [];
    default:
      return state;
  }
}
