import React from 'react';

function Asari() {
  return (
    <div>
      <h3>Jak skonfigurować eksport w AsariWeb?</h3>
      <p>
        Należy dodać portal &quot;tanieruchomosci.pl&quot; jako własny portal, zgodnie z&nbsp;
        <a href="http://wiki.asari.pl/pages/viewpage.action?pageId=8159339"
          target="_blank" rel="noopener noreferrer"
        >
          instrukcją
        </a>
        , korzystając z danych z powyższej tabeli.
      </p>

      <h3>Włączenie eksportu ogłoszeń</h3>
      <p>
        &quot;Oferty wprowadzone do aplikacji asariWEB, nie są automatycznie włączone do eksportu na
        portale. Każdą ofertę włączamy indywidualnie (lub grupowo przy pomocy funkcji Koszyka)
        &quot; -
        {' '}
        <a href="http://wiki.protoss.pl/pages/viewpage.action?pageId=3965203"
          target="_blank" rel="noopener noreferrer"
        >
          więcej tutaj
        </a>
        .
        <br />
        <br />
        Aby włączyć eksport konkretnego ogłoszenia, należy kliknąć w edycję tego ogłoszenia,
        następnie w&nbsp;zakładkę &quot;Eksport&quot;, wybrać &quot;Edytuj ustawienia eksportu&quot;
        , zaznaczyć opcję &quot;Eksport na portale&quot;, a&nbsp;następnie zaznaczyć również portal
        &quot;tanieruchomosci.pl&quot; i kliknąć w &quot;Zapisz&quot;.
        <br />
        Od tego momentu ogłoszenie będzie eksportowane do portalu.
        <br />
        <br />
        Aby włączyć eksport
        {' '}
        <strong>wielu ogłoszeń</strong>
        , należy dodać wybrane oferty do koszyka,
        a następnie wybrać &quot;Edytuj ustawienia eksportów w grupie&quot; i postępować zgodnie
        z&nbsp;
        <a href="http://wiki.protoss.pl/pages/viewpage.action?pageId=3965203"
          target="_blank" rel="noopener noreferrer"
        >
          instrukcją
        </a>
        .
        <br />
        <br />
      </p>
    </div>
  );
}

export default Asari;
