import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EstateCard from '../components/EstateCard';

class Promoted extends PureComponent {
  static renderItems(estates) {
    if (!estates || estates.length === 0) return null;

    return estates.map((estate) => (
      <li key={estate.estateId ? estate.estateId : Math.random()} className="col-xs-12 col-sm-6 col-md-3">
        <EstateCard {...estate} />
      </li>
    ));
  }

  render() {
    return (
      <div className="row estates-short-list">
        <h2>Ogłoszenia promowane:</h2>

        <ul>
          {Promoted.renderItems(this.props.promoted || Array(8).fill({ promoted: 1 }))}
        </ul>
      </div>
    );
  }
}

Promoted.propTypes = {
  promoted: PropTypes.array,
};

export default connect(
  (state) => ({ promoted: state.estates.promoted }),
)(Promoted);
