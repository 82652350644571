import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class Breadcrumbs extends PureComponent {
  static getBreadcrumbsItems(breadcrumbs) {
    return breadcrumbs.map((item, index) => {
      if (index === breadcrumbs.length - 1) {
        return <li key={`bcrumb-${item.title}`} className="active">{item.title}</li>;
      }

      /* eslint-disable react/no-unknown-property */
      return (
        <li key={`bcrumb-${item.title}`} property="itemListElement" typeof="ListItem">
          <Link to={item.url} property="item" typeof="WebPage">
            <span property="name">{item.title}</span>
          </Link>
          <meta property="position" content={index + 1} />
        </li>
      );
    });
  }

  render() {
    const { breadcrumbs } = this.props;
    if (breadcrumbs.length === 0) return null;

    return (
      <div className="row">
        <ol className="breadcrumb" vocab="http://schema.org/" typeof="BreadcrumbList">
          <li property="itemListElement" typeof="ListItem">
            <a href="/" property="item" typeof="WebPage">
              <span property="name">Nieruchomości</span>
            </a>
            <meta property="position" content="1" />
          </li>

          {Breadcrumbs.getBreadcrumbsItems(breadcrumbs)}
        </ol>
      </div>
    );
  }
}

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default connect(
  (state) => ({ breadcrumbs: state.breadcrumbs }),
)(Breadcrumbs);
