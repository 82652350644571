import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import TopBar from '../containers/header/TopBar';
import NavBar from '../containers/header/NavBar';

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { sticky: false };
  }

  componentDidMount() {
    this.subject = fromEvent(document, 'scroll')
      .pipe(debounceTime(0.2))
      .subscribe((e, isSticky = window.scrollY > 0) => {
        if (this.state.sticky !== isSticky) {
          this.setState({ sticky: isSticky });
        }
      });
  }

  componentWillUnmount() {
    this.subject.unsubscribe();
  }

  render() {
    const stickyClass = (this.state.sticky) ? ' is-sticky' : '';

    return (
      <header className="header">
        <div role="navigation" className={`navbar navbar-default navbar-fixed-top${stickyClass}`}>
          <TopBar history={this.props.history} />
          <NavBar />
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  history: PropTypes.object,
};

export default Header;
