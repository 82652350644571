import React from 'react';
import PropTypes from 'prop-types';
import formatNumber from '../../utils/formatNumber';
import { getCategory } from '../../utils/estate/categories';
import { getAccess, getMarket, getMaterial } from '../../utils/estate/label';

function EstateDetails(props) {
  return (
    <ul className="details-list">
      <li className="text-right li-grey">
        ID: #
        {props.estateId}
      </li>
      <li>
        Adres:
        {' '}
        <strong className="pull-right">{props.address}</strong>
      </li>
      <li>
        Miejscowość:
        {' '}
        <strong className="pull-right">
          {props.cityCode}
          {' '}
          {props.city.city}
        </strong>
      </li>

      {props.district && (
      <li>
        Dzielnica:
        {' '}
        <strong className="pull-right">{props.district}</strong>
      </li>
      )}

      <li>
        Województwo:
        {' '}
        <strong className="pull-right">{props.province.province}</strong>
      </li>
      <li>
        Powierzchnia:
        {' '}
        <strong className="pull-right">
          {formatNumber(props.area)}
          {' '}
          m2
        </strong>
      </li>

      {props.landArea > 0 && (
      <li>
        Powierzchnia działki:
        <strong className="pull-right">
          {formatNumber(props.landArea)}
          {' '}
          m2
        </strong>
      </li>
      )}

      <li>
        Cena:
        {' '}
        <strong className="pull-right">
          {formatNumber(props.price)}
          {' '}
          zł
        </strong>
      </li>

      {props.category > 0 && (
      <li>
        Kategoria:
        {' '}
        <strong className="pull-right">{getCategory(props.type, props.category)}</strong>
      </li>
      )}

      {props.market > 0 && (
      <li>
        Rynek:
        {' '}
        <strong className="pull-right">{getMarket(props.market)}</strong>
      </li>
      )}

      {props.floor > 0 && (
      <li>
        Piętro:
        {' '}
        <strong className="pull-right">{props.floor}</strong>
      </li>
      )}
      {props.floors > 0 && (
      <li>
        Liczba pięter:
        {' '}
        <strong className="pull-right">{props.floors}</strong>
      </li>
      )}
      {props.rooms > 0 && (
      <li>
        Liczba pokoi:
        {' '}
        <strong className="pull-right">{props.rooms}</strong>
      </li>
      )}
      {props.height > 0 && (
      <li>
        Wysokość pomieszczeń:
        {' '}
        <strong className="pull-right">
          {props.height}
          {' '}
          cm
        </strong>
      </li>
      )}
      {props.year > 0 && (
      <li>
        Rok budowy:
        {' '}
        <strong className="pull-right">{props.year}</strong>
      </li>
      )}
      {props.material > 0 && (
      <li>
        Materiał:
        {' '}
        <strong className="pull-right">{getMaterial(props.material)}</strong>
      </li>
      )}
      {props.access > 0 && (
      <li>
        Dojazd:
        {' '}
        <strong className="pull-right">{getAccess(props.access)}</strong>
      </li>
      )}
    </ul>
  );
}

EstateDetails.propTypes = {
  estateId: PropTypes.number.isRequired,
  type: PropTypes.string,
  address: PropTypes.string.isRequired,
  cityCode: PropTypes.string.isRequired,
  city: PropTypes.shape({
    cityId: PropTypes.number.isRequired,
    city: PropTypes.string.isRequired,
  }).isRequired,
  district: PropTypes.string,
  province: PropTypes.shape({
    provinceId: PropTypes.number.isRequired,
    province: PropTypes.string.isRequired,
  }).isRequired,
  area: PropTypes.number.isRequired,
  landArea: PropTypes.number,
  price: PropTypes.number.isRequired,
  category: PropTypes.number.isRequired,
  market: PropTypes.number,
  floor: PropTypes.number,
  floors: PropTypes.number,
  rooms: PropTypes.number,
  height: PropTypes.number,
  year: PropTypes.number,
  material: PropTypes.number,
  access: PropTypes.number,
};

export default EstateDetails;
