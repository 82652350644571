import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import * as userActions from '../../actions/userActions';
import MetaTags from '../../components/MetaTags';
import RecentlyAdded from '../../containers/RecentlyAdded';
import ForgotPassForm from '../../containers/forms/ForgotPassForm';

class ForgotPassword extends PureComponent {
  static title = 'Zapomniałem hasła';

  constructor(props) {
    super(props);
    this.handleForm = this.handleForm.bind(this);
  }

  componentDidMount() {
    this.props.getRecentlyAdded();

    this.props.breadcrumbs([{ title: ForgotPassword.title }]);
  }

  handleForm(values) {
    this.props.userForgotPass(values.email);
  }

  render() {
    return (
      <div className="forgot-password-page">
        <MetaTags title={ForgotPassword.title} />

        <div className="row">
          <div className="well-container">
            <div
              className="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12
                col-sm-offset-0 col-xs-12 col-xs-offset-0 well-form"
            >
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h1>{ForgotPassword.title}</h1>

                <ForgotPassForm onSubmit={this.handleForm} />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h2>Zapomniałeś hasła?</h2>
                <div className="row col-lg-12 desc-right">
                  O to co musisz zrobić:
                  <ul>
                    <li>Wypełnij formularz w celu zmiany hasła</li>
                    <li>Potwierdzenie zostanie wysłane na Twój adres e-mail</li>
                    <li>Ewentualnie sprawdź folder SPAM</li>
                    <li>Kliknij w link, który otrzymasz w wiadomości i zmień hasło</li>
                    <li>W przypadku dalszych problemów skontaktuj się z nami</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RecentlyAdded />
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  getRecentlyAdded: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.func.isRequired,
  userForgotPass: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  (dispatch) => bindActionCreators(
    { ...breadcrumbsActions, ...estatesActions, ...userActions }, dispatch,
  ),
)(ForgotPassword);
