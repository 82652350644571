import _ from 'lodash';
import { MESSAGES_ACTION_TYPES } from '../actions/messagesActions';

export default function messages(state = {}, action = {}) {
  switch (action.type) {
    case MESSAGES_ACTION_TYPES.FLASH_MESSENGER_ADD:
      const flashMessages = _.isArray(action.data) ? action.data : [action.data];
      return { ...state, fleshMessenger: flashMessages };
    case MESSAGES_ACTION_TYPES.FLASH_MESSENGER_CLEAR:
      return { ...state, fleshMessenger: null };
    default:
      return state;
  }
}
