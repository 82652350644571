import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class AdActions extends PureComponent {
  render() {
    const { estateId, active, photos, onDelete, onRefresh } = this.props;
    const activeTooltip = active ? 'Aktywne' : 'Nieaktywne';
    const activeGlyph = active ? 'ok-sign' : 'exclamation-sign';

    return (
      <div className="ad-actions">
        <OverlayTrigger placement="right" overlay={<Tooltip id="ad-active">{activeTooltip}</Tooltip>}>
          <Glyphicon glyph={activeGlyph} className="pull-left" />
        </OverlayTrigger>
        <Link to={`/usun-ogloszenie/${estateId}`} title="Usuń ogłoszenie" className="pull-right" onClick={(e) => onDelete(e, estateId)}>
          <Glyphicon glyph="remove-circle" />
        </Link>
        <Link to={`/edytuj-ogloszenie/${estateId}/krok/1`} title="Edytuj ogłoszenie" className="pull-right">
          <Glyphicon glyph="cog" />
        </Link>
        {(photos.length > 0) && (
          <Link to={`/odswiez-ogloszenie/${estateId}`} title="Odśwież ogłoszenie" className="pull-right" onClick={(e) => onRefresh(e, estateId, active)}>
            <Glyphicon glyph="refresh" />
          </Link>
        )}
      </div>
    );
  }
}

AdActions.propTypes = {
  estateId: PropTypes.number.isRequired,
  active: PropTypes.number,
  photos: PropTypes.array,
  onDelete: PropTypes.func,
  onRefresh: PropTypes.func,
};

export default AdActions;
