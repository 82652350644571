import { deleteJsonAuth, getJsonAuth, postJsonAuth } from '../utils/fetch';
import { getToken } from '../utils/user';
import { sendErrorMessage, sendSuccessMessage } from './messagesActions';
import log from '../utils/log';

export const ADMIN_ACTION_TYPES = {
  ADMIN_STATS_ADS_CHART: 'ADMIN_STATS_ADS_CHART',
  ADMIN_STATS_ADS_TOTAL: 'ADMIN_STATS_ADS_TOTAL',
  ADMIN_STATS_USERS_TOTAL: 'ADMIN_STATS_USERS_TOTAL',
};

export const setStatsAdsChart = data => ({
  type: ADMIN_ACTION_TYPES.ADMIN_STATS_ADS_CHART,
  data,
});

export const setStatsAdsTotal = data => ({
  type: ADMIN_ACTION_TYPES.ADMIN_STATS_ADS_TOTAL,
  data,
});

export const setStatsUsersTotal = data => ({
  type: ADMIN_ACTION_TYPES.ADMIN_STATS_USERS_TOTAL,
  data,
});

export function getStatsAdsChart() {
  return (dispatch, getState) => {
    getJsonAuth('/api/site/stats/ads', getToken(getState))
      .then(json => dispatch(setStatsAdsChart(json)))
      .catch((err) => {
        log.error(`An error occured in getStatsAdsChart(): ${err.message}`, err);
        return dispatch(setStatsAdsChart({}));
      });
  };
}

export function getStatsAdsTotal() {
  return (dispatch, getState) => {
    getJsonAuth('/api/site/stats/ads/total', getToken(getState))
      .then(json => dispatch(setStatsAdsTotal(json)))
      .catch((err) => {
        log.error(`An error occured in getStatsAdsTotal(): ${err.message}`, err);
        return dispatch(setStatsAdsTotal({}));
      });
  };
}

export function getStatsUsersTotal() {
  return (dispatch, getState) => {
    getJsonAuth('/api/site/stats/users/total', getToken(getState))
      .then(json => dispatch(setStatsUsersTotal(json)))
      .catch((err) => {
        log.error(`An error occured in getStatsUsersTotal(): ${err.message}`, err);
        return dispatch(setStatsUsersTotal({}));
      });
  };
}

export function adminRemoveAd(estateId) {
  const message = {
    error: 'Wystąpił błąd podczas usuwania ogłoszenie!',
    success: 'Ogłoszenie zostało usunięte.',
  };

  return (dispatch, getState) => {
    deleteJsonAuth(`/api/site/remove-ad/${estateId}`, getToken(getState))
      .then(() => {
        dispatch(sendSuccessMessage(message.success));
      })
      .catch((err) => {
        log.error(`An error occured in adminRemoveAd(): ${err.message}`, err);
        return dispatch(sendErrorMessage(message.error));
      });
  };
}

export function adminAddIntegration(data) {
  const message = {
    error: 'Wystąpił błąd podczas dodawania integracji!',
    success: 'Integracja została dodana.',
  };

  return (dispatch, getState) => {
    postJsonAuth('/api/site/add-integration', data, getToken(getState))
      .then(() => {
        dispatch(sendSuccessMessage(message.success));
      })
      .catch((err) => {
        log.error(`An error occured in adminAddIntegration(): ${err.message}`, err);
        return dispatch(sendErrorMessage(message.error));
      });
  };
}
