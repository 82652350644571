import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import { Route } from 'react-router-dom';

const ANALYTICS_ENABLED = process.env.REACT_GOOGLE_ANALYTICS_ID || window.REACT_GOOGLE_ANALYTICS_ID;

class GoogleAnalytics extends Component {
  componentDidMount() {
    this.logPageChange(
      this.props.location.pathname,
      this.props.location.search,
    );
  }

  componentDidUpdate({ location: prevLocation }) {
    const { location: { pathname, search } } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  logPageChange(pathname, search = '') {
    const page = pathname + search;
    const { location } = window;
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...this.props.options,
    });
    ReactGA.send({ hitType: 'pageview', page });
  }

  render() {
    return null;
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  options: PropTypes.object,
};

function RouteTracker() {
  return <Route component={GoogleAnalytics} />;
}

const init = () => {
  const isGAEnabled = !!ANALYTICS_ENABLED;

  if (ANALYTICS_ENABLED) {
    ReactGA.initialize(ANALYTICS_ENABLED, { nonce: 'dGFuaWVydWNob21vc2NpLnBsL2d0bQ==' });
  }

  return isGAEnabled;
};

export default {
  GoogleAnalytics,
  RouteTracker,
  init,
};
