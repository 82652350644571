import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import MetaTags from '../../components/MetaTags';
import AddAdSteps from '../../components/estate/AddAdSteps';
import EstateForm from '../../containers/forms/EstateForm';
import { getType } from '../../utils/estate/label';

class AddAdStep1 extends PureComponent {
  static title = 'Dodawanie ogłoszenia';

  constructor(props) {
    super(props);
    const { history } = props;
    const { type } = props.match.params;

    this.handleForm = this.handleForm.bind(this, history, type);
  }

  componentDidMount() {
    this.props.breadcrumbs([{ title: AddAdStep1.title }]);

    const { estateId } = this.props;
    if (estateId) {
      this.fetchData(estateId);
    }
  }

  handleForm(history, type, values) {
    const { estateId } = this.props;

    this.props.estateAdStep1Submit(type, estateId, values, history);
  }

  fetchData(estateId) {
    this.props.estateAdStep1Load(estateId);
  }

  render() {
    const { estateId } = this.props.match.params;
    const { dataStep1, maxSteps } = this.props;

    if (estateId && _.isEmpty(dataStep1)) return null;

    const type = estateId ? getType(dataStep1.type, true) : this.props.match.params.type;

    return (
      <div className="add-add-page-1">
        <MetaTags title={AddAdStep1.title} />

        <div className="row">
          <h1>{AddAdStep1.title}</h1>
        </div>

        <div className="row">
          <div className="well-container">
            <AddAdSteps type={type} step={1} steps={maxSteps} />

            <div className="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12
              col-sm-offset-0 col-xs-12 col-xs-offset-0 well-form"
            >
              <EstateForm initialValues={dataStep1} type={type} onSubmit={this.handleForm} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddAdStep1.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  breadcrumbs: PropTypes.func.isRequired,
  estateAdStep1Load: PropTypes.func.isRequired,
  estateAdStep1Submit: PropTypes.func.isRequired,
  dataStep1: PropTypes.object,
  maxSteps: PropTypes.number,
  estateId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default withRouter(connect(
  (state) => ({
    dataStep1: state.estates.estateAd.dataStep1,
    maxSteps: state.estates.estateAd.step,
    estateId: state.estates.estateAd.estateId,
  }),
  (dispatch) => bindActionCreators({ ...breadcrumbsActions, ...estatesActions }, dispatch),
)(AddAdStep1));
