import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import RecentlyAdded from '../../containers/RecentlyAdded';
import { estatesListUrl } from '../../utils/url';
import { getRent, getType } from '../../utils/estate/label';
import MetaTags from '../../components/MetaTags';
import EstateItem from '../../components/EstateItem';
import ErrorNotFound from '../../components/ErrorNotFound';

class EstatesItem extends PureComponent {
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match !== this.props.match) {
      this.fetchData();
    }

    const { estate } = this.props;
    if (!_.isEmpty(estate) && estate.active) this.setBreadcrumbs(this.props.estate);
  }

  setBreadcrumbs(estate) {
    const rentText = getRent(estate.rent);
    const typeText = getType(estate.type);
    const typeTitle = _.upperFirst(typeText.replace('dzialki', 'działki'));

    return this.props.breadcrumbs([
      { title: `${typeTitle} na ${rentText}`, url: estatesListUrl(estate) },
      { title: estate.title },
    ]);
  }

  fetchData() {
    this.props.getEstateById(this.props.match.params.id);
    this.props.getRecentlyAdded();
  }

  render() {
    const { estate } = this.props;
    const isEmpty = !estate;

    if (estate && (_.isEmpty(estate) || !estate.active)) return (<ErrorNotFound />);

    const description = !isEmpty ? _.upperFirst(estate.description.trim()) : null;

    return (
      <div className="estate-page">
        {!isEmpty && (
          <MetaTags title={estate.title} description={description} />
        )}

        <EstateItem estate={estate} />

        <RecentlyAdded />
      </div>
    );
  }
}

EstatesItem.propTypes = {
  match: PropTypes.object,
  estate: PropTypes.object,
  getEstateById: PropTypes.func.isRequired,
  getRecentlyAdded: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.func.isRequired,
};

export default withRouter(connect(
  (state) => ({ estate: state.estates.estate }),
  (dispatch) => bindActionCreators({ ...breadcrumbsActions, ...estatesActions }, dispatch),
)(EstatesItem));
