import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import MetaTags from '../../components/MetaTags';
import EditAdSteps from '../../components/estate/EditAdSteps';
import EstateForm from '../../containers/forms/EstateForm';
import { getType } from '../../utils/estate/label';
import ErrorNotFound from '../../components/ErrorNotFound';

class EditAdStep1 extends PureComponent {
  static title = 'Edycja ogłoszenia';

  constructor(props) {
    super(props);
    const { history } = props;

    this.handleForm = this.handleForm.bind(this, history);
  }

  componentDidMount() {
    this.props.breadcrumbs([{ title: EditAdStep1.title }]);

    const { estateId } = this.props.match.params;
    if (estateId) {
      this.fetchData(estateId);
    }
  }

  componentDidUpdate(prevProps) {
    const { estateId } = this.props.match.params;

    if (prevProps.match !== this.props.match) {
      this.fetchData(estateId);
    }
  }

  handleForm(history, values) {
    const { estateId } = this.props.match.params;
    const type = getType(this.props.dataStep1.type, true);

    this.props.estateAdStep1Submit(type, estateId, values, history);
  }

  fetchData(estateId) {
    this.props.estateAdStep1Load(estateId);
  }

  render() {
    const { estateId } = this.props.match.params;
    const { dataStep1, maxSteps } = this.props;

    if (!dataStep1) return null;
    if (dataStep1 && _.isEmpty(dataStep1)) return (<ErrorNotFound />);

    const type = getType(dataStep1.type, true);

    return (
      <div className="add-add-page-1">
        <MetaTags title={EditAdStep1.title} />

        <div className="row">
          <h1>{EditAdStep1.title}</h1>
        </div>

        <div className="row">
          <div className="well-container">
            <EditAdSteps estateId={estateId} step={1} steps={maxSteps} />

            <div className="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12
              col-sm-offset-0 col-xs-12 col-xs-offset-0 well-form"
            >
              <EstateForm initialValues={dataStep1} type={type} onSubmit={this.handleForm} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditAdStep1.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  breadcrumbs: PropTypes.func.isRequired,
  estateAdStep1Load: PropTypes.func.isRequired,
  estateAdStep1Submit: PropTypes.func.isRequired,
  dataStep1: PropTypes.object,
  maxSteps: PropTypes.number,
};

export default withRouter(connect(
  (state) => ({
    dataStep1: state.estates.estateAd.dataStep1,
    maxSteps: state.estates.estateAd.step,
  }),
  (dispatch) => bindActionCreators({ ...breadcrumbsActions, ...estatesActions }, dispatch),
)(EditAdStep1));
