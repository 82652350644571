const getSearchParams = () => [
  'type', 'rent', 'province', 'city', 'priceFrom', 'priceTo', 'areaFrom', 'areaTo', 'landAreaFrom',
  'landAreaTo', 'rooms', 'internet', 'furnished', 'parking', 'elevator', 'page',
];

const getCommonParams = () => [
  'rent', 'province', 'city', 'priceFrom', 'priceTo',
];

const getDetailsParams = () => ({
  apartment: ['areaFrom', 'areaTo', 'rooms', 'internet', 'furnished', 'parking', 'elevator'],
  house: ['areaFrom', 'areaTo', 'landAreaFrom', 'landAreaTo', 'internet', 'furnished'],
  land: ['areaFrom', 'areaTo'],
  object: ['areaFrom', 'areaTo', 'landAreaFrom', 'landAreaTo', 'internet', 'elevator'],
  place: ['areaFrom', 'areaTo', 'rooms', 'internet', 'furnished', 'parking', 'elevator'],
});

export { getSearchParams, getCommonParams, getDetailsParams };
