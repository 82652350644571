import React from 'react';
import { Link } from 'react-router-dom';

function MenuBottom() {
  return (
    <div className="row">
      <div className="text-center bottom-links">
        <Link to="/o-nas">O nas</Link>
        <Link to="/regulamin">Regulamin</Link>
        <Link to="/polityka-prywatnosci">Polityka&nbsp;prywatności</Link>
        <Link to="/faq">Najczęstsze&nbsp;pytania</Link>
        <Link to="/import-ofert">Import&nbsp;ofert</Link>
        <Link to="/kontakt">Kontakt</Link>
      </div>
    </div>
  );
}

export default MenuBottom;
