import React from 'react';

function EstiCRM() {
  return (
    <div>
      <h3>Jak skonfigurować eksport w EstiCRM?</h3>
      <p>
        Należy wybrać portal:
        {' '}
        <i>Dowolny - format Oferty.NET</i>
        , a następnie wpisać: nazwę
        użytkownika, hasło, adres serwera (bez przedrostka ftp.), numer portu i zatwierdzić.
        <br />
        Następnie należy aktywować eksport.
        <br />
        <br />
      </p>
    </div>
  );
}

export default EstiCRM;
