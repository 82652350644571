import _ from 'lodash';
import * as Sentry from '@sentry/browser';
import { getJson, getJsonAuth, deleteJsonAuth, postJson, postJsonAuth } from '../utils/fetch';
import { getToken, getUserId, getUserProfile } from '../utils/user';
import { sendErrorMessage, sendSuccessMessage } from './messagesActions';
import log from '../utils/log';

export const USER_ACTION_TYPES = {
  USER_INTEGRATION: 'USER_INTEGRATION',
  USER_LOG_IN: 'USER_LOG_IN',
  USER_NEW_PASS: 'USER_NEW_PASS',
  USER_PROFILE: 'USER_PROFILE',
  USER_CLOSE_COOKIE_BAR: 'USER_GDPR_COOKIE_CLOSED',
};

const userAllowedFields = [
  ['name', 'surname', 'phone'],
  ['companyName', 'nip', 'address', 'cityCode', 'phone'],
];

export const userLoggedIn = data => ({
  type: USER_ACTION_TYPES.USER_LOG_IN,
  data,
});

export const userNewPass = data => ({
  type: USER_ACTION_TYPES.USER_NEW_PASS,
  data,
});

export const userCloseCookieBar = () => ({
  type: USER_ACTION_TYPES.USER_CLOSE_COOKIE_BAR,
});

const updateSentryUserId = (userId) => {
  const SENTRY_ENABLED = process.env.REACT_SENTRY_ENABLED || window.REACT_SENTRY_ENABLED;

  if (SENTRY_ENABLED !== 'false') {
    Sentry.configureScope((scope) => scope.setUser(userId ? ({ id: userId }) : null));
  }
};

export function userLogIn(values, history) {
  const message = {
    error: 'Niepoprawny login i/lub hasło!',
  };

  return (dispatch) => {
    dispatch(userLoggedIn(null));

    postJson('/api/user/login', values)
      .then((json) => {
        dispatch(userLoggedIn(json));
        updateSentryUserId(json.userId);
        localStorage.setItem('user.profile', JSON.stringify(json));
        history.push('/zarzadzaj-ogloszeniami');
      })
      .catch(() => {
        dispatch(sendErrorMessage(message.error));
        history.push('/logowanie');
      });
  };
}

export const setUserIntegration = data => ({
  type: USER_ACTION_TYPES.USER_INTEGRATION,
  data,
});

export function getUserIntegration() {
  return (dispatch, getState) => {
    dispatch(setUserIntegration(null));

    const userProfile = getUserProfile(getState);
    const integrationId = userProfile.integrationId || null;

    getJsonAuth(`/api/user/${getUserId(getState)}/integration/${integrationId}`, getToken(getState))
      .then(json => dispatch(setUserIntegration(json)))
      .catch((err) => {
        log.error(`An error occured in getUserIntegration(): ${err.message}`, err);
        return dispatch(setUserIntegration(null));
      });
  };
}

export function userRegister(type, values) {
  const message = {
    error: 'Wystąpił błąd podczas procesu rejestracji: niepoprawne dane '
    + 'lub konto zarejestrowane na ten adres e-mail już istnieje',
    success: 'Aby dokończyć proces rejestracji postępuj zgodnie z instrukcją '
      + 'wysłaną na Twój adres e-mail.',
  };

  return (dispatch) => {
    postJson(`/api/user/register/${type}`, values)
      .then(() => dispatch(sendSuccessMessage(message.success)))
      .catch((err) => {
        if (err.status === 400) {
          log.warn('Incorrect data was sent or account for this email address already exists');
        } else {
          log.error(`An error occured in userRegister(): ${err.message}`, err);
        }
        return dispatch(sendErrorMessage(message.error));
      });
  };
}

export function userGetByHash(userId, hash) {
  return (dispatch) => {
    dispatch(userNewPass(null));

    getJson(`/api/user/new-pass/${userId}/${hash}`)
      .then(json => dispatch(userNewPass(json)))
      .catch((err) => {
        log.error(`An error occured in userGetByHash(): ${err.message}`, err);
        return dispatch(userNewPass(undefined));
      });
  };
}

export const userSetProfile = data => ({
  type: USER_ACTION_TYPES.USER_PROFILE,
  data,
});

export function userGetProfile() {
  return (dispatch, getState) => {
    dispatch(userSetProfile(null));

    getJsonAuth(`/api/user/${getUserId(getState)}/profile`, getToken(getState))
      .then(json => dispatch(userSetProfile(json)))
      .catch((err) => {
        log.error(`An error occured in userGetProfile(): ${err.message}`, err);
        return dispatch(userSetProfile(undefined));
      });
  };
}

export function userUpdateProfile(data) {
  const message = {
    error: 'Wystąpił błąd podczas aktualizacji profilu lub nie było żadnych zmian!',
    success: 'Profil został pomyślnie zaktualizowany.',
  };

  return (dispatch, getState) => {
    dispatch(userSetProfile(null));

    const updateData = _.pick(data, userAllowedFields[data.company]);
    updateData.company = data.company;

    postJsonAuth(`/api/user/${getUserId(getState)}/profile`, updateData, getToken(getState))
      .then(() => {
        dispatch(userSetProfile(data));
        dispatch(sendSuccessMessage(message.success));
      })
      .catch((err) => {
        if (err.status === 400) {
          log.warn('Incorrect data was sent or no data was changed');
        } else {
          log.error(`An error occured in userUpdateProfile(): ${err.message}`, err);
        }
        dispatch(userSetProfile(data));
        return dispatch(sendErrorMessage(message.error));
      });
  };
}

export function userActivate(userId, hash, history) {
  const message = {
    error: 'Wystąpił błąd podczas aktywacji konta lub Twoje konto jest już aktywne!',
    success: 'Twoje konto zostało aktywowane. Możesz się teraz zalogować.',
  };

  return (dispatch) => {
    postJson('/api/user/activate', { userId, hash })
      .then(() => {
        dispatch(sendSuccessMessage(message.success));
        history.push('/logowanie');
      })
      .catch((err) => {
        log.error(`An error occured in userActivate(): ${err.message}`, err);
        return dispatch(sendErrorMessage(message.error));
      });
  };
}

export function userForgotPass(email) {
  const message = {
    error: 'Wystąpił błąd lub nie znaleziono konta zarejestrowanego na podany adres e-mail!',
    success: 'Aby zmienić hasło na nowe sprawdź pocztę i postępuj zgodnie z instrukcją.',
  };

  return (dispatch) => {
    postJson('/api/user/forgot-password', { email })
      .then(() => dispatch(sendSuccessMessage(message.success)))
      .catch((err) => {
        if (err.status === 400) {
          log.warn('Incorrect data was sent or user does not exist');
        } else {
          log.error(`An error occured in userForgotPass(): ${err.message}`, err);
        }
        return dispatch(sendErrorMessage(message.error));
      });
  };
}

export function userSetNewPass(userId, hash, password) {
  const message = {
    error: 'Wystąpił błąd podczas zmiany hasła lub użyto tego samego hasła!',
    success: 'Hasło zostało zmienione.',
  };

  return (dispatch) => {
    postJson(`/api/user/new-pass/${userId}/${hash}`, { password })
      .then(() => dispatch(sendSuccessMessage(message.success)))
      .catch((err) => {
        if (err.status === 400) {
          log.warn('Incorrect data was sent or the same password used');
        } else {
          log.error(`An error occured in userSetNewPass(): ${err.message}`, err);
        }
        return dispatch(sendErrorMessage(message.error));
      });
  };
}

export function userChangePass(currentPassword, newPassword) {
  const message = {
    error: 'Wystąpił błąd podczas zmiany hasła lub użyto tego samego hasła!',
    success: 'Hasło zostało zmienione.',
  };

  return (dispatch, getState) => {
    postJsonAuth(`/api/user/${getUserId(getState)}/change-pass`,
      { currentPassword, newPassword },
      getToken(getState))
      .then(() => dispatch(sendSuccessMessage(message.success)))
      .catch((err) => {
        if (err.status === 400) {
          log.warn('Incorrect data was sent or the same password used');
        } else {
          log.error(`An error occured in userChangePass(): ${err.message}`, err);
        }
        return dispatch(sendErrorMessage(message.error));
      });
  };
}

export function userLogOut() {
  return (dispatch) => {
    dispatch(userLoggedIn(null));
    updateSentryUserId(null);
    localStorage.removeItem('user.profile');
  };
}

export function userRemoveAccount() {
  const message = {
    error: 'Wystąpił błąd podczas usuwania konta!',
    success: 'Konto zostało usunięte.',
  };

  return (dispatch, getState) => {
    deleteJsonAuth(`/api/user/${getUserId(getState)}/remove-account`, getToken(getState))
      .then(() => {
        dispatch(sendSuccessMessage(message.success));
        userLogOut()(dispatch);
      })
      .catch((err) => {
        log.error(`An error occured in userRemoveAccount(): ${err.message}`, err);
        return dispatch(sendErrorMessage(message.error));
      });
  };
}

export function closeCookieBar() {
  return (dispatch) => dispatch(userCloseCookieBar());
}
