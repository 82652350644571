import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { persistReducer } from 'redux-persist';
import { estatesPersistConfig, formPersistConfig } from '../store/persistConfig';
import adminReducer from './adminReducer';
import breadcrumbsReducer from './breadcrumbsReducer';
import citiesReducer from './citiesReducer';
import estatesReducer from './estatesReducer';
import messagesReducer from './messagesReducer';
import safeReducer from './safeReducer';
import userReducer from './userReducer';

export default combineReducers({
  admin: adminReducer,
  breadcrumbs: breadcrumbsReducer,
  cities: citiesReducer,
  estates: persistReducer(estatesPersistConfig, estatesReducer),
  form: persistReducer(formPersistConfig, formReducer),
  messages: messagesReducer,
  safe: safeReducer,
  user: userReducer,
});
