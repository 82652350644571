import { USER_ACTION_TYPES } from '../actions/userActions';

export default function user(state = {}, action = {}) {
  switch (action.type) {
    case USER_ACTION_TYPES.USER_INTEGRATION:
      return { ...state, integration: action.data };
    case USER_ACTION_TYPES.USER_LOG_IN:
      return { ...state, profile: action.data };
    case USER_ACTION_TYPES.USER_NEW_PASS:
      return { ...state, newPassUser: action.data };
    case USER_ACTION_TYPES.USER_PROFILE:
      return { ...state, userProfile: action.data };
    case USER_ACTION_TYPES.USER_CLOSE_COOKIE_BAR:
      return { ...state, showCookieBar: false };
    default:
      return state;
  }
}
