import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import * as userActions from '../../actions/userActions';
import MetaTags from '../../components/MetaTags';
import RecentlyAdded from '../../containers/RecentlyAdded';
import LoginForm from '../../containers/forms/LoginForm';

class Login extends PureComponent {
  static title = 'Logowanie';

  constructor(props) {
    super(props);
    this.handleLogIn = this.handleLogIn.bind(this);
  }

  componentDidMount() {
    this.props.getRecentlyAdded();

    this.props.breadcrumbs([{ title: Login.title }]);
  }

  handleLogIn(values) {
    const { history } = this.props;
    this.props.userLogIn(values, history);
  }

  render() {
    return (
      <div className="login-page">
        <MetaTags title={Login.title} />

        <div className="row">
          <div className="well-container">
            <div
              className="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12
                col-sm-offset-0 col-xs-12 col-xs-offset-0 well-form"
            >
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h1>Zaloguj się</h1>

                <div className="row">
                  <LoginForm onSubmit={this.handleLogIn} />
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h2>Nie masz jeszcze konta?</h2>

                <div className="row col-lg-12 desc-right">
                  Oferujemy:
                  <ul>
                    <li>Bezpłatne konto</li>
                    <li>Nielimitowane dodawanie ogłoszeń</li>
                    <li>Możliwość promowania ogłoszeń</li>
                    <li>Aktualną wyszukiwarkę ogłoszeń</li>
                    <li>Dostęp do serwisu z urządzeń mobilnych</li>
                    <li>Lokalizację obiektów na mapie</li>
                  </ul>

                  <Link className="btn btn-success btn-large pull-right" to="/rejestracja">
                    Zarejestruj się
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RecentlyAdded />
      </div>
    );
  }
}

Login.propTypes = {
  getRecentlyAdded: PropTypes.func.isRequired,
  userLogIn: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.func.isRequired,
  history: PropTypes.object,
};

export default connect(
  () => ({}),
  (dispatch) => bindActionCreators(
    { ...breadcrumbsActions, ...estatesActions, ...userActions }, dispatch,
  ),
)(Login);
