import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as breadcrumbsActions from '../actions/breadcrumbsActions';
import MetaTags from '../components/MetaTags';

function PrivacyPolicy(props) {
  const title = 'Polityka prywatności';
  props.breadcrumbs([{ title }]);

  return (
    <div className="privacy-policy-page">
      <MetaTags title={title} />

      <div className="row regulations margin-bottom20">
        <h1>{title}</h1>
        <div className="col-lg-10 col-lg-offset-1">
          <h2>&sect; 1. Pliki cookies</h2>
          <ol>
            <li>
              Serwis nie zbiera w sposób automatyczny żadnych informacji, z wyjątkiem informacji
              zawartych w&nbsp;plikach cookies.
            </li>
            <li>
              Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w&nbsp;szczególności
              pliki tekstowe, które przechowywane są w&nbsp;urządzeniu końcowym Użytkownika serwisu
              i&nbsp;przeznaczone są do korzystania ze stron internetowych serwisu. Cookies
              zazwyczaj zawierają nazwę strony internetowej, z&nbsp;której pochodzą,
              czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.
            </li>
            <li>
              Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika serwisu pliki cookies
              oraz uzyskującym do nich dostęp jest operator serwisu.
            </li>
            <li>
              Pliki cookies wykorzystywane są w&nbsp;celu:
              <ol className="list-alpha">
                <li>
                  dostosowania zawartości stron internetowych serwisu do preferencji Użytkownika
                  oraz optymalizacji korzystania ze stron internetowych; w&nbsp;szczególności pliki
                  te pozwalają rozpoznać urządzenie Użytkownika serwisu i&nbsp;odpowiednio
                  wyświetlić stronę internetową, dostosowaną do jego indywidualnych potrzeb,
                </li>
                <li>
                  tworzenia statystyk, które pomagają zrozumieć, w&nbsp;jaki sposób Użytkownicy
                  serwisu korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury
                  i&nbsp;zawartości,
                </li>
                <li>
                  utrzymanie sesji Użytkownika serwisu (po zalogowaniu), dzięki której Użytkownik
                  nie musi na każdej podstronie serwisu ponownie wpisywać loginu i&nbsp;hasła,
                </li>
                <li>
                  automatyzacji procesów marketingowych takich jak analiza ruchu na stronie,
                  automatyczna wysyłka e-maili reklamowych itp.
                </li>
              </ol>
            </li>
            <li>
              W&nbsp;ramach serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne”
              (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami
              tymczasowymi, które przechowywane są w&nbsp;urządzeniu końcowym Użytkownika do czasu
              wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania
              (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w&nbsp;urządzeniu
              końcowym Użytkownika przez czas określony w&nbsp;parametrach plików cookies lub do
              czasu ich usunięcia przez Użytkownika.
            </li>
            <li>
              W&nbsp;ramach serwisu stosowane są następujące rodzaje plików cookies:
              <ol className="list-alpha">
                <li>
                  „niezbędne” pliki cookies, umożliwiające korzystanie z&nbsp;usług dostępnych
                  w&nbsp;ramach serwisu, np. uwierzytelniające pliki cookies wykorzystywane do
                  usług wymagających uwierzytelniania w&nbsp;ramach serwisu,
                </li>
                <li>
                  pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do
                  wykrywania nadużyć w&nbsp;zakresie uwierzytelniania w&nbsp;ramach serwisu,
                </li>
                <li>
                  „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez
                  Użytkownika ustawień i&nbsp;personalizację interfejsu Użytkownika, np.
                  w&nbsp;zakresie wybranego języka lub regionu, z&nbsp;którego pochodzi Użytkownik,
                  rozmiaru czcionki, wyglądu strony internetowej itp.,
                </li>
                <li>
                  automatyzacji procesów marketingowych takich jak analiza ruchu na stronie,
                  automatyczna wysyłka e-maili reklamowych itp.
                </li>
              </ol>
            </li>
            <li>
              W&nbsp;wielu przypadkach oprogramowanie służące do przeglądania stron internetowych
              (przeglądarka internetowa) domyślnie dopuszcza przechowywanie plików cookies
              w&nbsp;urządzeniu końcowym Użytkownika. Użytkownicy serwisu mogą dokonać w&nbsp;każdym
              czasie zmiany ustawień dotyczących plików cookies. Ustawienia te mogą zostać zmienione
              w&nbsp;szczególności w&nbsp;taki sposób, aby blokować automatyczną obsługę plików
              cookies w&nbsp;ustawieniach przeglądarki internetowej bądź informować o&nbsp;ich
              każdorazowym zamieszczeniu w&nbsp;urządzeniu Użytkownika serwisu. Szczegółowe
              informacje o możliwości i&nbsp;sposobach obsługi plików cookies dostępne są
              w&nbsp;ustawieniach oprogramowania (przeglądarki internetowej).
            </li>
            <li>
              Operator serwisu informuje, że ograniczenia stosowania plików cookies mogą wpłynąć
              na niektóre funkcjonalności dostępne na stronach internetowych serwisu.
            </li>
            <li>
              Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika serwisu
              i&nbsp;wykorzystywane mogą być również przez współpracujących z&nbsp;operatorem
              serwisu reklamodawców oraz partnerów.
            </li>
            <li>
              Więcej informacji na temat plików cookies dostępnych jest w&nbsp;sekcji „Pomoc” w menu
              przeglądarki internetowej.
            </li>
          </ol>
        </div>

        <div className="col-lg-10 col-lg-offset-1">
          <h2>&sect; 2. Bezpieczeństwo danych</h2>
          <ol>
            <li>
              Serwis wymusza korzystanie z&nbsp;bezpiecznego protokołu HTTPS, który szyfruje
              przesyłane dane. Istnieje możliwość sprawdzenia poziomu zabezpieczeń i&nbsp;ważności
              certyfikatu bezpieczeństwa z&nbsp;poziomu przeglądarki internetowej.
            </li>
            <li>
              Hasła są hashowane (szyfrowane jednostronnie) korzystając jednocześnie
              z&nbsp;dodatkowej puli znaków poprawiającej bezpieczeństwo. W&nbsp;ten sposób serwis
              nie przetrzymuje haseł a&nbsp;jedynie sumy kontrolne,
              które są jedynie sprawdzane w&nbsp;momencie logowania do serwisu.
            </li>
          </ol>
        </div>

        <div className="col-lg-10 col-lg-offset-1">
          <h2>&sect; 3. RODO</h2>
          <ol>
            <li>
              Jest to skrót od Rozporządzenia o&nbsp;Ochronie Danych Osobowych. RODO wprowadza nowe
              zagadnienia z&nbsp;zakresu praw klientów. Jednym z&nbsp;celów RODO jest szczegółowe
              informowanie klientów o&nbsp;przetwarzaniu ich danych.
            </li>
            <li>
              Serwis zbiera informacje podawane przez Użytkownika, gdy:
              <ol className="list-alpha">
                <li>
                  <strong>Użytkownik zakłada konto:</strong>
                  {' '}
                  zbierane informacje to: imię
                  i&nbsp;nazwisko lub nazwa firmy, adres, adres e-mail oraz numer telefonu
                </li>
                <li>
                  <strong>Weryfikacja Użytkownika lub jego konta:</strong>
                  {' '}
                  mogą być zbierane
                  i&nbsp;przetwarzane informacje (zgodnie z&nbsp;przepisami prawa) w&nbsp;celu
                  weryfikacji Użytkownika, jego konta, lub otrzymanych danych
                </li>
                <li>
                  <strong>Użytkownik publikuje ogłoszenie:</strong>
                  {' '}
                  zbierane informacje to
                  podstawowe dane dotyczące nieruchomości, które póżniej są prezentowane wraz
                  z&nbsp;danymi kontaktowymi do użytkownika (numer telefonu, adres e-mail)
                </li>
                <li>
                  <strong>Użytkownik wykonuje operacje na koncie:</strong>
                  {' '}
                  np. aktualizuje lub
                  dodaje informacje do konta, dodaje oferty do listy alertów i&nbsp;zapisuje
                  wyszukiwania
                </li>
                <li>
                  <strong>Użytkownik kontaktuje się z&nbsp;nami:</strong>
                  {' '}
                  za pośrednictwem
                  formularza internetowego
                </li>
              </ol>
            </li>
            <li>
              Serwis nie wykorzystuje w&nbsp;żaden sposób zbieranych informacji. Jedynie prezentuje
              zamieszczone ogłoszenia oraz dane kontaktowe: adres e-mail oraz numer telefonu.
            </li>
            <li>
              Użytkownik w&nbsp;każdej chwili może usunąć dodane ogłoszenie oraz konto, korzystając
              z&nbsp;opcji dostępnych z poziomu panelu &quot;Moje konto&quot;.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}

PrivacyPolicy.propTypes = {
  breadcrumbs: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  dispatch => bindActionCreators(breadcrumbsActions, dispatch),
)(PrivacyPolicy);
