import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormGroup } from 'react-bootstrap';

const RECAPTCHA_SITE_KEY = process.env.REACT_RECAPTCHA_SITE_KEY || window.REACT_RECAPTCHA_SITE_KEY;

class Captcha extends Component {
  constructor(props) {
    super(props);
    this.captcha = React.createRef();
  }

  componentDidUpdate() {
    if (this.props.meta.error && !this.props.meta.submitFailed) {
      this.captcha.current.props.grecaptcha.reset();
    }
  }

  render() {
    return (
      <FormGroup className="col-xs-12">
        <ReCAPTCHA
          ref={this.captcha}
          sitekey={RECAPTCHA_SITE_KEY}
          onChange={this.props.input.onChange}
        />
        {this.props.meta.error && (
          <div>
            <button type="button" onClick={() => { this.captcha.current.props.grecaptcha.reset(); }}>
              Odśwież
            </button>
            <span className="captcha-error">{this.props.meta.error}</span>
          </div>
        )}
      </FormGroup>
    );
  }
}

Captcha.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default Captcha;
