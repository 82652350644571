import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, reset } from 'redux-form';
import { FormGroup, Button } from 'react-bootstrap';
import { validation, required, minLength, identical } from '../../utils/forms/validation';
import ReduxFormControl from '../../components/forms/ReduxFormControl';

class NewPassForm extends PureComponent {
  render() {
    const { handleSubmit } = this.props;

    return (
      <form className="row register-company" onSubmit={handleSubmit}>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <Field component={ReduxFormControl} name="password" type="password" placeholder="Nowe hasło" />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <Field component={ReduxFormControl} name="rePassword" type="password" placeholder="Powtórz nowe hasło" />
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <FormGroup controlId="newPass-submit">
            <Button bsStyle="success" className="submit" type="submit">
              Zmień hasło
            </Button>
          </FormGroup>
        </div>
      </form>
    );
  }
}

NewPassForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const validate = (values) => {
  const errors = {};

  errors.password = validation(values.password, [required(), minLength(3)]);
  errors.rePassword = validation(
    values.rePassword, [required(), minLength(3), identical(values.password)],
  );

  return errors;
};

const onSubmitSuccess = (result, dispatch) => dispatch(reset('newPass'));

export default reduxForm({ form: 'newPass', validate, onSubmitSuccess })(NewPassForm);
