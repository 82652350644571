import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import * as userActions from '../../actions/userActions';
import MetaTags from '../../components/MetaTags';
import RecentlyAdded from '../../containers/RecentlyAdded';

class Activation extends PureComponent {
  static title = 'Aktywacja konta';

  componentDidMount() {
    this.props.getRecentlyAdded();

    this.props.breadcrumbs([{ title: Activation.title }]);

    const { userId, hash } = this.props.match.params;
    const { history } = this.props;
    this.props.userActivate(userId, hash, history);
  }

  render() {
    return (
      <div className="activation-page">
        <MetaTags title={Activation.title} />

        <div className="row">
          <h1>{Activation.title}</h1>
        </div>

        <RecentlyAdded />
      </div>
    );
  }
}

Activation.propTypes = {
  match: PropTypes.object.isRequired,
  getRecentlyAdded: PropTypes.func.isRequired,
  userActivate: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.func.isRequired,
  history: PropTypes.object,
};

export default connect(
  () => ({}),
  (dispatch) => bindActionCreators(
    { ...breadcrumbsActions, ...estatesActions, ...userActions }, dispatch,
  ),
)(Activation);
