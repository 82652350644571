/* eslint import/prefer-default-export: 0 */
import _ from 'lodash';
import types from '../../data/estate/types';

function getType(label, singular = false) {
  const predicate = {};
  predicate[singular ? 'singular' : 'plural'] = label;

  return _.findKey(types, predicate);
}

export { getType };
