import moment from 'moment-timezone';

const timezone = 'Europe/Warsaw';

const checkIfExpired = (expiration) => (moment.unix(expiration).tz(timezone).diff(moment().tz(timezone), 'seconds') <= 0);

export function getProfileFromState(getState) {
  const { profile } = getState().user;
  const isExpired = checkIfExpired(profile.expiration);

  if (isExpired) {
    // Refresh the page to force log out
    window.location.reload(true);
    return {};
  }

  return profile;
}

export function getProfileFromStorage() {
  const profileKey = 'user.profile';
  const profileStored = localStorage.getItem(profileKey);

  if (profileStored) {
    const profile = JSON.parse(profileStored);
    const isExpired = checkIfExpired(profile.expiration);

    if (isExpired) {
      localStorage.removeItem(profileKey);
    } else {
      return profile;
    }
  }

  return {};
}
