import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import MetaTags from '../../components/MetaTags';
import AddAdSteps from '../../components/estate/AddAdSteps';
import PhotosForm from '../../containers/forms/PhotosForm';

const maxStepsCondition = (maxSteps) => (!maxSteps || maxSteps < 2);

class AddAdStep2 extends PureComponent {
  static title = 'Dodawanie ogłoszenia';

  constructor(props) {
    super(props);
    const { history, maxSteps } = props;
    const { type } = props.match.params;

    this.handleForm = this.handleForm.bind(this, history, type, this.props.estateId);
    this.handleUpload = this.handleUpload.bind(this, type, this.props.estateId);

    if (maxStepsCondition(maxSteps)) props.history.push('/dodaj-ogloszenie');
  }

  componentDidMount() {
    this.props.breadcrumbs([{ title: AddAdStep2.title }]);

    const { estateId } = this.props;
    if (estateId) {
      this.fetchData(estateId);
    }
  }

  handleForm(history, type, estateId) {
    this.props.estateAdStep2Submit(type, estateId, history);
  }

  handleUpload(type, estateId, acceptedFiles) {
    this.props.estateAdStep2Upload(type, estateId, acceptedFiles);
  }

  fetchData(estateId) {
    this.props.estateAdStep2Load(estateId);
  }

  render() {
    const { photos, maxSteps, estateId } = this.props;
    const { type } = this.props.match.params;

    if (maxStepsCondition(maxSteps)) return null;

    return (
      <div className="add-add-page-2">
        <MetaTags title={AddAdStep2.title} />

        <div className="row">
          <h1>{AddAdStep2.title}</h1>
        </div>

        <div className="row">
          <div className="well-container">
            <AddAdSteps type={type} step={2} steps={maxSteps} />

            <div className="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12
              col-sm-offset-0 col-xs-12 col-xs-offset-0 well-form"
            >
              <PhotosForm
                onSubmit={this.handleForm} onUpload={this.handleUpload}
                type={type} estateId={estateId.toString()} photos={photos}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddAdStep2.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  breadcrumbs: PropTypes.func.isRequired,
  estateAdStep2Load: PropTypes.func.isRequired,
  estateAdStep2Upload: PropTypes.func.isRequired,
  estateAdStep2Submit: PropTypes.func.isRequired,
  estateId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  maxSteps: PropTypes.number,
  photos: PropTypes.array,
};

export default withRouter(connect(
  (state) => ({
    estateId: state.estates.estateAd.estateId,
    maxSteps: state.estates.estateAd.step,
    photos: state.estates.estateAd.photos,
  }),
  (dispatch) => bindActionCreators({ ...breadcrumbsActions, ...estatesActions }, dispatch),
)(AddAdStep2));
