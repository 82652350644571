import { getProfileFromStorage } from '../utils/getProfile';

export default {
  admin: {
    stats: {
      ads: { activeAdsCount: 0 },
      adsChart: [],
      users: { activeUsersCount: 0 },
    },
  },
  breadcrumbs: [],
  cities: {
    cities: {},
    provinces: {},
    queryResult: [],
  },
  estates: {
    accountEstatesList: null,
    cityEstatesList: null,
    estateAd: { step: 1 },
    estatesList: null,
    promoted: null,
    recentlyAdded: null,
    safeList: null,
    search: null,
    searchResult: null,
    userEstatesList: null,
  },
  safe: [],
  user: {
    integration: null,
    newPassUser: null,
    profile: getProfileFromStorage(),
    userProfile: null,
    showCookieBar: true,
  },
};
