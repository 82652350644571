import React from 'react';

function Agencja3000() {
  return (
    <div>
      <h3>Jak skonfigurować eksport w programie Agencja3000?</h3>
      <p>
        W pierwszej kolejności należy skontaktować się z biurem obsługi Klienta systemu
        {' '}
        <a href="http://www.agencja3000.com" target="_blank" rel="noopener noreferrer">Agencja3000</a>
        , w celu dodania portalu &quot;tanieruchomosci.pl&quot; do listy dostępnych do eksportu
        portali.
        <br />
        Na tym etapie należy również podać pełne dane dostępowe znajdujące się powyżej
        (adres FTP, login, hasło), w&nbsp;celu włączenia eksportu na serwery portalu.
        <br />
        <strong>Ważne jest</strong>
        , aby podać &quot;Format pliku XML&quot;, jako iż definiuje on
        poprawne parsowanie otrzymanych przez nas danych.
        Bez tej informacji eksport będzie działał niepoprawnie!
        <br />
        <br />
        Ostatni krok to ustawienie eksportu w systemie Agencja3000 (szczegóły w instrukcji).
        Zgodnie z ideą oprogramowania dalej wszystko dzieje się automatycznie.
        <br />
        <br />
        <br />
        <br />
      </p>
    </div>
  );
}

export default Agencja3000;
