const LIMIT = 10;

function calculateStart(page, pages) {
  if (pages <= LIMIT) return 1;

  // If first half of LIMIT then start with 1
  if (page < (LIMIT / 2)) return 1;

  // If last LIMIT of items then show last LIMIT
  if (page > pages - LIMIT) return (pages - LIMIT) + 1;

  // Oherwise start half of LIMIT eariler
  return (page - (LIMIT / 2)) + 1;
}

function calculateEnd(page, pages) {
  if (pages <= LIMIT) return pages;

  // If last LIMIT of items show end
  if (page > pages - LIMIT) return pages;

  // If first half of LIMIT of items show LIMIT of items
  if (page <= (LIMIT / 2)) return LIMIT;

  // Otherwise end half of LIMIT later
  return page + (LIMIT / 2);
}

/*
  Show left side when:
  - there are more pages than LIMIT
  - current page is different than first one
*/
const showLeftSide = (page, pages) => (pages > LIMIT && page > 1);
/*
  Show right side when:
  - there are more pages than LIMIT
  - current page is different than last one
*/
const showRightSide = (page, pages) => (pages > LIMIT && page < pages);
/*
  Show left side ellipsis when:
  - there are more pages than LIMIT
  - current page is higher than first half of LIMIT
*/
const showLeftEllipsis = (page, pages) => (pages > LIMIT && page > (LIMIT / 2));
/*
  Show right side ellipsis when:
  - there are more pages than LIMIT
  - current page is lower than last half of LIMIT
*/
const showRightEllipsis = (page, pages) => (pages > LIMIT && page + (LIMIT / 2) < pages);

export { calculateStart };
export { calculateEnd };
export { showLeftSide };
export { showRightSide };
export { showLeftEllipsis };
export { showRightEllipsis };
