import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import * as userActions from '../../actions/userActions';
import MetaTags from '../../components/MetaTags';
import RecentlyAdded from '../../containers/RecentlyAdded';
import ChangePassForm from '../../containers/forms/ChangePassForm';

class ChangePassword extends Component {
  static title = 'Zmiana hasła';

  constructor(props) {
    super(props);
    this.handleForm = this.handleForm.bind(this);
  }

  componentDidMount() {
    this.props.getRecentlyAdded();

    this.props.breadcrumbs([{ title: ChangePassword.title }]);
  }

  handleForm(values) {
    this.props.userChangePass(values.currentPassword, values.newPassword);
  }

  render() {
    return (
      <div className="change-password-page">
        <MetaTags title={ChangePassword.title} />

        <div className="row">
          <div className="well-container">
            <div
              className="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12
                col-sm-offset-0 col-xs-12 col-xs-offset-0 well-form"
            >
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h1>{ChangePassword.title}</h1>

                <ChangePassForm onSubmit={this.handleForm} />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h2>Chcesz zmienić hasło?</h2>
                <div className="row col-lg-12 desc-right">
                  Wystarczy, że:
                  <ul>
                    <li>Podasz swoje aktualne hasło</li>
                    <li>Podasz i poprawnie powtórzysz nowe hasło</li>
                    <li>Hasło musi spełniać określone kryteria</li>
                    <li>Potwierdzenie pocztą elektroniczną nie jest wymagane</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RecentlyAdded />
      </div>
    );
  }
}

ChangePassword.propTypes = {
  getRecentlyAdded: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.func.isRequired,
  userChangePass: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  (dispatch) => bindActionCreators(
    { ...breadcrumbsActions, ...estatesActions, ...userActions }, dispatch,
  ),
)(ChangePassword);
