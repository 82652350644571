import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as breadcrumbsActions from '../actions/breadcrumbsActions';
import MetaTags from '../components/MetaTags';
import OfferList from '../components/OfferList';

function About(props) {
  const title = 'O nas';
  props.breadcrumbs([{ title }]);

  return (
    <div className="about-page">
      <MetaTags title={title} />

      <div className="row">
        <h1>{title}</h1>

        <div className="col-lg-12">
          <p>
            Serwis jest w&nbsp;całości poświęcony rynkowi nieruchomości. Znajdziesz tu aktualne
            oferty z rynku pierwotnego i&nbsp;wtórnego. Oferty nieruchomości pogrupowane są
            w&nbsp;działy: domy, mieszkania, działki, lokale użytkowe oraz obiekty użytkowe.
            Szukając nieruchomości w&nbsp;prosty sposób określisz czy interesuje Cię kupno czy
            najem nieruchomości. Jeśli chcesz sprzedać lub wynająć nieruchomość, zamieść swoją
            ofertę w&nbsp;naszym serwisie za darmo!
          </p>
          <p>
            Ogłaszają się w&nbsp;nim zarówno agencje nieruchomości jak i&nbsp;osoby prywatne.
            Zamieszczanie ogłoszeń w&nbsp;serwisie przede wszystkim pozwala na dotarcie do ściśle
            określonej grupy potencjalnych klientów. W&nbsp;dzisiejszych czasach ważną zaletą
            serwisu internetowego jest wykorzystanie wielu możliwości oraz nowoczesnych technologii.
            Dlatego nasz serwis dostępny jest na wszelkiego rodzaju urządzenia w&nbsp;tym dotykowe
            i&nbsp;mobilne. Ponadto pozwala na określenie w&nbsp;łatwy sposób lokalizacji danej
            nieruchomości, a&nbsp;nawet zwizualizowanie rzeczywistego widoku.
          </p>
          <p>
            Dla osób prywatnych chcących sprzedać, kupić lub wynająć nieruchomość rejestracja
            w&nbsp;serwisie jest całkowicie bezpłatna. Wszyscy użytkownicy, którzy założą konto,
            otrzymują kilka możliwości, dzięki którym korzystanie z&nbsp;serwisu będzie jeszcze
            wygodniejsze. Będąc zarejestrowanym użytkownikiem masz możliwość zarządzania
            wprowadzonymi ogłoszeniami - edytowania, usuwania oraz dodawania nowych bez konieczności
            ponownego wprowadzania swoich danych. Drugim udogodnieniem, do którego dostęp mają tylko
            zarejestrowani użytkownicy, jest możliwość otrzymywania informacji dotyczących nowości,
            akcji promocyjnych itp. Informacje przesyłane są drogą elektroniczną.
          </p>
        </div>
      </div>

      <OfferList />
    </div>
  );
}

About.propTypes = {
  breadcrumbs: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  dispatch => bindActionCreators(breadcrumbsActions, dispatch),
)(About);
