import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as breadcrumbsActions from '../actions/breadcrumbsActions';
import MetaTags from '../components/MetaTags';

function ImportAds(props) {
  const title = 'Eksport ofert na portal';
  props.breadcrumbs([{ title }]);

  return (
    <div className="import-ads-page">
      <MetaTags title={title} />

      <div className="row regulations margin-bottom20">
        <h1>{title}</h1>
        <div className="col-lg-10 col-lg-offset-1">
          <p>
            Zarejestrowanym Użytkownikom naszego serwisu udostępniamy możliwość uruchomienia usługi
            automatycznego eksportu ogłoszeń z&nbsp;oprogramowania, którego używają
            w&nbsp;swoim biurze.
            <br />
          </p>
          <p>
            Usługa polega na automatycznej wysyłce ofert nieruchomości do naszego serwisu
            z&nbsp;wcześniej skonfigurowanego systemu do obsługi biur nieruchomości. Konfiguracja
            odbywa się poprzez podanie danych dostępowych do naszych serwerów oraz włączenie
            eksportu wszystkich lub poszczególnych ogłoszeń w&nbsp;używanym oprogramowaniu.
            Import ofert realizowany jest automatycznie minimum raz dziennie, zazwyczaj
            w&nbsp;godzinach nocnych. Ogłoszenia oraz zdjęcia są bez jakiejkolwiek dodatkowej pracy:
            dodawane, wycofywane i&nbsp;aktualizowane.
            <br />
            <br />
          </p>
          <div className="paragraph">
            <h4>Atuty usługi:</h4>
            <ul>
              <li>
                Oszczędność czasu - ogłoszeniami nie trzeba ręcznie zarządzać, gdyż importują się
                automatycznie.
              </li>
              <li>
                Zwiększenie efektywności - oferty prezentowane w&nbsp;większej ilości serwisów
                docierają do większej grupy ludzi, co zwiększa szansę na powodzenie.
              </li>
            </ul>
          </div>
          <p>
            <br />
            Lista obsługiwanych systemów znajduje się poniżej:
          </p>

          <div className="software-list">
            <h4>ASARI CRM</h4>
            <div className="software-logo">
              <a
                href="https://asaricrm.com" target="_blank"
                rel="noopener noreferrer"
                title="ASARI CRM"
              >
                <img
                  src="/img/partners/asari-crm.png"
                  alt="ASARI CRM"
                />
              </a>
            </div>
            <p>
              ASARI CRM to program dla biur nieruchomości, który jest jednym z&nbsp;najchętniej
              wybieranych przez pośredników nieruchomości. Wyróżnia go najszybszy automatyczny
              eksport ogłoszeń na portale, zintegrowana strona dla biur nieruchomości działająca
              pod systemem WordPress oraz dostęp do największego w&nbsp;Polsce systemu MLS.
              W&nbsp;systemie ASARI znajdziesz również narzędzia wspierające sprzedaż, zarządzanie
              bazą ofert i&nbsp;klientów, zdalne kierowanie pracą agentów w&nbsp;terenie oraz
              możliwość automatyzacji procesów i&nbsp;komunikacji.
            </p>
          </div>

          <div className="software-list">
            <h4>Galactica VIRGO</h4>
            <div className="software-logo">
              <a
                href="http://virgo.galactica.pl" target="_blank"
                rel="noopener noreferrer"
                title="Galactica VIRGO - zintegrowany system dla biur nieruchomości"
              >
                <img
                  src="/img/partners/galactica-virgo.jpg"
                  alt="Galactica VIRGO - zintegrowany system dla biur nieruchomości"
                />
              </a>
            </div>
            <p>
              Galactica Virgo to internetowy system wielu zintegrowanych funkcji, złożonych
              w&nbsp;jedną całość.
              <br />
              Integruje bazę nieruchomości z&nbsp;portalami
              ogłoszeniowymi, kalendarz z&nbsp;bazą klientów, poczty e-mail z&nbsp;bazą CRM,
              strony WWW z&nbsp;bazą ofert, może także zintegrować Twoją agencję z&nbsp;innymi
              i&nbsp;wiele innych cennych funkcji.
            </p>
          </div>

          <div className="software-list">
            <h4>EstiCRM</h4>
            <p>
              EstiCRM jest nowoczesnym oprogramowaniem dla biur i&nbsp;agencji nieruchomości.
              Aplikacja w&nbsp;łatwy i&nbsp;przejrzysty sposób umożliwia zarządzanie ofertami,
              klientami i&nbsp;pracownikami.
              EstiCRM zapewnia szybki i&nbsp;stabilny eksport ofert na wszystkie popularne portale
              nieruchomości.
              Ponadto oferujemy firmową stronę WWW, pocztę oraz hosting.
            </p>
          </div>

          <div className="software-list">
            <h4>IMO</h4>
            <p>
              IMO to nowoczesny i przede wszystkim łatwy w obsłudze program dla pośredników
              w obrocie nieruchomościami i&nbsp;deweloperów, stanowiący podstawowe narzędzie ich
              pracy. Integracja z&nbsp;blisko 100 portalami ogłoszeniowymi i&nbsp;zewnętrznymi
              systemami typu AMER, ułatwia automatyczną publikację ofert w&nbsp;Internecie oraz
              pozyskiwanie nowych ofert i&nbsp;zapytań klientów.
            </p>
          </div>

          <div className="software-list">
            <h4>Agencja3000</h4>
            <p>
              Agencja3000 to nowoczesny, zintegrowany system bazodanowy wspomagający pracę zarówno
              małego – jedno- lub kilkuosobowego, jak i&nbsp;dużego wielooddziałowego biura obrotu
              nieruchomościami.
              <br />
              Zaprojektowany wspólnie przez doświadczonych programistów
              i&nbsp;specjalistów z&nbsp;zakresu obrotu nieruchomościami odpowiada aktualnym
              potrzebom zinformatyzowanego rynku.
            </p>
          </div>

          <p>
            <br />
            Autorskie rozwiązania
            {' '}
            <strong>nie są wspierane</strong>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

ImportAds.propTypes = {
  breadcrumbs: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  dispatch => bindActionCreators(breadcrumbsActions, dispatch),
)(ImportAds);
