import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as safeActions from '../../actions/safeActions';

class SafeBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { active: false };
    this.handleClick = this.handleClick.bind(this);
    this.handleMouseOver = this.activateBar.bind(this, true);
    this.handleMouseOut = this.activateBar.bind(this, false);
  }

  handleClick(estateId) {
    this.props.updateSafeItem(estateId);
  }

  activateBar = (active) => {
    this.setState({ active });
  };

  render() {
    const isSaved = this.props.safe.indexOf(this.props.estateId) !== -1;
    const classActive = this.state.active ? ' active' : '';
    const classItemActive = isSaved ? 'fa-star active' : 'fa-star-o';
    const text = isSaved ? 'Usuń ze schowka' : 'Dodaj do schowka';

    return (
      <div className={`safe-bar${classActive}`}>
        <span>{text}</span>
        <i className={`safe-star fa ${classItemActive}`} role="button" tabIndex={0}
          onClick={() => this.handleClick(this.props.estateId)} aria-label={text}
          onKeyDown={() => this.handleClick(this.props.estateId)}
          onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut}
          onFocus={this.handleMouseOver} onBlur={this.handleMouseOut}
        />
      </div>
    );
  }
}

SafeBar.propTypes = {
  estateId: PropTypes.number.isRequired,
  safe: PropTypes.array.isRequired,
  updateSafeItem: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({ safe: state.safe }),
  (dispatch) => bindActionCreators(safeActions, dispatch),
)(SafeBar);
