import React from 'react';
import { Switch } from 'react-router-dom';
import LayoutRoute from './LayoutRoute';
import CityEstatesList from '../pages/estates/CityEstatesList';
import EstatesItem from '../pages/estates/EstatesItem';
import EstatesList from '../pages/estates/EstatesList';
import ManageAds from '../pages/user/ManageAds';
import NewAd from '../pages/estates/NewAd';
import UserEstatesList from '../pages/estates/UserEstatesList';
import SafeList from '../pages/estates/SafeList';
import SearchResult from '../pages/estates/SearchResult';
import AddAdStep1 from '../pages/estates/AddAdStep1';
import AddAdStep2 from '../pages/estates/AddAdStep2';
import AddAdStep3 from '../pages/estates/AddAdStep3';
import EditAdStep1 from '../pages/estates/EditAdStep1';
import EditAdStep2 from '../pages/estates/EditAdStep2';
import EditAdStep3 from '../pages/estates/EditAdStep3';
import { rentOptions, typesPlural, typesSingular } from './params/estates';
import NotFound from '../pages/NotFound';

function EstatesRoutes() {
  return (
    <Switch>
      <LayoutRoute exact path="/schowek" component={SafeList} />
      <LayoutRoute exact path="/wyszukaj-ogloszenia" component={SearchResult} />
      <LayoutRoute
        exact path={`/:type(${typesPlural})/:rent(${rentOptions})/:cityId([0-9]+)/:city/:offer(oferty-)?:page([0-9]+)?`}
        component={CityEstatesList}
      />
      <LayoutRoute
        exact path={`/:type(${typesPlural})/:rent(${rentOptions})/:offer(oferty-)?:page([0-9]+)?`}
        component={EstatesList}
      />
      <LayoutRoute
        exact path="/ogloszenia-uzytkownika/:userId([0-9]+):offer(/oferty-)?:page([0-9]+)?"
        component={UserEstatesList}
      />
      <LayoutRoute
        exact path={`/:type(${typesSingular})/:slug/:id`}
        component={EstatesItem}
      />
      <LayoutRoute exact path="/zarzadzaj-ogloszeniami:offer(/oferty-)?:page([0-9]+)?" ensureLoggedIn component={ManageAds} />
      <LayoutRoute exact path="/dodaj-ogloszenie" ensureLoggedIn component={NewAd} />
      <LayoutRoute exact path={`/dodaj-ogloszenie/:type(${typesSingular})/krok/1`}
        ensureLoggedIn component={AddAdStep1}
      />
      <LayoutRoute exact path="/edytuj-ogloszenie/:estateId([0-9]+)/krok/1"
        ensureLoggedIn component={EditAdStep1}
      />
      <LayoutRoute exact path={`/dodaj-ogloszenie/:type(${typesSingular})/krok/2`}
        ensureLoggedIn component={AddAdStep2}
      />
      <LayoutRoute exact path="/edytuj-ogloszenie/:estateId([0-9]+)/krok/2"
        ensureLoggedIn component={EditAdStep2}
      />
      <LayoutRoute exact path={`/dodaj-ogloszenie/:type(${typesSingular})/krok/3`}
        ensureLoggedIn component={AddAdStep3}
      />
      <LayoutRoute exact path="/edytuj-ogloszenie/:estateId([0-9]+)/krok/3"
        ensureLoggedIn component={EditAdStep3}
      />

      <LayoutRoute component={NotFound} />
    </Switch>
  );
}

export default EstatesRoutes;
