import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Glyphicon } from 'react-bootstrap';

class AdStats extends PureComponent {
  render() {
    const { stats } = this.props;

    return (
      <div className="ad-stats">
        <Glyphicon glyph="eye-open" className="pull-left" />
        <span className="pull-left">
          {stats.pages || 0}
        </span>
        <Glyphicon glyph="earphone" className="pull-left" />
        <span className="pull-left">
          {stats.phones || 0}
        </span>
      </div>
    );
  }
}

AdStats.propTypes = {
  stats: PropTypes.shape({
    pages: PropTypes.number,
    phones: PropTypes.number,
  }),
};

export default AdStats;
