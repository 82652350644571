import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import estateTypeDetails from './estate/details';
import GeoLocation from './estate/GeoLocation';
import EstateDetails from './estate/EstateDetails';
import AdditionalInformation from './estate/AdditionalInformation';
import GallerySlider from './estate/GallerySlider';
import ShareEstate from './estate/ShareEstate';
import { userEstatesUrl, similarEstatesUrl } from '../utils/url';
import ErrorNotFound from './ErrorNotFound';

class EstatesItem extends PureComponent {
  renderTypeDetails() {
    const { estate } = this.props;
    const EstateTypeDetails = estateTypeDetails[estate.type];

    return <EstateTypeDetails {...estate} />;
  }

  render() {
    const { estate, preview } = this.props;
    const isEmpty = !estate;

    if (estate && _.isEmpty(estate)) return (<ErrorNotFound />);

    const description = !isEmpty ? _.upperFirst(estate.description.trim()) : null;

    return (
      <div className="estate-item">
        <div className="ad-details row">
          <h1>{!isEmpty ? estate.title : <Skeleton width={200} height={25} />}</h1>
          <div className="col-lg-4 col-md-4 col-sm-5 col-xs-12 pull-right">
            {isEmpty && (<Skeleton count={8} height={42} />)}
            {!isEmpty && <EstateDetails {...estate} />}

            {isEmpty && (<Skeleton height={42} style={{ marginTop: '5px' }} />)}
            {!isEmpty && <ShareEstate estate={estate} />}

            {!isEmpty && (this.renderTypeDetails())}
          </div>

          <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12">
            {isEmpty && (
              <div id="ad-gallery"><Skeleton width="100%" style={{ paddingBottom: '64%' }} /></div>
            )}
            {!isEmpty && <GallerySlider estate={estate} preview={preview} />}

            <div className="ad-details-text">
              {!isEmpty ? description : <Skeleton count={10} />}
            </div>

            {!isEmpty && (
              <AdditionalInformation
                {...(_.pick(estate, ['estateId', 'creationDate', 'modificationDate', 'user']))}
              />
            )}

            {!isEmpty && (
              <div className="col-xs-12 dont-print">
                <div className="row more-links">
                  <Link to={userEstatesUrl(estate.user)} className="pull-left">
                    Więcej ofert tego użytkownika
                  </Link>
                  <Link to={similarEstatesUrl(estate)} className="more-ads-city pull-right">
                    Więcej takich ofert w miejscowości
                    {' '}
                    {estate.city.city}
                  </Link>
                </div>
              </div>
            )}
          </div>

          {isEmpty && (
            <Skeleton width="100%" style={{ paddingBottom: '30%' }} />
          )}
          {!isEmpty && (
            <GeoLocation {...(_.pick(estate, ['matchGeo', 'latitudeGeo', 'longitudeGeo']))} />
          )}
        </div>
      </div>
    );
  }
}

EstatesItem.propTypes = {
  match: PropTypes.object,
  estate: PropTypes.object,
  preview: PropTypes.bool,
};

export default withRouter(EstatesItem);
