import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import formatDate from '../../utils/formatDate';
import { pageView } from '../../actions/estatesActions';

class AdditionalInformation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { showEmail: false, showPhone: false };
    this.showEmail = this.showEmail.bind(this);
    this.showPhone = this.showPhone.bind(this);
  }

  showEmail() {
    this.setState({ showEmail: true });
  }

  showPhone() {
    this.setState({ showPhone: true });
    pageView(this.props.estateId, 'phone');
  }

  render() {
    const { creationDate, modificationDate, user } = this.props;

    return (
      <div>
        <ul className="details-list col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <li className="li-grey">Dane kontaktowe:</li>
          <li>
            <i className="fa fa-phone" />
            {' '}
            Telefon:
            {' '}
            {this.state.showPhone ? (
              <span><strong>{user.phone}</strong></span>) : (
                <button type="button" className="btn show-info-btn" onClick={this.showPhone}>
                  Pokaż numer telefonu
                </button>
            )}
          </li>
          <li>
            <i className="fa fa-envelope" />
            {' '}
            E-mail:
            {' '}
            {this.state.showEmail ? (
              <span><strong>{user.email}</strong></span>) : (
                <button type="button" className="btn show-info-btn" onClick={this.showEmail}>
                  Pokaż adres e-mail
                </button>
            )}
          </li>
        </ul>

        <ul className="details-list col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <li className="li-grey">Dodatkowe informacje:</li>
          <li>
            <i className="fa fa-calendar" />
            {' '}
            Data dodania:
            {' '}
            <strong>{formatDate(creationDate)}</strong>
          </li>
          <li>
            <i className="fa fa-calendar" />
            {' '}
            Data aktualizacji:
            {' '}
            <strong>{formatDate(modificationDate)}</strong>
          </li>
        </ul>
      </div>
    );
  }
}

AdditionalInformation.propTypes = {
  estateId: PropTypes.number.isRequired,
  creationDate: PropTypes.string.isRequired,
  modificationDate: PropTypes.string.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }).isRequired,
};

export default AdditionalInformation;
