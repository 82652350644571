import React from 'react';
import MetaTags from './MetaTags';

function ErrorNotFound() {
  return (
    <div className="error-page">
      <MetaTags />

      <h2 className="error-h2 text-center">
        Wystąpił błąd lub strona o podanym adresie nie istnieje!
      </h2>

      <div className="error-404" />
    </div>
  );
}

export default ErrorNotFound;
