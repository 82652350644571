const metaTags = {
  title: 'Darmowe ogłoszenia nieruchomości - domy, mieszkania, działki, lokale użytkowe, obiekty użytkowe',
  description: 'Serwis poświęcony jest rynkowi nieruchomości. Znajdziesz tu oferty z kategorii: domy, mieszkania, '
    + 'działki, lokale użytkowe oraz obiekty użytkowe, a także dodasz swoją ofertę za darmo!',
  keywords: ['darmowe', 'ogłoszenia', 'nieruchomości', 'ogloszenia', 'nieruchomosci', 'tanie', 'domy', 'mieszkania',
    'działki', 'lokale', 'obiekty'],
  image: 'https://www.tanieruchomosci.pl/img/fb-logo.png',
};

export default metaTags;
