import React, { PureComponent } from 'react';
import { Field, FormSection, reduxForm } from 'redux-form';
import { digits, validation } from '../../../utils/forms/validation';
import ReduxFormControl from '../../../components/forms/ReduxFormControl';
import categories from '../../../data/estate/categories';
import materials from '../../../data/estate/materials';

const checkboxes = [
  { name: 'power', label: 'Prąd' },
  { name: 'gas', label: 'Gaz' },
  { name: 'water', label: 'Woda' },
  { name: 'phone', label: 'Telefon' },
  { name: 'internet', label: 'Internet' },
  { name: 'garage', label: 'Garaż' },
  { name: 'basement', label: 'Piwnica' },
  { name: 'furnished', label: 'Umeblowanie' },
  { name: 'parking', label: 'Parking' },
  { name: 'elevator', label: 'Winda' },
];

class PlaceForm extends PureComponent {
  static validate = (values, errors) => {
    /* eslint no-param-reassign: 0 */
    const estateType = values.estateType || {};

    errors.estateType.rooms = validation(estateType.rooms, [digits()]);
    errors.estateType.height = validation(estateType.height, [digits()]);
    errors.estateType.floor = validation(estateType.floor, [digits()]);
    errors.estateType.floors = validation(estateType.floors, [digits()]);
    errors.estateType.year = validation(estateType.year, [digits()]);

    return errors;
  };

  static renderCheckboxes() {
    return checkboxes.map(({ name, label }) => (
      <div key={`checkbox-${name}`} className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
        <Field component={ReduxFormControl} name={name} type="checkbox"
          placeholder={label} useLabel
        />
      </div>
    ));
  }

  render() {
    return (
      <div>
        <FormSection name="estateType">
          <div>
            <Field component={ReduxFormControl} name="category" type="select"
              placeholder="Kategoria" useLabel options={categories.place}
            />
          </div>
          <div>
            <Field component={ReduxFormControl} name="rooms" type="text"
              placeholder="Liczba pokoi" useLabel tooltip="Podaj liczbę pokoi"
            />
          </div>
          <div>
            <Field component={ReduxFormControl} name="height" type="text"
              placeholder="Wysokość pomieszczeń (cm)" useLabel tooltip="Podaj wysokość pomieszczeń w cm"
            />
          </div>
          <div>
            <Field component={ReduxFormControl} name="floor" type="text"
              placeholder="Piętro" useLabel tooltip="Podaj które piętro"
            />
          </div>
          <div>
            <Field component={ReduxFormControl} name="floors" type="text"
              placeholder="Liczba pięter" useLabel tooltip="Podaj liczbę pięter"
            />
          </div>
          <div>
            <Field component={ReduxFormControl} name="year" type="text"
              placeholder="Rok budowy" useLabel
            />
          </div>
          <div>
            <Field component={ReduxFormControl} name="material" type="select"
              placeholder="Materiał" useLabel options={materials}
            />
          </div>

          {PlaceForm.renderCheckboxes()}
        </FormSection>
      </div>
    );
  }
}

export default reduxForm({ form: 'estate' })(PlaceForm);
