const provinces = [
  { value: 2, label: 'dolnośląskie' },
  { value: 4, label: 'kujawsko-pomorskie' },
  { value: 6, label: 'lubelskie' },
  { value: 8, label: 'lubuskie' },
  { value: 10, label: 'łódzkie' },
  { value: 12, label: 'małopolskie' },
  { value: 14, label: 'mazowieckie' },
  { value: 16, label: 'opolskie' },
  { value: 18, label: 'podkarpackie' },
  { value: 20, label: 'podlaskie' },
  { value: 22, label: 'pomorskie' },
  { value: 24, label: 'śląskie' },
  { value: 26, label: 'świętokrzyskie' },
  { value: 28, label: 'warmińsko-mazurskie' },
  { value: 30, label: 'wielkopolskie' },
  { value: 32, label: 'zachodniopomorskie' },
];

export default provinces;
