import { validate as apartment } from './ApartmentForm';
import { validate as house } from './HouseForm';
import { validate as land } from './LandForm';
import { validate as object } from './ObjectForm';
import { validate as place } from './PlaceForm';

export default {
  apartment,
  house,
  land,
  object,
  place,
};
