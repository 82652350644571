import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, FormSection, reduxForm } from 'redux-form';
import { digits, validation } from '../../../utils/forms/validation';
import ReduxFormControl from '../../../components/forms/ReduxFormControl';

const checkboxes = [
  { name: 'internet', label: 'Internet' },
  { name: 'furnished', label: 'Umeblowanie' },
];

const validate = (values, errors) => {
  /* eslint no-param-reassign: 0 */
  const details = values.details || {};

  errors.details.areaFrom = validation(details.areaFrom, [digits()]);
  errors.details.areaTo = validation(details.areaTo, [digits()]);
  errors.details.landAreaFrom = validation(details.landAreaFrom, [digits()]);
  errors.details.landAreaTo = validation(details.landAreaTo, [digits()]);

  return errors;
};

class HouseForm extends PureComponent {
  static renderCheckboxes() {
    return checkboxes.map(({ name, label }) => (
      <div key={`checkbox-${name}`} className="adv-field-cont col-md-2 col-sm-6 col-xs-6">
        <Field component={ReduxFormControl} name={name} type="checkbox"
          placeholder={label} useLabel
        />
      </div>
    ));
  }

  render() {
    const advancedSearchClass = (this.props.advSearch) ? ' visible' : '';

    return (
      <div className={`advanced-form${advancedSearchClass}`}>
        <FormSection name="details">
          <div className="adv-field-cont col-md-2 col-sm-6 col-xs-6">
            <div className="row">
              <Field component={ReduxFormControl} name="areaFrom" type="text"
                placeholder="Powierzchnia od"
              />
            </div>
          </div>
          <div className="adv-field-cont col-md-2 col-sm-6 col-xs-6">
            <div className="row">
              <Field component={ReduxFormControl} name="areaTo" type="text"
                placeholder="Powierzchnia do"
              />
            </div>
          </div>
          <div className="adv-field-cont col-md-2 col-sm-6 col-xs-6">
            <div className="row">
              <Field component={ReduxFormControl} name="landAreaFrom" type="text"
                placeholder="Działka od"
              />
            </div>
          </div>
          <div className="adv-field-cont col-md-2 col-sm-6 col-xs-6">
            <div className="row">
              <Field component={ReduxFormControl} name="landAreaTo" type="text"
                placeholder="Działka do"
              />
            </div>
          </div>
          <div className="clearfix" />

          {HouseForm.renderCheckboxes()}
        </FormSection>
      </div>
    );
  }
}

HouseForm.propTypes = {
  advSearch: PropTypes.bool,
};

export default reduxForm({ form: 'search' })(HouseForm);

export { validate };
