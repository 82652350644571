import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, reset } from 'redux-form';
import { FormGroup, Button } from 'react-bootstrap';
import { validation, required, digits } from '../../../utils/forms/validation';
import ReduxFormControl from '../../../components/forms/ReduxFormControl';

class RemoveAdForm extends PureComponent {
  render() {
    const { handleSubmit } = this.props;

    return (
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div>
            <Field component={ReduxFormControl} name="estateId" type="text" placeholder="ID ogłoszenia" />
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <FormGroup controlId="changePass-submit">
            <Button bsStyle="danger" bsSize="large" className="submit" type="submit">
              Usuń ogłoszenie
            </Button>
          </FormGroup>
        </div>
      </form>
    );
  }
}

RemoveAdForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const validate = (values) => {
  const errors = {};

  errors.estateId = validation(values.estateId, [required(), digits()]);

  return errors;
};

const onSubmitSuccess = (result, dispatch) => dispatch(reset('removeAd'));

export default reduxForm({ form: 'removeAd', validate, onSubmitSuccess })(RemoveAdForm);
