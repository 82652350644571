import Agencja3000 from './Agencja3000';
import Asari from './Asari';
import EstiCRM from './EstiCRM';
import Galactica from './Galactica';
import Imo from './Imo';

export default {
  agencja3000: Agencja3000,
  asari: Asari,
  esticrm: EstiCRM,
  galactica: Galactica,
  imo: Imo,
};
