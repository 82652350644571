import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, reset } from 'redux-form';
import { FormGroup, Button, Alert } from 'react-bootstrap';
import ReduxFormControl from '../../components/forms/ReduxFormControl';
import * as estatesActions from '../../actions/estatesActions';
import * as messagesActions from '../../actions/messagesActions';
import Photos from '../../components/estate/Photos';

const errorMessages = (rejectedFiles) => (rejectedFiles.map(rejectedFile => {
  let message = `Wystąpił błąd podczas wysyłania pliku: ${rejectedFile.file.path} `;
  if (rejectedFile.errors[0].code === 'file-invalid-type') {
    message += '- format pliku inny niż: JPG, JPEG, PNG, HEIC';
  } else if (rejectedFile.errors[0].code === 'file-too-large') {
    message += '- maksymalny rozmiar pliku to 8 MB';
  }

  return message;
}));

class PhotosForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { acceptedFiles: [] };
    this.handleUpload = this.handleUpload.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.photos !== this.props.photos) {
      this.clearPreview();
    }
  }

  handleUpload(acceptedFiles, rejectedFiles) {
    if (rejectedFiles.length > 0) {
      this.props.sendErrorMessage(errorMessages(rejectedFiles));
    }

    // Only allow max 8 photos per ad in total
    const approvedFiles = acceptedFiles.slice(0, 8 - this.props.photos.length);
    if (approvedFiles.length < acceptedFiles.length) {
      this.props.sendErrorMessage('Liczba dodanych zdjęć przekroczyła liczbę 8 dozwolonych zdjęć');
    }

    this.props.onUpload(approvedFiles);
    const parsed = approvedFiles.map(
      photo => ({ photoId: photo.lastModified, preview: photo.preview }),
    );
    this.setState({ acceptedFiles: parsed });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!this.props.photos || this.props.photos.length === 0) {
      return this.props.sendErrorMessage('Wymagane jest dodanie przynajmniej 1 zdjęcia');
    }

    this.props.handleSubmit();
  }

  clearPreview() {
    const { acceptedFiles } = this.state;
    acceptedFiles.forEach(file => URL.revokeObjectURL(file.preview));

    this.setState({ acceptedFiles: [] });
  }

  render() {
    const { photos, type, estateId, changeMainPhoto, deletePhoto } = this.props;
    const { acceptedFiles } = this.state;
    const allPhotos = [...(photos || []), ...acceptedFiles];
    const accept = {
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'image/heic': ['.heic'],
    };

    return (
      <form className="photo-form" onSubmit={this.handleSubmit}>
        <div className="photos-uploader col-lg-8 col-lg-offset-2">
          <h3 className="col-lg-12 col-md-12 col-sm-12 col-xs-12">Dodaj nowe zdjęcie:</h3>
          <div className="clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <Field component={ReduxFormControl} name="photos" type="file"
                accept={accept} onUpload={this.handleUpload}
              />
            </div>
          </div>
        </div>

        <div id="ads" className="estates-short-list">
          {(!photos || photos.length === 0) && (
            <div className="col-lg-12">
              <Alert bsStyle="info" className="text-center">
                W chwili obecnej nie posiadasz dodanych żadnych zdjęć.
              </Alert>
              <Alert bsStyle="warning" className="text-center">
                Wymagane jest dodanie przynajmniej 1 zdjęcia o minimalnym rozmiarze 500px.
              </Alert>
            </div>
          )}

          {(allPhotos && allPhotos.length > 0) && (
            <Photos
              type={type} estateId={estateId} photos={allPhotos}
              onMainPhotoChange={changeMainPhoto} onPhotoDelete={deletePhoto}
            />
          )}
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <FormGroup controlId="add-ad-2-submit">
            <Button bsStyle="success" className="submit pull-right" type="submit">
              Dalej
            </Button>
          </FormGroup>
        </div>
      </form>
    );
  }
}

PhotosForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  changeMainPhoto: PropTypes.func.isRequired,
  deletePhoto: PropTypes.func.isRequired,
  sendErrorMessage: PropTypes.func.isRequired,
  estateId: PropTypes.string,
  photos: PropTypes.array,
  type: PropTypes.string.isRequired,
};

const validate = (values) => {
  const errors = {};

  if (values.photos && values.photos.rejected.length > 0) {
    errors.photos = errorMessages(values.photos.rejected);
  }

  return errors;
};

const onSubmitSuccess = (result, dispatch) => dispatch(reset('photos'));

export default reduxForm({ form: 'photos', validate, onSubmitSuccess })(
  connect(
    () => ({}),
    (dispatch) => bindActionCreators({ ...estatesActions, ...messagesActions }, dispatch),
  )(PhotosForm),
);
