import slug from './slug';
import { getRent, getType } from './estate/label';

function estatesListUrl({ type, rent }) {
  return `/${slug(getType(type))}/${slug(getRent(rent))}/`;
}

function estateUrl(type, title, estateId) {
  return `/${type}/${slug(title)}/${estateId}`;
}

function pageUrl(url, page) {
  if (url.indexOf('page=') !== -1) return url.replace(/page=[0-9]*/, `page=${page}`);
  const filteredUrl = (url.indexOf('/oferty-') > -1 ? url.slice(0, url.indexOf('/oferty-')) : url)
    .replace(/\/$/, '');
  return `${filteredUrl}/oferty-${page}`;
}

function similarEstatesUrl({ type, rent, city }) {
  return `/${slug(getType(type))}/${slug(getRent(rent))}/${city.cityId}/${slug(city.city)}/`;
}

function staticUrl(url) {
  const STATIC_HOST = process.env.REACT_STATIC_HOST || window.REACT_STATIC_HOST;

  return `//${STATIC_HOST}${url}`;
}

function thumbnailUrl(container, type, filename, size) {
  return staticUrl(`/${container}/${size}/${type}/${filename}`);
}

function photoUrl(container, type, filename) {
  return staticUrl(`/${container}/${type}/${filename}`);
}

function userEstatesUrl(user) {
  return `/ogloszenia-uzytkownika/${user.userId}`;
}

export { estatesListUrl };
export { estateUrl };
export { pageUrl };
export { similarEstatesUrl };
export { thumbnailUrl };
export { photoUrl };
export { userEstatesUrl };
