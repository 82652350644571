import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'react-bootstrap';
import Header from './Header';
import MenuBottom from '../components/MenuBottom';
import * as safeActions from '../actions/safeActions';
import * as userActions from '../actions/userActions';
import Search from '../containers/Search';
import Breadcrumbs from '../containers/Breadcrumbs';
import FlashMessenger from '../containers/FlashMessenger';

class Layout extends PureComponent {
  componentDidMount() {
    this.props.fetchSafe();
  }

  render() {
    const { closeCookieBar, showCookieBar } = this.props;

    return (
      <div id="layout">
        {showCookieBar && (
          <div className="cookie-policy">
            <span>
              Korzystanie z naszej witryny jest jednoznaczne z wyrażeniem zgody
              na wykorzystywanie plików cookie.
            </span>
            <Button bsStyle="success" className="close-cookie-info" onClick={closeCookieBar}>
              Zamknij
            </Button>
          </div>
        )}

        <Header history={this.props.history} />

        <div id="main" className={(this.props.match.path === '/') ? 'home' : 'sub'}>
          <div className="landscape" />
          <Search />
          <div className="clearfix" />

          <div className="container content">
            <Breadcrumbs />

            <FlashMessenger />

            {this.props.children}

            <MenuBottom />
          </div>
        </div>

        <div id="push" />
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  showCookieBar: PropTypes.bool,
  fetchSafe: PropTypes.func.isRequired,
  closeCookieBar: PropTypes.func.isRequired,
};

export default withRouter(connect(
  (state) => ({ showCookieBar: state.user.showCookieBar }),
  (dispatch) => bindActionCreators({ ...safeActions, ...userActions }, dispatch),
)(Layout));
