import React from 'react';
import { Link } from 'react-router-dom';

function AcceptRegulations() {
  return (
    <span>
      Akceptuję
      {' '}
      <Link to="/regulamin">regulamin</Link>
    </span>
  );
}

export default AcceptRegulations;
