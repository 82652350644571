import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Alert, Button, ButtonGroup, Glyphicon } from 'react-bootstrap';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import EstateCard from '../../components/EstateCard';
import { getType } from '../../utils/estate/types';
import { getRentBySlug } from '../../utils/estate/rent';
import MetaTags from '../../components/MetaTags';
import { pageUrl } from '../../utils/url';
import Paginator from '../../components/Paginator';
import countPages from '../../utils/estate/countPages';

class EstatesList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { grid: true };
    this.gridViewOn = this.activateGridView.bind(this, true);
    this.gridViewOff = this.activateGridView.bind(this, false);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match !== this.props.match) {
      this.fetchData();
    }

    if (this.props.estatesList) this.props.breadcrumbs([{ title: this.getTitle() }]);
  }

  getTitle() {
    const { rent, type } = this.props.match.params;

    const rentText = getRentBySlug(rent).label;
    const categoryText = _.upperFirst(type.replace('dzialki', 'działki'));

    return `${categoryText} na ${rentText}`;
  }

  activateGridView = (grid) => {
    this.setState({ grid });
  };

  fetchData() {
    const { type, rent, page } = this.props.match.params;

    const path = `${getType(type)}/${getRentBySlug(rent).value}`;
    const query = `page=${page || 1}`;

    this.props.getEstatesList({ path, query });
  }

  renderList() {
    const { estatesList } = this.props;

    const estates = (estatesList ? estatesList.rows : Array(20).fill({}))
      .map((estate) => (
        <li key={estate.estateId ? estate.estateId : Math.random()} className="col-xs-12 col-sm-6 col-md-3">
          <EstateCard {...estate} />
        </li>
      ));

    return (
      <ul>{estates}</ul>
    );
  }

  render() {
    const page = parseInt(this.props.match.params.page, 10) || 1;
    const { estatesList } = this.props;
    const { grid } = this.state;

    const title = this.getTitle();

    return (
      <div className="estates-list-page">
        <MetaTags title={title} />

        <div id="ads" className={`row estates-short-list${!grid ? ' estates-list' : ''}`}>
          <h1>{`${title}:`}</h1>

          {(!_.isEmpty(estatesList) && estatesList.rows.length === 0) && (
            <div className="col-lg-12">
              <Alert bsStyle="info" className="text-center">
                W chwili obecnej kategoria nie posiada dodanych ofert!
              </Alert>
            </div>
          )}

          {(!_.isEmpty(estatesList) && estatesList.rows.length > 0) && (
            <ButtonGroup className="estates-list-types">
              <Button className={`estates-list-type ${grid ? ' active' : ''}`} title="Siatka"
                onClick={this.gridViewOn}
              >
                <Glyphicon glyph="th" />
              </Button>
              <Button className={`estates-list-type ${grid ? '' : 'active'}`} title="Lista"
                onClick={this.gridViewOff}
              >
                <Glyphicon glyph="th-list" />
              </Button>
            </ButtonGroup>
          )}

          {this.renderList()}
        </div>

        {(!_.isEmpty(estatesList) && estatesList.rows.length > 0) && (
          <Paginator page={page} pages={countPages(estatesList)}
            url={pageUrl(this.props.match.url, page)}
          />
        )}
      </div>
    );
  }
}

EstatesList.propTypes = {
  breadcrumbs: PropTypes.func.isRequired,
  match: PropTypes.object,
  estatesList: PropTypes.shape({
    count: PropTypes.number,
    rows: PropTypes.array,
  }),
  getEstatesList: PropTypes.func.isRequired,
};

export default withRouter(connect(
  (state) => ({ estatesList: state.estates.estatesList }),
  (dispatch) => bindActionCreators({ ...breadcrumbsActions, ...estatesActions }, dispatch),
)(EstatesList));
