import ApartmentDetails from './Apartment';
import HouseDetails from './House';
import LandDetails from './Land';
import ObjectDetails from './Object';
import PlaceDetails from './Place';

export default {
  apartment: ApartmentDetails,
  house: HouseDetails,
  land: LandDetails,
  object: ObjectDetails,
  place: PlaceDetails,
};
