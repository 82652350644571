import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Alert, Button } from 'react-bootstrap';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import MetaTags from '../../components/MetaTags';
import EstateItem from '../../components/EstateItem';
import AddAdSteps from '../../components/estate/AddAdSteps';

const maxStepsCondition = (maxSteps) => (!maxSteps || maxSteps < 3);

class AddAdStep3 extends PureComponent {
  static title = 'Dodawanie ogłoszenia';

  constructor(props) {
    super(props);
    const { maxSteps } = props;

    if (maxStepsCondition(maxSteps)) props.history.push('/dodaj-ogloszenie');

    this.handleLastStep = this.handleLastStep.bind(this);
  }

  componentDidMount() {
    this.props.breadcrumbs([{ title: AddAdStep3.title }]);

    this.fetchData();
  }

  handleLastStep() {
    const { estateId, history, estateAdStep3Submit } = this.props;

    return estateAdStep3Submit(estateId, history);
  }

  fetchData() {
    this.props.getEstateById(this.props.estateId);
  }

  render() {
    const { type } = this.props.match.params;
    const { estate, maxSteps } = this.props;

    if (!estate || maxStepsCondition(maxSteps)) return null;

    return (
      <div className="add-add-page-3">
        <MetaTags title={AddAdStep3.title} />

        <div className="row">
          <h1>{AddAdStep3.title}</h1>
        </div>

        <div className="row">
          <div className="margin-bottom20">
            <AddAdSteps type={type} step={3} steps={maxSteps} />
          </div>
        </div>

        {!estate.active && (
          <Alert bsStyle="warning" className="text-center">
            Ogłoszenie nie jest jeszcze aktywne!
            {' '}
            <strong>Przejdź na dół strony</strong>
            {' '}
            i&nbsp;zatwierdź podgląd klikając w&nbsp;&quot;Zapisz&quot;.
            <br />
            Ogłoszenie będzie aktywne przez
            {' '}
            <strong>6 miesiący</strong>
            , a następnie usunięte w przypadku braku ponownej aktywacji po kolejnych
            {' '}
            <strong>6 miesiącach</strong>
            .
          </Alert>
        )}

        <EstateItem estate={estate} preview />

        <div className="clearfix margin-bottom20">
          <Button bsStyle="success" className="submit pull-right" onClick={this.handleLastStep}>
            Zapisz
          </Button>
        </div>
      </div>
    );
  }
}

AddAdStep3.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  breadcrumbs: PropTypes.func.isRequired,
  getEstateById: PropTypes.func.isRequired,
  estateAdStep3Submit: PropTypes.func.isRequired,
  maxSteps: PropTypes.number,
  estateId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  estate: PropTypes.object,
};

export default withRouter(connect(
  (state) => ({
    estateId: state.estates.estateAd.estateId,
    maxSteps: state.estates.estateAd.step,
    estate: state.estates.estate,
  }),
  (dispatch) => bindActionCreators({ ...breadcrumbsActions, ...estatesActions }, dispatch),
)(AddAdStep3));
