import { reset } from 'redux-form';
import _ from 'lodash';
import { BlockBlobClient } from '@azure/storage-blob';
import {
  getJson, getJsonAuth, postAuth, postJson, postJsonAuth, putJsonAuth, deleteJsonAuth,
} from '../utils/fetch';
import { getToken, getUserId } from '../utils/user';
import { getType } from '../utils/estate/types';
import { sendErrorMessage, sendSuccessMessage } from './messagesActions';
import log from '../utils/log';
import { getRentByValue } from '../utils/estate/rent';
import { getMarketByValue } from '../utils/estate/market';
import { getProvinceByValue } from '../utils/provinces';

export const ESTATES_ACTION_TYPES = {
  ESTATES_SET_ACCOUNT_LIST: 'ESTATES_SET_ACCOUNT_LIST',
  ESTATES_SET_BY_ID: 'ESTATES_SET_BY_ID',
  ESTATES_SET_BY_USER_ID: 'ESTATES_SET_BY_USER_ID',
  ESTATES_SET_CITY_LIST: 'ESTATES_SET_CITY_LIST',
  ESTATES_SET_ESTATE_AD: 'ESTATES_SET_ESTATE_AD',
  ESTATES_SET_ESTATE_AD_PHOTOS: 'ESTATES_SET_ESTATE_AD_PHOTOS',
  ESTATES_SET_LIST: 'ESTATES_SET_LIST',
  ESTATES_SET_PROMOTED: 'ESTATES_SET_PROMOTED',
  ESTATES_SET_RECENTLY_ADDED: 'ESTATES_SET_RECENTLY_ADDED',
  ESTATES_SET_SAFE_LIST: 'ESTATES_SET_SAFE_LIST',
  ESTATES_SET_SEARCH: 'ESTATES_SET_SEARCH',
  ESTATES_SET_SEARCH_RESULT: 'ESTATES_SET_SEARCH_RESULT',
};

export const setEstateAd = data => ({
  type: ESTATES_ACTION_TYPES.ESTATES_SET_ESTATE_AD,
  data,
});

export const setEstateAdPhotos = data => ({
  type: ESTATES_ACTION_TYPES.ESTATES_SET_ESTATE_AD_PHOTOS,
  data,
});

export const setAccountEstates = data => ({
  type: ESTATES_ACTION_TYPES.ESTATES_SET_ACCOUNT_LIST,
  data,
});

export function pageView(estateId, type) {
  postJson('/api/views', { estateId, type }).then(() => {}).catch(() => {});
}

export function getAccountEstates(query) {
  return (dispatch, getState) => {
    dispatch(setAccountEstates(null));

    getJsonAuth(`/api/estates/account/${getUserId(getState)}?${query}`, getToken(getState))
      .then(json => dispatch(setAccountEstates(json)))
      .catch((err) => {
        log.error(`An error occured in getAccountEstates(): ${err.message}`, err);
        return dispatch(setAccountEstates(null));
      });
  };
}

const parseDataToDB = (data) => {
  /* eslint no-param-reassign: 0 no-return-assign: 0 */
  const parseValue = (value) => ((typeof value === 'boolean') ? Number(value) : value);
  const getValue = (result, value, key) => (
    result[key] = _.isObject(value) ? value.value : parseValue(value)
  );
  /* eslint no-param-reassign: 1 no-return-assign: 1 */

  return {
    estate: {
      ..._.transform(data.estate, getValue),
      ...{ city: data.estate.city, province: data.estate.province },
    },
    estateType: _.transform(data.estateType, getValue),
  };
};

const parseDataFromDB = (json) => {
  const estateFields = [
    'rent', 'market', 'title', 'address', 'cityCode', 'district', 'price', 'area', 'description',
  ];
  const irrelevantFields = [
    'estateId', 'type', 'active', 'city', 'province', 'creationDate', 'externalId', 'imported',
    'latitudeGeo', 'longitudeGeo', 'matchGeo', 'modificationDate', 'photos', 'promoted', 'user',
  ];

  return {
    type: json.type,
    estate: {
      ...(_.pick(json, estateFields)),
      market: getMarketByValue(json.market),
      rent: getRentByValue(json.rent),
      province: getProvinceByValue(json.province.provinceId),
      city: { value: json.city.cityId, label: json.city.city },
    },
    estateType: _.mapValues(
      _.omit(json, estateFields.concat(irrelevantFields)),
      (item) => ((item === 0) ? null : item),
    ),
  };
};

export function estateAdReset() {
  return dispatch => {
    dispatch(setEstateAd({ step: 1 }));
  };
}

export function searchReset() {
  return dispatch => {
    dispatch(reset('search'));
  };
}

export function estateAdStep1Load(estateId) {
  return (dispatch) => {
    dispatch(reset('estate'));
    dispatch(setEstateAd({ step: 1, estateId, dataStep1: null }));

    getJson(`/api/estates/${parseInt(estateId, 10)}`)
      .then((json) => {
        const data = parseDataFromDB(json);

        dispatch(setEstateAd({ step: 1, estateId, dataStep1: data }));
      })
      .catch((err) => {
        if (err.status === 404) {
          log.warn(`Estate with ID: ${estateId} was not found`);
        } else {
          log.error(`An error occured in estateAdStep1Load(): ${err.message}`, err);
        }
        dispatch(setEstateAd({ step: 1, estateId, dataStep1: {} }));
      });
  };
}

export function estateAdStep1Submit(type, estateId, data, history) {
  const message = {
    error: 'Wystąpił błąd podczas dodawania ogłoszenia!',
  };

  return (dispatch, getState) => {
    dispatch(setEstateAd({ step: 1, dataStep1: data }));

    const dbData = parseDataToDB(data);
    const urlPath = !estateId ? `/api/estates/${getType(type, true)}`
      : `/api/estates/${getType(type, true)}/${estateId}`;

    postJsonAuth(urlPath, dbData, getToken(getState))
      .then((json) => {
        dispatch(setEstateAd({ step: 2, estateId: json.estateId }));

        const redirectPath = history.location.pathname.indexOf('dodaj-ogloszenie') > -1
          ? `/dodaj-ogloszenie/${type}/krok/2`
          : `/edytuj-ogloszenie/${estateId}/krok/2`;
        history.push(redirectPath);
      })
      .catch((err) => {
        log.error(`An error occured in estateAdStep1Submit(): ${err.message}`, err);

        return dispatch(sendErrorMessage(message.error));
      });
  };
}

export function estateAdStep2Load(estateId) {
  return (dispatch) => {
    getJson(`/api/estates/${parseInt(estateId, 10)}/photos`)
      .then((data) => {
        dispatch(setEstateAdPhotos({ step: 2, photos: data }));
      })
      .catch((err) => {
        log.error(`An error occured in estateAdStep2Load(): ${err.message}`, err);
      });
  };
}

export function estateAdStep2Upload(type, estateId, files) {
  const message = {
    error: 'Wystąpił błąd podczas dodawania zdjęć do ogłoszenia!',
  };

  return (dispatch, getState) => {
    if (files.length === 0) return;

    if (process.env.REACT_APP_SERVERLESS === 'true') {
      const filesJson = files.map(file => ({ name: file.name, size: file.size, type: file.type }));

      postAuth(`/api/estates/${estateId}/photos`, JSON.stringify(filesJson), getToken(getState))
        .then((filesSAS) => {
          const uploadFiles = [];
          filesSAS.forEach(fileSAS => {
            if (fileSAS.sasTokenUrl) {
              const blockBlobClient = new BlockBlobClient(fileSAS.sasTokenUrl);
              const file = _.find(files, { name: fileSAS.name });
              const blobOptions = { blobHTTPHeaders: { blobContentType: file.type } };
              uploadFiles.push(blockBlobClient.uploadData(file, blobOptions));
            }
          });

          return Promise.all(uploadFiles)
            .then(() => getJson(`/api/estates/${parseInt(estateId, 10)}/photos`)
              .then((json) => dispatch(setEstateAdPhotos({ photos: json, append: false }))));
        })
        .catch((err) => {
          log.error(`An error occured in estateAdStep2Upload(): ${err.message}`, err);
          return dispatch(sendErrorMessage(message.error));
        });
    } else {
      const formData = new FormData();
      files.slice(0, 8).forEach(file => formData.append('files[]', file));

      postAuth(`/api/estates/${estateId}/photos`, formData, getToken(getState))
        .then((json) => {
          dispatch(setEstateAdPhotos({ photos: json, append: true }));
        })
        .catch((err) => {
          log.error(`An error occured in estateAdStep2Upload(): ${err.message}`, err);
          return dispatch(sendErrorMessage(message.error));
        });
    }
  };
}

export function estateAdStep2Submit(type, estateId, history) {
  return dispatch => {
    dispatch(setEstateAd({ step: 3 }));

    const redirectPath = type ? `/dodaj-ogloszenie/${type}/krok/3`
      : `/edytuj-ogloszenie/${estateId}/krok/3`;
    history.push(redirectPath);
  };
}

export function estateAdStep3Submit(estateId, history) {
  const message = {
    error: 'Wystąpił błąd podczas aktywacji ogłoszenia!',
  };

  return (dispatch, getState) => {
    putJsonAuth(`/api/estates/${estateId}/activate`, getToken(getState))
      .then(() => {
        dispatch(setEstateAd({ step: 1, data: null }));
        history.push('/zarzadzaj-ogloszeniami');
      })
      .catch((err) => {
        log.error(`An error occured in estateAdStep3Submit(): ${err.message}`, err);
        return dispatch(sendErrorMessage(message.error));
      });
  };
}

export const setCityEstatesList = data => ({
  type: ESTATES_ACTION_TYPES.ESTATES_SET_CITY_LIST,
  data,
});

export function getCityEstatesList({ path, query }) {
  return (dispatch) => {
    dispatch(setCityEstatesList(null));

    getJson(`/api/estates/${path}?${query}`)
      .then(json => dispatch(setCityEstatesList(json)))
      .catch((err) => {
        log.error(`An error occured in setCityEstatesList(): ${err.message}`, err);
        return dispatch(setCityEstatesList(null));
      });
  };
}

export const setEstateById = data => ({
  type: ESTATES_ACTION_TYPES.ESTATES_SET_BY_ID,
  data,
});

export function getEstateById(id) {
  return (dispatch) => {
    dispatch(setEstateById(null));

    getJson(`/api/estates/${parseInt(id, 10)}`)
      .then(json => {
        dispatch(setEstateById(json));
        pageView(id, 'page');
      })
      .catch(err => {
        if (err.status === 404) {
          log.warn(`Estate with ID: ${id} was not found`);
        } else {
          log.error(`An error occured in getEstateById(): ${err.message}`, err);
        }
        return dispatch(setEstateById({}));
      });
  };
}

export const setEstatesList = data => ({
  type: ESTATES_ACTION_TYPES.ESTATES_SET_LIST,
  data,
});

export function getEstatesList({ path, query }) {
  return (dispatch) => {
    dispatch(setEstatesList(null));

    getJson(`/api/estates/${path}?${query}`)
      .then(json => dispatch(setEstatesList(json)))
      .catch((err) => {
        log.error(`An error occured in setEstatesList(): ${err.message}`, err);
        return dispatch(setEstatesList(null));
      });
  };
}

export const setEstatesByUserId = data => ({
  type: ESTATES_ACTION_TYPES.ESTATES_SET_BY_USER_ID,
  data,
});

export function getEstatesByUserId(userId, query) {
  return (dispatch) => {
    dispatch(setEstatesByUserId(null));

    getJson(`/api/estates/user/${userId}?${query}`)
      .then(json => dispatch(setEstatesByUserId(json)))
      .catch((err) => {
        log.error(`An error occured in setEstatesByUserId(): ${err.message}`, err);
        return dispatch(setEstatesByUserId(null));
      });
  };
}

export const setPromoted = data => ({
  type: ESTATES_ACTION_TYPES.ESTATES_SET_PROMOTED,
  data,
});

export function getPromoted() {
  return (dispatch) => {
    dispatch(setPromoted(null));

    getJson('/api/estates/promoted')
      .then(json => dispatch(setPromoted(json)))
      .catch((err) => {
        log.error(`An error occured in getPromoted(): ${err.message}`, err);
        return dispatch(setPromoted(null));
      });
  };
}

export const setRecentlyAdded = data => ({
  type: ESTATES_ACTION_TYPES.ESTATES_SET_RECENTLY_ADDED,
  data,
});

export function getRecentlyAdded() {
  return (dispatch) => {
    dispatch(setRecentlyAdded(null));

    getJson('/api/estates/recently-added')
      .then(json => dispatch(setRecentlyAdded(json)))
      .catch((err) => {
        log.error(`An error occured in setRecentlyAdded(): ${err.message}`, err);
        return dispatch(setRecentlyAdded(null));
      });
  };
}

export const setSafeList = data => ({
  type: ESTATES_ACTION_TYPES.ESTATES_SET_SAFE_LIST,
  data,
});

export function getSafeList(safe) {
  return (dispatch) => {
    dispatch(setSafeList(null));
    if (safe.length === 0) return;

    getJson(`/api/estates/safe?estateId=[${safe.join(',')}]`)
      .then(json => dispatch(setSafeList(json)))
      .catch((err) => {
        log.error(`An error occured in setEstatesByUserId(): ${err.message}`, err);
        return dispatch(setSafeList(null));
      });
  };
}

export const setSearchResult = data => ({
  type: ESTATES_ACTION_TYPES.ESTATES_SET_SEARCH_RESULT,
  data,
});

export function getSearchResult(queryString) {
  return (dispatch) => {
    dispatch(setSearchResult(null));

    getJson(`/api/estates/search?${queryString}`)
      .then(json => dispatch(setSearchResult(json)))
      .catch((err) => {
        log.error(`An error occured in setSearchResult(): ${err.message}`, err);
        return dispatch(setSearchResult(null));
      });
  };
}

export const setSearchQuery = (estateType, data) => ({
  type: ESTATES_ACTION_TYPES.ESTATES_SET_SEARCH,
  estateType,
  data,
});

export function updateSearchQuery(estateType, data) {
  return (dispatch) => dispatch(setSearchQuery(estateType, data));
}

export function changeMainPhoto(estateId, photoId) {
  const message = {
    error: 'Wystąpił błąd podczas zmiany głównego zdjęcia!',
  };

  return (dispatch, getState) => {
    putJsonAuth(`/api/estates/${estateId}/photos/${photoId}/main`, getToken(getState))
      .then((data) => {
        dispatch(setEstateAdPhotos({ photos: data }));
      })
      .catch((err) => {
        log.error(`An error occured in changeMainPhoto(): ${err.message}`, err);
        return dispatch(sendErrorMessage(message.error));
      });
  };
}

export function deletePhoto(estateId, photoId) {
  const message = {
    error: 'Wystąpił błąd podczas usuwania zdjęcia!',
  };

  return (dispatch, getState) => {
    deleteJsonAuth(`/api/estates/${estateId}/photos/${photoId}`, getToken(getState))
      .then((data) => {
        dispatch(setEstateAdPhotos({ photos: data }));
      })
      .catch((err) => {
        log.error(`An error occured in deletePhoto(): ${err.message}`, err);
        return dispatch(sendErrorMessage(message.error));
      });
  };
}

export function deleteEstateAd(estateId, callback) {
  const message = {
    error: 'Wystąpił błąd podczas usuwania ogłoszenia!',
  };

  return (dispatch, getState) => {
    deleteJsonAuth(`/api/estates/${estateId}`, getToken(getState))
      .then(() => callback())
      .catch((err) => {
        log.error(`An error occured in deleteEstateAd(): ${err.message}`, err);
        return dispatch(sendErrorMessage(message.error));
      });
  };
}

export function refreshEstateAd(estateId) {
  const message = {
    error: 'Wystąpił błąd podczas odświeżania ogłoszenia, spróbuj ponownie.',
    success: 'Ogłoszenie zostało odświeżone i będzie aktywne przez kolejne 6 miesięcy.',
  };

  return (dispatch, getState) => {
    putJsonAuth(`/api/estates/refresh/${estateId}`, getToken(getState))
      .then(() => dispatch(sendSuccessMessage(message.success)))
      .catch((err) => {
        log.error(`An error occured in refreshEstateAd(): ${err.message}`, err);
        return dispatch(sendErrorMessage(message.error));
      });
  };
}
