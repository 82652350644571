import React from 'react';
import PropTypes from 'prop-types';
import { Glyphicon } from 'react-bootstrap';
import { photoUrl } from '../../utils/url';
import { getType } from '../../utils/estate/types';

const removeElement = (e) => { e.preventDefault(); e.currentTarget.remove(); };

const renderPhoto = (type, estateId, photo, onMainPhotoChange, onPhotoDelete) => (
  <>
    <div className="ad-actions">
      {photo.main === 1 && (
        <a className="pull-left" title="Zdjęcie główne" role="button" tabIndex={0}>
          <Glyphicon glyph="star" />
        </a>
      )}
      {photo.main === 0 && (
        <a className="pull-left" title="Ustaw jako główne" role="button" tabIndex={0}
          onClick={() => onMainPhotoChange(estateId, photo.photoId)}
          onKeyDown={() => onMainPhotoChange(estateId, photo.photoId)}
        >
          <Glyphicon glyph="star-empty" />
        </a>
      )}

      <a className="pull-right" title="Usuń zdjęcie" role="button" tabIndex={0}
        onClick={(event) => { removeElement(event); onPhotoDelete(estateId, photo.photoId); }}
        onKeyDown={(event) => { removeElement(event); onPhotoDelete(estateId, photo.photoId); }}
      >
        <Glyphicon glyph="remove-circle" />
      </a>
    </div>

    <div className="estate-img-container">
      <img src={photoUrl(photo.container, getType(type, true), photo.filename)} alt="" />
    </div>
  </>
);

const renderPreview = ({ preview }) => (
  <>
    <div className="ad-actions" />
    <div className="estate-img-container preview">
      <img src={preview} alt="" />
    </div>
  </>
);

const renderPhotos = (type, estateId, photos, onMainPhotoChange, onPhotoDelete) => (
  photos.map((photo) => {
    const additionalClass = (photo.main === 1) ? ' promoted' : '';

    return (
      <li key={photo.photoId} className="col-xs-12 col-sm-6 col-md-3">
        <div className={`estate-card${additionalClass}`}>
          <div className="row">
            <div className="col-md-6 clearfix">
              {photo.preview ? renderPreview(photo)
                : renderPhoto(type, estateId, photo, onMainPhotoChange, onPhotoDelete)}
            </div>
          </div>
        </div>
      </li>
    );
  })
);

function Photos({ type, estateId, photos, onMainPhotoChange, onPhotoDelete }) {
  if (!photos || photos.length === 0) return null;

  return (
    <ul className="photo-list">
      {renderPhotos(type, estateId, photos, onMainPhotoChange, onPhotoDelete)}
    </ul>
  );
}

Photos.propTypes = {
  type: PropTypes.string.isRequired,
  photos: PropTypes.array,
  estateId: PropTypes.string,
  onMainPhotoChange: PropTypes.func,
  onPhotoDelete: PropTypes.func,
};

renderPreview.propTypes = {
  preview: PropTypes.string.isRequired,
};

export default Photos;
