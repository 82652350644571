import _ from 'lodash';

function jsonToQueryString(jsonData) {
  return Object.keys(jsonData)
    .map((key) => {
      const value = jsonData[key];
      return (_.isNull(value)) ? `${key}=` : `${key}=${jsonData[key]}`;
    }).join('&');
}

function queryStringToJson(queryString) {
  const pairs = queryString.slice(1).split('&');

  const result = {};
  pairs.forEach((pair) => {
    const arg = pair.split('=');
    result[arg[0]] = decodeURIComponent(arg[1] || '');
  });

  return JSON.parse(JSON.stringify(result));
}

export { jsonToQueryString };
export { queryStringToJson };
