import { ADMIN_ACTION_TYPES } from '../actions/adminActions';

export default function admin(state = {}, action = {}) {
  switch (action.type) {
    case ADMIN_ACTION_TYPES.ADMIN_STATS_ADS_CHART:
      return { ...state, stats: { ...state.stats, adsChart: action.data } };
    case ADMIN_ACTION_TYPES.ADMIN_STATS_ADS_TOTAL:
      return { ...state, stats: { ...state.stats, ads: action.data } };
    case ADMIN_ACTION_TYPES.ADMIN_STATS_USERS_TOTAL:
      return { ...state, stats: { ...state.stats, users: action.data } };
    default:
      return state;
  }
}
