import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import MetaTags from '../../components/MetaTags';
import PromotionList from '../../components/PromotionList';

class NewAd extends PureComponent {
  static title = 'Dodaj ogłoszenie';

  componentDidMount() {
    this.props.breadcrumbs([{ title: NewAd.title }]);

    this.props.estateAdReset();
  }

  render() {
    return (
      <div className="new-ad-page">
        <MetaTags title={NewAd.title} />

        <div className="row">
          <h1>{NewAd.title}</h1>

          <div className="col-lg-12 new-ad">
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div id="apartment" className="col-lg-12 type-container">
                <Link className="type-link" to="/dodaj-ogloszenie/mieszkanie/krok/1">
                  Mieszkanie
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div id="house" className="col-lg-12 type-container">
                <Link className="type-link" to="/dodaj-ogloszenie/dom/krok/1">
                  Dom
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div id="land" className="col-lg-12 type-container">
                <Link className="type-link" to="/dodaj-ogloszenie/dzialka/krok/1">
                  Działka
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div id="place" className="col-lg-12 type-container">
                <Link className="type-link" to="/dodaj-ogloszenie/lokal/krok/1">
                  Lokal użytkowy
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div id="object" className="col-lg-12 type-container">
                <Link className="type-link" to="/dodaj-ogloszenie/obiekt/krok/1">
                  Obiekt użytkowy
                </Link>
              </div>
            </div>
          </div>
        </div>

        <PromotionList />
      </div>
    );
  }
}

NewAd.propTypes = {
  breadcrumbs: PropTypes.func.isRequired,
  estateAdReset: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  (dispatch) => bindActionCreators({ ...breadcrumbsActions, ...estatesActions }, dispatch),
)(NewAd);
