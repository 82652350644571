import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
import { persistStore, persistReducer } from 'redux-persist';
import { rootPersistConfig } from './persistConfig';
import rootReducer from '../reducers';

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export default function configureStore(initialState) {
  /* eslint-disable no-underscore-dangle */
  const devTools = window.__REDUX_DEVTOOLS_EXTENSION__;
  const sentryRedux = Sentry.createReduxEnhancer();
  const enhancer = (!devTools) ? applyMiddleware(thunk)
    : compose(applyMiddleware(thunk), devTools({ name: 'tanieruchomosci' }), sentryRedux);

  const store = createStore(persistedReducer, initialState, enhancer);
  const persistor = persistStore(store);
  return { store, persistor };
}
