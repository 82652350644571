import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as safeActions from '../../actions/safeActions';

class SafeButton extends PureComponent {
  handleClick(estateId) {
    this.props.updateSafeItem(estateId);
  }

  render() {
    const isSaved = this.props.safe.indexOf(this.props.estateId) !== -1;
    const classActive = isSaved ? ' active' : '';
    const classItemActive = isSaved ? 'fa-star' : 'fa-star-o';
    const text = isSaved ? 'Usuń ze schowka' : 'Dodaj do schowka';

    return (
      <a className={`safe-button${classActive}`} title={text} role="button" tabIndex={0}
        onClick={() => this.handleClick(this.props.estateId)}
        onKeyDown={() => this.handleClick(this.props.estateId)}
      >
        <i className={`fa ${classItemActive}`} />
      </a>
    );
  }
}

SafeButton.propTypes = {
  estateId: PropTypes.number.isRequired,
  safe: PropTypes.array.isRequired,
  updateSafeItem: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({ safe: state.safe }),
  (dispatch) => bindActionCreators(safeActions, dispatch),
)(SafeButton);
