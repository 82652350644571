const promotion = [
  {
    title: 'Dodaj ogłoszenie',
    icon: 'picture',
    desc: 'Dodaj ogłoszenie wypełniając poprawnie minimum wymagane pola, dodaj minimum jedno zdjęcie i\u00a0zatwierdź.',
  },
  {
    title: 'Polub nasz fanpage',
    icon: 'thumbs-up',
    desc: 'Kliknij przycisk "Lubię to" na stronie głównej serwisu lub bezpośrednio odwiedzając Facebook.',
  },
  {
    title: 'Wyślij do nas e-mail',
    icon: 'envelope',
    desc: 'Wyślij do nas wiadomość z\u00a0imieniem i\u00a0nazwiskiem oraz linkiem do ogłoszenia, które mamy wypromować.',
  },
  {
    title: 'Poczekaj na potwierdzenie',
    icon: 'ok-sign',
    desc: 'Poczekaj na wiadomość zwrotną potwierdzającą wypromowanie lub powód odrzucenia (z\u00a0reguły 24-48\u00a0h).',
  },
];

export default promotion;
