/* eslint quote-props: 0, object-property-newline: 0, prefer-template: 0 */
/**
 * Create a web friendly URL slug from a string.
 * Based on: https://gist.github.com/sgmurphy/3095196
 *
 * @param {string} textToSlug
 * @return string
 */
function slug(textToSlug) {
  let text = textToSlug;

  const delimiter = '-';
  const charactersMap = {
    // Latin
    'À': 'A', 'Á': 'A', 'Â': 'A', 'Ã': 'A', 'Ä': 'A', 'Å': 'A', 'Æ': 'AE', 'Ç': 'C',
    'È': 'E', 'É': 'E', 'Ê': 'E', 'Ë': 'E', 'Ì': 'I', 'Í': 'I', 'Î': 'I', 'Ï': 'I',
    'Ð': 'D', 'Ñ': 'N', 'Ò': 'O', 'Ó': 'O', 'Ô': 'O', 'Õ': 'O', 'Ö': 'O', 'Ő': 'O',
    'Ø': 'O', 'Ù': 'U', 'Ú': 'U', 'Û': 'U', 'Ü': 'U', 'Ű': 'U', 'Ý': 'Y', 'Þ': 'TH',
    'ß': 'ss',
    'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'ä': 'a', 'å': 'a', 'æ': 'ae', 'ç': 'c',
    'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e', 'ì': 'i', 'í': 'i', 'î': 'i', 'ï': 'i',
    'ð': 'd', 'ñ': 'n', 'ò': 'o', 'ó': 'o', 'ô': 'o', 'õ': 'o', 'ö': 'o', 'ő': 'o',
    'ø': 'o', 'ù': 'u', 'ú': 'u', 'û': 'u', 'ü': 'u', 'ű': 'u', 'ý': 'y', 'þ': 'th',
    'ÿ': 'y',

    // Polish
    'Ą': 'A', 'Ć': 'C', 'Ę': 'e', 'Ł': 'L', 'Ń': 'N', 'Ś': 'S', 'Ź': 'Z',
    'Ż': 'Z',
    'ą': 'a', 'ć': 'c', 'ę': 'e', 'ł': 'l', 'ń': 'n', 'ś': 's', 'ź': 'z',
    'ż': 'z',
  };

  // Transliterate characters to ASCII
  Object.keys(charactersMap).forEach(character => {
    text = text.replace(RegExp(character, 'g'), charactersMap[character]);
  });

  // Replace non-alphanumeric characters with our delimiter
  text = text.replace(/[^a-z0-9]+/ig, delimiter);

  // Remove duplicate delimiters
  text = text.replace(RegExp('[' + delimiter + ']{2,}', 'g'), delimiter);

  // Remove delimiter from ends
  text = text.replace(RegExp('(^' + delimiter + '|' + delimiter + '$)', 'g'), '');

  return text.toLowerCase();
}

export default slug;
