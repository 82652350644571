const digits = () => (value => (
  (value && !/^[0-9]+$/i.test(value)) ? 'Podana wartość nie jest liczbą!' : null)
);

const email = () => (value => ((value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(value))
  ? 'Podaj poprawny adres e-mail!' : null));

const identical = confirmation => (value => ((value !== confirmation) ? 'Powtórz wartość poprawnie!' : null)
);

const maxLength = max => (value => ((value && value.length > max)
  ? `Maksymalna długość pola to ${max} znaki!` : null));

const minLength = min => (value => ((value && value.length < min)
  ? `Minimalna długość pola to ${min} znaki!` : null));

const required = () => (value => ((!value) ? 'Wartość pola jest wymagana!' : null));

const validation = (value, conditions) => {
  let error = null;

  conditions.forEach((condition) => {
    const conditionError = condition(value);
    if (conditionError) error = conditionError;
  });

  return error;
};

export { digits };
export { email };
export { identical };
export { maxLength };
export { minLength };
export { required };
export { validation };
