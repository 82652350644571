import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as breadcrumbsActions from '../actions/breadcrumbsActions';
import ErrorNotFound from '../components/ErrorNotFound';

function NotFound(props) {
  props.breadcrumbs([]);

  return (
    <ErrorNotFound />
  );
}

NotFound.propTypes = {
  breadcrumbs: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  dispatch => bindActionCreators(breadcrumbsActions, dispatch),
)(NotFound);
