import React from 'react';
import OfferListItem from './offer-list/OfferListItem';
import offerData from '../data/offer';

const getItems = () => offerData.map(item => <OfferListItem key={item.icon} {...item} />);

function OfferList() {
  return (
    <div className="row offer-list">
      <h2>Co oferujemy:</h2>
      <ul>
        {getItems()}
      </ul>
    </div>
  );
}

export default OfferList;
