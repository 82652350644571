import 'formdata-polyfill';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControl, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import * as userActions from '../../actions/userActions';

class TopBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { smallFormActive: false };
    this.handleSmallForm = this.handleSmallForm.bind(this);
    this.handleLogIn = this.handleLogIn.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
  }

  handleSmallForm = () => {
    this.setState(prevState => ({ smallFormActive: !prevState.smallFormActive }));
  };

  handleLogIn(event) {
    event.preventDefault();

    const data = new FormData(event.target);
    const { history } = this.props;

    this.props.userLogIn({ email: data.get('email'), password: data.get('password') }, history);
  }

  handleLogOut() {
    this.props.userLogOut();
  }

  render() {
    const { profile, safe } = this.props;
    const smallFormClass = this.state.smallFormActive ? ' active' : '';
    const safeClass = this.state.smallFormActive ? ' hidden' : '';

    return (
      <div className="top-bar">
        <div className="container">
          <div className="row">
            <div className="cl-lg-12 col-md-12 col-sm-12 col-xs-12">
              <Link className={`safe pull-left${safeClass}`} to="/schowek">
                Schowek (
                <span className="safe-counter">{!safe ? 0 : safe.length}</span>
                )
              </Link>

              {!_.isEmpty(profile) && (
                <div className="logged-in pull-right">
                  <span className="logged-name hidden-xs">
                    Zalogowany jako:
                    {' '}
                    <strong>{profile.email}</strong>
                    .
                  </span>
                  <Button bsStyle="primary" bsSize="large" className="btn-grey pull-right" onClick={this.handleLogOut}>
                    Wyloguj
                  </Button>
                </div>
              )}

              {_.isEmpty(profile) && (
                <div className={`login-small-form pull-right${smallFormClass}`}>
                  <form onSubmit={this.handleLogIn}>
                    <FormGroup controlId="toplogin-email">
                      <FormControl type="text" name="email" placeholder="E-mail" required="required" />
                    </FormGroup>

                    <FormGroup controlId="toplogin-password">
                      <FormControl type="password" name="password" placeholder="Hasło" required="required" />
                    </FormGroup>

                    <input type="hidden" value="1" name="remember-me" />
                    <Button bsStyle="primary" bsSize="large" className="btn-grey pull-right submit" type="submit">
                      Zaloguj
                    </Button>
                  </form>
                </div>
              )}

              {_.isEmpty(profile) && (
                <a className="login-link pull-right" role="button" tabIndex={0}
                  onClick={this.handleSmallForm} onKeyDown={this.handleSmallForm}
                >
                  <span className="glyphicon glyphicon-log-in" />
                  {' '}
                  Zaloguj
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TopBar.propTypes = {
  safe: PropTypes.array,
  profile: PropTypes.object,
  userLogIn: PropTypes.func.isRequired,
  userLogOut: PropTypes.func.isRequired,
  history: PropTypes.object,
};

export default connect(
  (state) => ({ safe: state.safe, profile: state.user.profile }),
  (dispatch) => bindActionCreators(userActions, dispatch),
)(TopBar);
