const types = {
  apartment: {
    singular: 'mieszkanie',
    plural: 'mieszkania',
  },
  house: {
    singular: 'dom',
    plural: 'domy',
  },
  land: {
    singular: 'dzialka',
    plural: 'dzialki',
  },
  object: {
    singular: 'obiekt',
    plural: 'obiekty',
  },
  place: {
    singular: 'lokal',
    plural: 'lokale',
  },
};

export default types;
