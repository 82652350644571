const materials = [
  { value: 1, label: 'beton' },
  { value: 2, label: 'cegła' },
  { value: 3, label: 'wielka płyta' },
  { value: 4, label: 'drewno' },
  { value: 5, label: 'pustak' },
  { value: 6, label: 'zróżnicowany' },
];

export default materials;
