import { createTransform } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import _ from 'lodash';

const blacklist = {
  user: ['profile'],
};

const blacklistTransform = createTransform(
  (inboundState, key) => (blacklist[key] ? _.omit(inboundState, blacklist[key]) : inboundState),
);

const rootPersistConfig = {
  key: 'root',
  storage: localStorage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['estates', 'form'],
  transforms: [blacklistTransform],
};

const estatesPersistConfig = {
  key: 'estates',
  storage: storageSession,
};

const formPersistConfig = {
  key: 'form',
  storage: storageSession,
  blacklist: ['search'],
};

export { rootPersistConfig };
export { estatesPersistConfig };
export { formPersistConfig };
