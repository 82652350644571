import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import SafeBar from '../containers/estate/SafeBar';
import { estateUrl, thumbnailUrl } from '../utils/url';
import { getType } from '../utils/estate/label';
import formatNumber from '../utils/formatNumber';
import AdActions from './estate/AdActions';
import AdStats from './estate/AdStats';

const disableLink = event => event.preventDefault();
const handleClick = (event, isActive) => ((!isActive) ? disableLink(event) : null);

const getAdditionalClass = (props) => {
  let additionalClass = '';

  if (props.promoted === 1) additionalClass = `${additionalClass} promoted`;
  if (props.active === 0) additionalClass = `${additionalClass} inactive`;

  return additionalClass;
};

function EstateCard(props) {
  const {
    active, safeBar, adActions, adStats, estateId, title, photos, onDelete, onRefresh,
  } = props;
  const isEmpty = !estateId;
  const typeText = !isEmpty ? getType(props.type, true) : null;
  const url = !isEmpty ? estateUrl(typeText, props.title, props.estateId) : null;

  return (
    <div className={`estate-card${getAdditionalClass(props)}`}>
      <div className="row">
        <div className="col-md-6 clearfix">
          {!isEmpty && safeBar && <SafeBar estateId={estateId} />}
          {!isEmpty && adActions
            && (
              <AdActions estateId={estateId} active={active} photos={photos}
                onDelete={onDelete} onRefresh={onRefresh}
              />
            )}

          {isEmpty && (
            <div className="estate-img-container">
              <Skeleton width="100%" style={{ paddingBottom: '67%' }} />
            </div>
          )}
          {!isEmpty && (
            <Link to={url} className="estate-img-container" title={title}
              onClick={e => handleClick(e, active)}
            >
              {props.photos[0] && (
                <img className="lazy" alt={title}
                  src={thumbnailUrl(props.photos[0].container, props.type, props.photos[0].filename, '360x270')}
                />
              )}
              <span className="estate-price">
                {formatNumber(props.price)}
                {' '}
                zł
              </span>
            </Link>
          )}
        </div>

        <div className="col-md-6 row">
          <div className="estate-type">
            {isEmpty && (<Skeleton />)}
            {!isEmpty && (
              <>
                {_.startCase(typeText)}
                {' '}
                {props.rent ? 'do wynajęcia' : 'na sprzedaż'}
              </>
            )}
          </div>

          {isEmpty && (<Skeleton />)}
          {!isEmpty && (
            <Link to={url} className="estate-title" title={title} onClick={e => handleClick(e, active)}>
              {title}
            </Link>
          )}

          <div className="estate-city">{!isEmpty ? props.city.city : <Skeleton />}</div>

          {isEmpty && (<Skeleton />)}
          {!isEmpty && (
            <div className="estate-surface pull-left">
              <span className="glyphicon glyphicon-resize-horizontal" />
              {formatNumber(props.area)}
              {' '}
              m
              <sup>2</sup>
            </div>
          )}

          {!isEmpty && props.description && (
            <div className="pull-left estate-desc">{props.description.trim().substr(0, 160)}</div>
          )}

          {!isEmpty && adStats && <AdStats stats={props.views[0] || {}} />}
        </div>
      </div>
    </div>
  );
}

EstateCard.defaultProps = {
  adActions: false,
  adStats: false,
  safeBar: true,
};

EstateCard.propTypes = {
  estateId: PropTypes.number,
  title: PropTypes.string,
  type: PropTypes.string,
  city: PropTypes.shape({
    city: PropTypes.string,
  }),
  price: PropTypes.number,
  area: PropTypes.number,
  rent: PropTypes.number,
  description: PropTypes.string,
  photos: PropTypes.array,
  thumbnail: PropTypes.shape({
    filename: PropTypes.string,
  }),
  active: PropTypes.number,
  views: PropTypes.arrayOf(PropTypes.shape({
    pages: PropTypes.number,
    phones: PropTypes.number,
  })),
  adActions: PropTypes.bool,
  adStats: PropTypes.bool,
  safeBar: PropTypes.bool,
  onDelete: PropTypes.func,
  onRefresh: PropTypes.func,
};

export default EstateCard;
