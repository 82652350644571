import React from 'react';

const year = new Date().getFullYear();

function Footer() {
  return (
    <footer>
      <div className="container">
        Copyright © 2010 -
        {' '}
        {year}
        {' '}
        taNieruchomości.pl
        <ul className="social-networks">
          <li>
            <a
              href="https://www.facebook.com/tanieNIERUCH0M0SCI" target="_blank"
              rel="noopener noreferrer"
              title="Polub nas na Facebook.com"
            >
              <i className="fa fa-thumbs-o-up" />
            </a>
          </li>
          <li>
            <a
              href="http://www.facebook.com/sharer.php?u=https%3A%2F%2Fwww.tanieruchomosci.pl&amp;p%5Btitle%5D=Darmowe+og%C5%82oszenia+nieruchomo%C5%9Bci+-+domy%2C+mieszkania%2C+dzia%C5%82ki%2C+lokale+u%C5%BCytkowe%2C+obiekty+u%C5%BCytkowe"
              className="social-popup" target="_blank"
              rel="noopener noreferrer" title="Udostępnij na Facebook.com"
            >
              <i className="fa fa-facebook" />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.tanieruchomosci.pl&amp;text=Darmowe+og%C5%82oszenia+nieruchomo%C5%9Bci+-+domy%2C+mieszkania%2C+dzia%C5%82ki%2C+lokale+u%C5%BCytkowe%2C+obiekty+u%C5%BCytkowe"
              className="social-popup" target="_blank"
              rel="noopener noreferrer" title="Udostępnij na Twitter.com"
            >
              <i className="fa fa-twitter" />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
