import React from 'react';
import PropTypes from 'prop-types';
import YesNoBadge from '../../YesNoBadge';

function Land(props) {
  return (
    <div className="estate-details row pull-left">
      <div className="col-lg-12 clearfix">
        <h3>Media:</h3>
        <ul className="estate-details-properties row">
          <li className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
            <YesNoBadge value={props.power} />
            {' '}
            Prąd
          </li>
          <li className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
            <YesNoBadge value={props.gas} />
            {' '}
            Gaz
          </li>

          {props.water !== undefined && props.water < 2 && (
          <li className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
            <YesNoBadge value={props.water} />
            {' '}
            Woda
          </li>
          )}

          <li className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
            <YesNoBadge value={props.canalization} />
            {' '}
            Kanalizacja
          </li>
          <li className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
            <YesNoBadge value={props.phone} />
            {' '}
            Telefon
          </li>
          <li className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
            <YesNoBadge value={props.internet} />
            {' '}
            Internet
          </li>
        </ul>
      </div>

      <div className="col-lg-12">
        <h3>Informacje dodatkowe:</h3>
        <ul className="estate-details-properties row">
          <li className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
            <YesNoBadge value={props.fence} />
            {' '}
            Ogrodzenie
          </li>
        </ul>
      </div>
    </div>
  );
}

Land.propTypes = {
  power: PropTypes.number,
  gas: PropTypes.number,
  water: PropTypes.number,
  canalization: PropTypes.number,
  phone: PropTypes.number,
  internet: PropTypes.number,
  fence: PropTypes.number,
};

export default Land;
