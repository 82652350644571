import React from 'react';

function Imo() {
  return (
    <div>
      <h3>Jak skonfigurować eksport w IMO?</h3>
      <p>
        W&nbsp;pierwszej kolejności należy skontaktować się z działem pomocy
        {' '}
        <a href="https://www.imo.pl" target="_blank" rel="noopener noreferrer">IMO</a>
        ,w&nbsp;celu
        dodania portalu &quot;tanieruchomosci.pl&quot; do listy dostępnych do eksportu portali.
        <br />
        Można to zrobić z poziomu programu, za pomocą formularza znajdującego się w menu górnym
        <strong> Pomoc / Helpdesk - zgłaszanie uwag</strong>
        .
        <br />
        <strong>Ważne jest</strong>
        , aby podać jako &quot;Format pliku XML&quot; wartość &quot;Oferty.net&quot;,
        jako iż definiuje on poprawne parsowanie otrzymanych przez nas danych.
        Bez tej informacji eksport będzie działał niepoprawnie!
        <br />
        Po dodaniu serwisu na listę dostępnych, należy
        {' '}
        <a href="https://www.imo.pl/pomoc/imo/konfiguracja-eksportu#kotwica"
          target="_blank" rel="noopener noreferrer"
        >
          skonfigurować eksport do Internetu
        </a>
        {' '}
        wykorzystując dane dostępowe znajdujące się powyżej (adres FTP, login, hasło).
        <br />
        <br />
      </p>
    </div>
  );
}

export default Imo;
