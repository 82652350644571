import React from 'react';
import PropTypes from 'prop-types';
import ReactMetaTags from 'react-meta-tags';
import defaultMetaTags from '../data/metaTags';

function MetaTags(props) {
  return (
    <ReactMetaTags>
      <title>
        {props.title ? `${props.title} - Darmowe ogłoszenia nieruchomości` : defaultMetaTags.title}
      </title>

      <meta name="description" content={props.description} />
      <meta name="keywords" content={props.keywords.join(', ')} />

      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:image" content={props.image} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={props.title} />
      <meta name="twitter:description" content={props.description} />
      <meta name="twitter:image" content={props.image} />
    </ReactMetaTags>
  );
}

MetaTags.defaultProps = {
  description: defaultMetaTags.description,
  keywords: defaultMetaTags.keywords,
  image: defaultMetaTags.image,
};

MetaTags.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.array,
  image: PropTypes.string,
};

export default MetaTags;
