import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import * as adminActions from '../../actions/adminActions';
import MetaTags from '../../components/MetaTags';
import RecentlyAdded from '../../containers/RecentlyAdded';
import RemoveAdForm from '../../containers/forms/admin/RemoveAdForm';

class RemoveAd extends PureComponent {
  static title = 'Usuwanie ogłoszenia';

  constructor(props) {
    super(props);
    this.handleForm = this.handleForm.bind(this);
  }

  componentDidMount() {
    this.props.getRecentlyAdded();

    this.props.breadcrumbs([{ title: RemoveAd.title }]);
  }

  handleForm(values) {
    if (!window.confirm('Czy na pewno chcesz usunąć ogłoszenie?')) return;

    this.props.adminRemoveAd(values.estateId);
  }

  render() {
    /* eslint no-alert: 0 */
    return (
      <div className="admin-remove-ad-page">
        <MetaTags title={RemoveAd.title} />

        <div className="row">
          <div className="well-container">
            <div
              className="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12
                col-sm-offset-0 col-xs-12 col-xs-offset-0 well-form"
            >
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h1>{RemoveAd.title}</h1>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                  <p>&nbsp;</p>
                  <RemoveAdForm onSubmit={this.handleForm} />
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h2>Chcesz usunąć ogłoszenie?</h2>
                <div className="row col-lg-12 desc-right">
                  Ważne informacje związane z usunięciem ogłoszenia:
                  <ul>
                    <li>Usunięcie ogłoszenia spowoduje nieodwracalne usunięcie ogłoszenia</li>
                    <li>
                      Usunięcie ogłoszenia spowoduje usunięcie wszystkich zdjęć ogłoszenia
                    </li>
                    <li>
                      Usunięte ogłoszenia mogą nadal być widoczne przez jakiś czas w wyszukiwarkach
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RecentlyAdded />
      </div>
    );
  }
}

RemoveAd.propTypes = {
  getRecentlyAdded: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.func.isRequired,
  adminRemoveAd: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  (dispatch) => bindActionCreators(
    { ...breadcrumbsActions, ...estatesActions, ...adminActions }, dispatch,
  ),
)(RemoveAd);
