import { SAFE_ACTION_TYPES } from '../actions/safeActions';

export default function safe(state = {}, action = {}) {
  switch (action.type) {
    case SAFE_ACTION_TYPES.SAFE_UPDATE:
      return action.data;
    default:
      return state;
  }
}
