import React from 'react';
import PropTypes from 'prop-types';
import YesNoBadge from '../../YesNoBadge';

function ObjectDetails(props) {
  return (
    <div className="estate-details row pull-left">
      <div className="col-lg-12 clearfix">
        <h3>Media:</h3>
        <ul className="estate-details-properties row">
          <li className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
            <YesNoBadge value={props.power} />
            {' '}
            Prąd
          </li>
          <li className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
            <YesNoBadge value={props.gas} />
            {' '}
            Gaz
          </li>
          <li className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
            <YesNoBadge value={props.water} />
            {' '}
            Woda
          </li>

          <li className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
            <YesNoBadge value={props.canalization} />
            {' '}
            Kanalizacja
          </li>
          <li className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
            <YesNoBadge value={props.phone} />
            {' '}
            Telefon
          </li>
          <li className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
            <YesNoBadge value={props.internet} />
            {' '}
            Internet
          </li>
        </ul>
      </div>

      <div className="col-lg-12">
        <h3>Informacje dodatkowe:</h3>
        <ul className="estate-details-properties row">
          {props.garage !== undefined && props.garage < 2 && (
          <li className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
            <YesNoBadge value={props.garage} />
            {' '}
            Garaż
          </li>
          )}
          {props.parking !== undefined && props.parking < 2 && (
          <li className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
            <YesNoBadge value={props.parking} />
            {' '}
            Parking
          </li>
          )}
          {props.elevator !== undefined && props.elevator < 2 && (
          <li className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
            <YesNoBadge value={props.elevator} />
            {' '}
            Winda
          </li>
          )}
          {props.fence !== undefined && props.fence < 2 && (
          <li className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
            <YesNoBadge value={props.fence} />
            {' '}
            Ogrodzenie
          </li>
          )}
        </ul>
      </div>
    </div>
  );
}

ObjectDetails.propTypes = {
  power: PropTypes.number,
  gas: PropTypes.number,
  water: PropTypes.number,
  canalization: PropTypes.number,
  phone: PropTypes.number,
  internet: PropTypes.number,
  garage: PropTypes.number,
  parking: PropTypes.number,
  elevator: PropTypes.number,
  fence: PropTypes.number,
};

export default ObjectDetails;
