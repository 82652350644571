import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import * as breadcrumbsActions from '../../actions/breadcrumbsActions';
import * as estatesActions from '../../actions/estatesActions';
import EstateCard from '../../components/EstateCard';
import RecentlyAdded from '../../containers/RecentlyAdded';
import MetaTags from '../../components/MetaTags';
import Loader from '../../components/Loader';

class SafeList extends PureComponent {
  static title = 'Schowek';

  componentDidMount() {
    this.fetchData();

    this.props.breadcrumbs([{ title: SafeList.title }]);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match !== this.props.match || prevProps.safe.length !== this.props.safe.length) {
      this.fetchData();
    }
  }

  fetchData() {
    this.props.getSafeList(this.props.safe);
    this.props.getRecentlyAdded();
  }

  renderList() {
    const { estatesList, safe } = this.props;

    if (!estatesList || (estatesList.length === 0 || safe.length === 0)) return null;

    const estates = estatesList.map((estate) => (
      <li key={estate.estateId} className="col-xs-12 col-sm-6 col-md-3">
        <EstateCard {...estate} />
      </li>
    ));

    return (
      <ul>{estates}</ul>
    );
  }

  render() {
    const { estatesList, safe } = this.props;

    return (
      <div className="safe-page">
        <MetaTags title={SafeList.title} />

        <div id="ads" className="row estates-short-list estates-list">
          <h1>{SafeList.title}</h1>

          {(_.isEmpty(estatesList) && safe.length > 0) && (<Loader />)}

          {(safe.length === 0) && (
            <div className="col-lg-12">
              <Alert bsStyle="info" className="text-center">
                W chwili obecnej w&nbsp;schowku nie ma dodanych żadnych ofert!
                <br />
                <br />

                Aby dodać ofertę do schowka najedź i&nbsp;kliknij na gwiazdkę położoną:
                <br />
                - w&nbsp;prawym górnym rogu obrazka na liście ofert
                <br />
                - poniżej tabelki, wraz z&nbsp;innymi przyciskami na podstronie z&nbsp;ofertą
              </Alert>
            </div>
          )}

          {this.renderList()}
        </div>

        <RecentlyAdded />
      </div>
    );
  }
}

SafeList.propTypes = {
  match: PropTypes.object,
  estatesList: PropTypes.array,
  safe: PropTypes.array.isRequired,
  getSafeList: PropTypes.func.isRequired,
  getRecentlyAdded: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.func.isRequired,
};

export default withRouter(connect(
  (state) => ({ estatesList: state.estates.safeList, safe: state.safe }),
  (dispatch) => bindActionCreators({ ...breadcrumbsActions, ...estatesActions }, dispatch),
)(SafeList));
