import ApartmentForm from './ApartmentForm';
import HouseForm from './HouseForm';
import LandForm from './LandForm';
import ObjectForm from './ObjectForm';
import PlaceForm from './PlaceForm';

export default {
  apartment: ApartmentForm,
  house: HouseForm,
  land: LandForm,
  object: ObjectForm,
  place: PlaceForm,
};
