export const BREADCRUMBS_ACTION_TYPES = {
  BREADCRUMBS_SET: 'BREADCRUMBS_SET',
};

export const setBreadcrumbs = data => ({
  type: BREADCRUMBS_ACTION_TYPES.BREADCRUMBS_SET,
  data,
});

export function breadcrumbs(data) {
  return dispatch => dispatch(setBreadcrumbs(data));
}
