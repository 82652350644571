import { getProfileFromState } from './getProfile';

const getProfile = getState => getProfileFromState(getState);

const getToken = getState => (getProfile(getState).token || null);

const getUserId = getState => (getProfile(getState).userId || null);

const getUserProfile = getState => (getProfile(getState) || null);

export { getToken };
export { getUserId };
export { getUserProfile };
