const categories = {
  apartment: [
    { value: 1, label: 'blok' },
    { value: 2, label: 'kamienica' },
    { value: 3, label: 'dom wolnostojący' },
    { value: 4, label: 'szeregowiec' },
    { value: 5, label: 'apartamentowiec' },
    { value: 6, label: 'inny' },
  ],
  house: [
    { value: 1, label: 'wolnostojący' },
    { value: 2, label: 'bliźniak' },
    { value: 3, label: 'szeregowiec' },
    { value: 4, label: 'kamienica' },
    { value: 5, label: 'dworek/pałac' },
    { value: 6, label: 'gospodarstwo' },
    { value: 7, label: 'inny' },
  ],
  land: [
    { value: 1, label: 'budowlana' },
    { value: 2, label: 'rolna' },
    { value: 3, label: 'budowlano-rolna' },
    { value: 4, label: 'rekreacyjna' },
    { value: 5, label: 'inwestycyjna' },
    { value: 6, label: 'usługowa' },
    { value: 7, label: 'przemysłowa' },
    { value: 8, label: 'leśna' },
    { value: 9, label: 'siedliskowa' },
    { value: 10, label: 'inna' },
  ],
  object: [
    { value: 1, label: 'gospodarstwo domowe' },
    { value: 2, label: 'hala/magazyn' },
    { value: 3, label: 'kiosk/pawilon' },
    { value: 4, label: 'hotel/pensjonat' },
    { value: 5, label: 'przemysłowy' },
    { value: 6, label: 'ośrodek wypoczynkowy' },
    { value: 7, label: 'biurowiec' },
    { value: 8, label: 'kamienica' },
    { value: 9, label: 'handlowo-usługowy' },
    { value: 10, label: 'zabytkowy' },
    { value: 11, label: 'kompleks budynków' },
    { value: 12, label: 'inny' },
  ],
  place: [
    { value: 1, label: 'biurowy' },
    { value: 2, label: 'handlowo-usługowy' },
    { value: 3, label: 'gastronomiczny' },
    { value: 4, label: 'magazynowy' },
    { value: 5, label: 'garaż' },
    { value: 6, label: 'inny' },
  ],
};

export default categories;
